<template>
  <div>
    <!-- Modal Detail Facture -->
    <div
      v-if="showDetailFacture"
      :class="`modal ${
        !showDetailFacture && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-screen top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="showDetailFacture = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 70rem"
        class="z-50 w-11/12 mx-auto max-h-[40rem] bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Récapitulatif de la facture</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="showDetailFacture = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="w-1/3 ml-2">
              Référence client:
              {{ detailFacture.Note }}
            </div>

            <!--  -->
            <div
              class="flex flex-col w-14 h-8 justify-end items-center ml-auto mx-10"
            >
              <span
                class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
                style="color: blue"
                @click="createPDF(detailFacture)"
              >
                download
              </span>
              <div>PDF</div>
            </div>
          </div>

          <!--Body-->
          <table v-if="detailFacture.Bdls" class="min-w-full mt-4">
            <div class="overflow-y-auto max-h-[25rem]">
              <thead class="tableHEADTR">
                <tr>
                  <th
                    class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Réf.
                  </th>
                  <th
                    class="mx-20 w-80 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Description
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Qté
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    P.V.
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Remise
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Px final
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Total
                  </th>
                </tr>
              </thead>

              <tbody
                v-if="detailFacture.Bdls.length > 0"
                class="bg-white w-fit"
              >
                <div
                  v-for="(bdl, ind) in detailPdf"
                  :key="ind"
                  class="tableHEADTR"
                >
                  <br />
                  <div class="bg-gray-100">BL n°{{ bdl.Numero }}</div>

                  <tr
                    v-for="(r, index) in detailFacture.Bdls[ind].pdfData"
                    :key="index"
                    class="tableHEADTR"
                  >
                    <td
                      class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="flex flex-row items-center">
                        <span class="ml-4 text-sm leading-5 text-gray-900">
                          {{ r.ref }}
                        </span>
                      </div>
                    </td>

                    <td
                      class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      style="overflow: none"
                    >
                      <div class="text-sm leading-5 text-gray-500">
                        {{ r.description }}
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="text-sm leading-5">
                        {{ r.quantite }}
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      {{
                        parseFloat(
                          detailFacture.Bdls[ind].Articles[index].Prix_euro
                        ).toFixed(2)
                      }}
                      €
                    </td>
                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div
                        v-if="
                          detailFacture.Bdls[ind].Articles[index]
                            .PrixFournisseurType != 'prixnet'
                        "
                      >
                        {{ r.remise }} %
                      </div>
                      <div v-else>prix net</div>
                    </td>
                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div
                        v-if="
                          detailFacture.Bdls[ind].Articles[index]
                            .PrixFournisseurType != 'prixnet'
                        "
                      >
                        {{
                          parseFloat(
                            detailFacture.Bdls[ind].Articles[index].Prix_euro -
                              detailFacture.Bdls[ind].Articles[index]
                                .Prix_euro *
                                (r.remise / 100)
                          ).toFixed(2)
                        }}
                        €
                      </div>

                      <div v-else>
                        {{
                          parseFloat(
                            detailFacture.Bdls[ind].Articles[index].Prix_vente
                          ).toFixed(2)
                        }}
                        €
                      </div>
                    </td>
                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      {{ parseFloat(r.total).toFixed(2) }}€
                    </td>
                  </tr>
                </div>
              </tbody>
              <!-- 2 eme  -->
              <tbody
                v-else-if="detailFacture.Bdls.length == 0"
                class="bg-white w-fit"
              >
                <tr
                  v-for="(r, index) in detailPdf"
                  :key="index"
                  class="tableHEADTR"
                >
                  <td
                    class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex flex-row items-center">
                      <span class="ml-4 text-sm leading-5 text-gray-900">
                        {{ r.ref }}
                      </span>
                    </div>
                  </td>

                  <td
                    class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    style="overflow: none"
                  >
                    <div class="text-sm leading-5 text-gray-500">
                      {{ r.description }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="text-sm leading-5">
                      {{ r.quantite }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{
                      parseFloat(
                        detailFacture.Articles[index].Prix_euro
                      ).toFixed(2)
                    }}
                    €
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div
                      v-if="
                        detailFacture.Articles[index].PrixFournisseurType !=
                        'prixnet'
                      "
                    >
                      {{ r.remise }} %
                    </div>
                    <div v-else>prix net</div>
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div
                      v-if="
                        detailFacture.Articles[index].PrixFournisseurType !=
                        'prixnet'
                      "
                    >
                      {{
                        parseFloat(
                          detailFacture.Articles[index].Prix_euro -
                            detailFacture.Articles[index].Prix_euro *
                              (r.remise / 100)
                        ).toFixed(2)
                      }}
                      €
                    </div>

                    <div v-else>
                      {{
                        parseFloat(
                          detailFacture.Articles[index].Prix_vente
                        ).toFixed(2)
                      }}
                      €
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{ parseFloat(r.total).toFixed(2) }}€
                  </td>
                </tr>
              </tbody>
            </div>
            <thead class="tableHEADTR mt-12 border-t">
              <tr class="tableHEADTR">
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="w-64 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    Total HT : {{ parseFloat(tmpTotalPrice).toFixed(2) }} €
                  </div>
                  <div class="text-sm leading-5 text-gray-500">
                    Total TTC : {{ parseFloat(tmpTotalPriceTTC).toFixed(2) }} €
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
              </tr>
            </thead>
          </table>

          <!--Footer-->
          <!-- <div class="flex justify-end pt-2">
          <button
            @click="showDetailFacture = false"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
         </div> -->
        </div>
      </div>
    </div>
    <!-- Fin modal Detail Facture -->
    <div
      v-if="showModifFacture"
      :class="`modal ${
        !showModifFacture && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="showModifFacture = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 45rem; max-height: 48rem; overflow: auto"
        class="z-50 w-11/12 mx-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">
              Mode de règlement facture n°{{ tabModifFacture.Numero }}
            </p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="showModifFacture = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!-- Modal Detail Articles -->
          <div
            v-show="showModifFacture"
            style="max-width: 40rem"
            class="m-auto mt-2 bg-gray-200 p-2 rounded shadow-lg z-20"
          >
            <!--Body-->

            <div class="flex justify-end mb-1">
              <!-- <button
                class="p-1 my-2 rounded shadow bg-blue-500 text-white text-sm flex flex-row items-center"
                @click="showSaisieModifFacture = !showSaisieModifFacture"
              >
                <span class="material-icons-outlined"> border_color </span>
                <span> Ajout rapide </span>
              </button> -->
              <div class="flex">
                <label>Montant total TTC : </label>
                <label class="ml-1"
                  >{{ parseFloat(setTotalTTC).toFixed(2) }} €</label
                >
              </div>
            </div>

            <div class="flex justify-center p-1">
              <div class="flex space-x-8">
                <div class="flex flex-col cursor-pointer hover:shadow">
                  <img
                    class="w-12 border border-black rounded-md m-auto rounded-md focus:outline-2 outline-sky-300"
                    src="../../assets/icons_payments/LCR.png"
                    @click="setReglement('lc')"
                  />
                  <span style="font-size: 8px" class="m-auto">LCR</span>
                </div>

                <div class="flex flex-col cursor-pointer hover:shadow">
                  <img
                    class="w-12 border border-black rounded-md m-auto rounded-md focus:outline-2 outline-sky-300"
                    src="../../assets/icons_payments/ESP.png"
                    @click="setReglement('es')"
                  />
                  <span style="font-size: 8px" class="m-auto">Espèce</span>
                </div>

                <div class="flex flex-col cursor-pointer hover:shadow">
                  <img
                    class="w-12 border border-black rounded-md m-auto rounded-md focus:outline-2 outline-sky-300"
                    src="../../assets/icons_payments/Virement.png"
                    @click="setReglement('vi')"
                  />
                  <span style="font-size: 8px" class="m-auto">Virement</span>
                </div>

                <div class="flex flex-col cursor-pointer hover:shadow">
                  <img
                    class="w-12 border border-black rounded-md m-auto rounded-md focus:outline-2 outline-sky-300"
                    src="../../assets/icons_payments/CB.png"
                    @click="setReglement('cb')"
                  />
                  <span style="font-size: 8px" class="m-auto"
                    >Carte Bancaire</span
                  >
                </div>

                <div class="flex flex-col cursor-pointer hover:shadow">
                  <img
                    class="w-12 border border-black rounded-md m-auto rounded-md focus:outline-2 outline-sky-300"
                    src="../../assets/icons_payments/CHQ.png"
                    @click="setReglement('cc')"
                  />
                  <span style="font-size: 8px" class="m-auto"
                    >Chèque Comptant</span
                  >
                </div>
              </div>
              <!-- <input
                    type="image"
                    v-model="Reglements[index].src"
                    class="flex justiy-center w-12 border border-black rounded-md focus:outline-2 outline-sky-300"
                    v-bind:src="reglement.src"
                    v-bind:alt="reglement.alt"
                  /> -->
            </div>

            <table class="min-w-full">
              <thead class="tableHEADTR">
                <tr>
                  <th
                    class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Type
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Date
                  </th>
                  <th
                    class="px-3 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Prix payé
                  </th>
                </tr>
              </thead>

              <tbody class="bg-white w-fit" v-if="tabModifFacture">
                <tr
                  v-for="(reg, index) in tabModifFacture.reglement"
                  :key="index"
                  class="tableHEADTR"
                >
                  <td
                    v-if="
                      reg.placeholder == 'LCR' ||
                      reg.placeholder == 'Espèce' ||
                      reg.placeholder == 'Carte Bancaire' ||
                      reg.placeholder == 'Virement' ||
                      reg.placeholder == 'Chèque Comptant'
                    "
                    class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex flex-col">
                      <div>
                        <input
                          type="image"
                          v-model="tabModifFacture.reglement.img"
                          class="w-12 border border-black rounded-md focus:outline-2 outline-sky-300"
                          v-bind:src="reg.img"
                        />
                      </div>
                      <div>
                        <span
                          class="focus:outline-2 outline-sky-300"
                          style="font-size: 8px"
                        >
                          {{ reg.placeholder }}
                        </span>
                      </div>
                    </div>
                  </td>

                  <td
                    v-if="
                      reg.placeholder == 'LCR' ||
                      reg.placeholder == 'Espèce' ||
                      reg.placeholder == 'Carte Bancaire' ||
                      reg.placeholder == 'Virement' ||
                      reg.placeholder == 'Chèque Comptant'
                    "
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <!-- <div class="text-sm leading-5 text-gray-900">
                      {{ reg.date }}
                    </div> -->
                    <input
                      class="text-sm leading-5 w-4/5 p-2 text-sky-600 border focus:outline-2 outline-sky-300 rounded-md"
                      type="date"
                      @change="
                        tabModifFacture.reglement[index].date = parseInt(
                          tabModifFacture.reglement[index].date
                        )
                      "
                      v-model="tabModifFacture.reglement[index].date"
                    />
                  </td>

                  <td
                    v-if="
                      reg.placeholder == 'LCR' ||
                      reg.placeholder == 'Espèce' ||
                      reg.placeholder == 'Carte Bancaire' ||
                      reg.placeholder == 'Virement' ||
                      reg.placeholder == 'Chèque Comptant'
                    "
                    class="px-3 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex justify-between">
                      <!-- <span class="text-sky-600">{{ reg.model }} €</span> -->
                      <div>
                        <input
                          class="leading-5 p-2 text-sky-600 border focus:outline-2 outline-sky-300 rounded-md"
                          style="width: 60%"
                          type="number"
                          @change="
                            tabModifFacture.reglement[index].model = parseInt(
                              tabModifFacture.reglement[index].model
                            )
                          "
                          v-model="tabModifFacture.reglement[index].model"
                        />
                        <span class="py-3">€</span>
                      </div>
                      <div class="py-2 ml-12 mt-0.5">
                        <span
                          class="material-icons-outlined text-xl text-red-600 cursor-pointer rounded-full px-0.5 py-0.25 w-6 h-6 hover:bg-red-300 hover:bg-opacity-25"
                          @click="deleteLine(index)"
                        >
                          clear
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="flex flex-col border-t border-gray-900"
              v-if="tabModifFacture"
            >
              <div
                class="flex justify-end text-red-700"
                v-if="tabModifFacture.reglement"
              >
                <div class="w-36 px-6 py-4">
                  <span class="focus:outline-2 outline-sky-300">
                    Reste dû
                  </span>
                </div>
                <div class="w-56 px-6 py-4">
                  <label>{{ parseFloat(setRestant).toFixed(2) }} €</label>
                </div>
              </div>

              <div
                class="flex justify-end text-gray-900"
                v-if="tabModifFacture.reglement"
              >
                <div class="w-36 px-6 py-4">
                  <span
                    class="focus:outline-2 outline-sky-300"
                    style="font-size: 10px"
                  >
                    Rendu
                  </span>
                </div>
                <div class="w-56 px-6 py-4">
                  <label>{{ parseFloat(setRendu).toFixed(2) }} €</label>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-end">
            <button
              class="mt-4 p-2 bg-blue-500 hover:bg-opacity-25 text-white rounded shadow"
              @click="updateFacture(index)"
            >
              Modifier
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin Modif Mode Reglement -->

    <div class="flex justify-between">
      <searchInput
        placeholder="Rechercher dans factures"
        @searchWord="search(1, 10, $event)"
        @catchFalse="searchCatchError = $event"
        :searchStarted="searchInProgress"
        :catchError="searchCatchError"
      />

      <div
        class="flex space-x-6 bg-gray-100 rounded-md shadow mb-2 p-2 w-fit"
        v-if="selectedFacture.length > 1"
      >
        <div class="flex flex-col">
          <label for="allStatus" class="text-sm"
            >Statut de la séléction :</label
          >
          <select
            id="allStatus"
            v-model="allStatus"
            class="rounded-md shadow focus:outline-2 outline-sky-300"
          >
            <option class="text-blue-400" value="Éditée">Éditée</option>
            <option class="text-blue-500" value="Émise">Émise</option>
            <option class="text-red-600" value="Rejetée">Rejetée</option>
            <option class="text-blue-600" value="Reçue">Reçue</option>
            <option class="text-red-600" value="Refusée">Refusée</option>
            <option class="text-orange-600" value="Suspendue">Suspendue</option>
            <option class="text-green-400" value="Paiement en cours">
              Paiement en cours
            </option>
            <option class="text-green-500" value="Encaissée">Encaissée</option>
            <option class="text-green-600" value="Comptabilisée">
              Comptabilisée
            </option>
            <option class="text-cyan-600" value="Archivée">Archivée</option>
          </select>
        </div>
        <button
          v-if="loading"
          class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
        >
          <span class="material-icons-outlined text-xl mr-2 animate-spin">
            refresh
          </span>
          <span>En cours de chargement</span>
        </button>
        <button
          v-else
          class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
          @click="changeStatusSelection()"
        >
          <span class="material-icons-outlined text-xl mr-2"> forward </span>
          <span>Valider</span>
        </button>
      </div>

      <div>
        <button
          v-if="!loadingExport"
          class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
          @click="exportExcel()"
        >
          <span class="material-icons-outlined text-xl mr-2"> download </span>
          <span>Exporter en Excel</span>
        </button>
        <button
          v-else
          class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
        >
          <PulseLoader color="#ffff" class="mr-2" />
          <span>Export en cours </span>
        </button>
      </div>
    </div>

    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  style="width: 1rem"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <div class="flex space-x-0.5">
                    <input
                      type="checkbox"
                      class="focus:outline-2 outline-sky-300"
                      v-model="selectAll"
                      @change="selectAllFact"
                    />
                    <span>Sélect.</span>
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Statut</span>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Réf client</span>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Date</span>
                  <span
                    v-if="showSortDate"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByDate(),
                        (showReverseDate = true),
                        (showSortDate = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseDate"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByDate(),
                        (showSortDate = true),
                        (showReverseDate = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Numéro</span>
                  <span
                    v-if="showSortNumero"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByNumero(),
                        (showReverseNumero = true),
                        (showSortNumero = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseNumero"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByNumero(),
                        (showSortNumero = true),
                        (showReverseNumero = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Client</span>
                  <span
                    v-if="showSortAlpha"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByAlpha(),
                        (showReverseAlpha = true),
                        (showSortAlpha = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseAlpha"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByAlpha(),
                        (showSortAlpha = true),
                        (showReverseAlpha = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Créé par</span>
                  <span
                    v-if="showSortUsername"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByUsername(),
                        (showReverseUsername = true),
                        (showSortUsername = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseUsername"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByUsername(),
                        (showSortUsername = true),
                        (showReverseUsername = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>HT</span>
                  <span
                    v-if="showSortTotalPrice"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByTotalPrice(),
                        (showReverseTotalPrice = true),
                        (showSortTotalPrice = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseTotalPrice"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByTotalPrice(),
                        (showSortTotalPrice = true),
                        (showReverseTotalPrice = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>TTC</span>
                  <span
                    v-if="showSortTotalPrice"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByTotalPrice(),
                        (showReverseTotalPrice = true),
                        (showSortTotalPrice = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseTotalPrice"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByTotalPrice(),
                        (showSortTotalPrice = true),
                        (showReverseTotalPrice = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  PDF
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  ACTION
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="(u, index) in infosFacture"
                :key="index"
                class="cursor-pointer hover:bg-gray-100"
              >
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
                  style="width: 1rem"
                >
                  <input
                    type="checkbox"
                    class="focus:outline-2 outline-sky-300"
                    :value="u"
                    v-model="selectedFacture"
                    :checked="selectAll"
                  />
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <select
                    v-model="u.status"
                    class="rounded-md shadow focus:outline-2 outline-sky-300"
                    @change="saveStatus(u._id, u)"
                  >
                    <option class="text-blue-400" value="Éditée">Éditée</option>
                    <option class="text-blue-500" value="Émise">Émise</option>
                    <option class="text-red-600" value="Rejetée">
                      Rejetée
                    </option>
                    <option class="text-blue-600" value="Reçue">Reçue</option>
                    <option class="text-red-600" value="Refusée">
                      Refusée
                    </option>
                    <option class="text-orange-600" value="Suspendue">
                      Suspendue
                    </option>
                    <option class="text-green-400" value="Paiement en cours">
                      Paiement en cours
                    </option>
                    <option class="text-green-500" value="Encaissée">
                      Encaissée
                    </option>
                    <option class="text-green-600" value="Comptabilisée">
                      Comptabilisée
                    </option>
                    <option class="text-cyan-600" value="Archivée">
                      Archivée
                    </option>
                  </select>
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.Note }}
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex items-center">
                    <!-- <div class="flex-shrink-0 w-10 h-10">
                      <img
                        class="w-10 h-10 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt
                      />
                    </div>-->

                    <div class="ml-4">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ getFRDate(u.Date) }}
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ u.Numero }}
                    <span
                      @click="openModalDetail(u._id, u)"
                      title="Plus d'informations"
                      class="material-icons-outlined text-base text-cyan-600 cursor-pointer"
                    >
                      info
                    </span>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.Client }}
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.Name }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalPrice).toFixed(2) }} €
                </td>

                <td
                  v-if="u.TotalPriceTTC"
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalPriceTTC).toFixed(2) }} €
                </td>
                <td
                  v-else
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalPrice + u.TotalPrice * 0.2).toFixed(2) }}
                  €
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <!-- <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                    v-if="u.IsProcessed === true"
                    ><span class="material-icons-outlined"> done </span></span
                  >
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full"
                    v-else
                    ><span class="material-icons-outlined">close</span></span
                  > -->
                  <div class="flex">
                    <div class="flex flex-row relative">
                      <span
                        class="material-icons-outlined mr-4 cursor-pointer text-blue-600 hover:text-opacity-25"
                        @click="openFacture(u)"
                      >
                        visibility
                      </span>
                      <span
                        class="material-icons-outlined cursor-pointer text-blue-600 hover:text-opacity-25"
                        @click="setEmails(u, index)"
                      >
                        email
                      </span>
                      <modalFacture
                        v-if="showEmail[index]"
                        :showEmail="showEmail[index]"
                        :ind="index"
                        :objet="u"
                        :tabFacture="infosFacture[index].clientInfo[0]"
                        :tabTempInfosFacture="infosFacture"
                        @close="getInfosFacture(currentPage, 10)"
                      />
                    </div>

                    <span
                      class="material-icons-round cursor-pointer text-blue-600 hover:text-opacity-25"
                      @click="createPDF(u)"
                    >
                      download
                    </span>
                    <div
                      v-if="loadingPDF"
                      class="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100 bg-opacity-20"
                    >
                      <div class="bg-white py-5 px-20 rounded border shadow">
                        <!-- <svg
                          class="animate-spin h-10 w-10 text-blue-500 mx-auto"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path stroke="none" d="M0 0h24v24H0z"></path>
                          <circle cx="12" cy="12" r="1"></circle>
                          <circle cx="12" cy="5" r="1"></circle>
                          <circle cx="12" cy="19" r="1"></circle>
                        </svg> -->
                        <div class="loader"></div>
                        <p class="text-center mt-2">Chargement...</p>
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <div
                    class="flex hover:text-opacity-25"
                    @click="showModalModifFacture(u)"
                  >
                    <span
                      class="material-icons-round ml-2 cursor-pointer text-green-500"
                    >
                      edit
                    </span>
                    <span
                      class="material-icons-round mt-3 -ml-3 cursor-pointer text-green-400"
                      style="font-size: 16px"
                    >
                      payments
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <pageNumber
            v-if="!searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="infosFactureLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(infosFactureLength / 10)"
            :clickFunction="getInfosFacture"
            :currentPage="currentPage"
            :typeList="'factures'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />

          <pageNumber
            v-if="searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="searchLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(searchLength / 10)"
            :clickFunction="(arg1, arg2) => search(arg1, arg2, searchTerm)"
            :currentPage="currentPage"
            :typeList="'factures'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import * as XLSX from "xlsx";
import { mapActions, mapGetters } from "vuex";
// import {sendemailfacture} from "@/hooks/email/emailFacture";
// import {sendemailfacturepro} from "@/hooks/email/emailGroupebdl";
import { createfacture } from "@/hooks/pdf/facture";
import { createfacturepro } from "@/hooks/pdf/groupebdl";
import { useToast } from "vue-toastification";
import modalFacture from "@/components/Email/modalFacture";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "MyFacture",
  data: () => ({
    Factures: [],
    infosFacture: [],
    infosFactureTmp: [],
    tabModifFacture: [],
    detailPdf: [],
    showModifFacture: false,
    showDetailFacture: false,
    showSortDate: true,
    showReverseDate: false,
    showSortNumero: true,
    showReverseNumero: false,
    showSortAlpha: true,
    showReverseAlpha: false,
    showSortUsername: true,
    showReverseUsername: false,
    showSortTotalPrice: true,
    showReverseTotalPrice: false,
    loadingExport: false,
    index: 0,
    indexMode: 0,
    inputFilter: "",
    regPaye: "",
    Reglements: [
      {
        placeholder: "LCR",
        src: "../../assets/icons_payments/LCR.png",
        alt: "LCR",
      },
      {
        placeholder: "Espèce",
        src: "../../assets/icons_payments/ESP.png",
        alt: "ESP",
      },
      {
        placeholder: "Virement",
        src: "../../assets/icons_payments/Virement.png",
        alt: "Virement",
      },
      {
        placeholder: "Carte Bancaire",
        src: "../../assets/icons_payments/CB.png",
        alt: "CB",
      },
      {
        placeholder: "Chèque Comptant",
        src: "../../assets/icons_payments/CHQ.png",
        alt: "CHQ",
      },
    ],

    showEmail: [],
    errors: [],

    infoInFacture: false,
    detailFacture: [],
    selectAll: [],
    selectedFacture: [],
    allStatus: "",
    loading: false,
    loadingPDF: false,

    pageTab: [],
    pageNext: 11,
    pageStart: 1,
    currentPage: 1,
    infosFactureLength: 0,

    searchActive: false,
    searchTerm: "",
    searchLength: 0,
    searchInProgress: false,
    searchCatchError: false,
  }),
  components: {
    modalFacture,
    pageNumber,
    searchInput,
    PulseLoader,
  },
  computed: {
    ...mapGetters(["user", "plateform"]),
    setTotalTTC() {
      return (
        this.tabModifFacture.TotalPrice + this.tabModifFacture.TotalPrice * 0.2
      );
    },
    setRestant() {
      var paye = 0;
      var restant = 0;
      this.tabModifFacture.reglement.forEach((element) => {
        paye = paye + parseFloat(element.model);
      });
      restant = parseFloat(this.setTotalTTC) - paye;
      if (restant < 0) {
        restant = 0;
      }
      return restant;
    },
    setRendu() {
      var paye = 0;
      var rendu = 0;
      this.tabModifFacture.reglement.forEach((element) => {
        if (element.placeholder != "Rendu") {
          paye = paye + parseFloat(element.model);
        }
      });
      if (paye > this.setTotalTTC) {
        rendu = Math.abs(paye - this.setTotalTTC);
      }
      return rendu;
    },
    setRegPaye() {
      var paye = 0;
      this.tabModifFacture.reglement.forEach((element) => {
        paye = paye + parseFloat(element.model);
      });
      return paye;
    },
  },
  methods: {
    ...mapActions(["setfactureselected"]),
    getFRDate(date) {
      return getFrDate(date);
    },
    openFacture(facture) {
      this.setfactureselected(facture);
      this.$router.push("/detailFacture");
    },
    getDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      return dd + "/" + mm + "/" + yyyy;
    },
    getDatePaiement() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 2).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      return dd + "/" + mm + "/" + yyyy;
    },
    getInfosFacture(skipValue, limitValue) {
      this.currentPage = skipValue;
      axios
        .post(`${process.env.VUE_APP_API}/facture/getByOwner/getpage`, {
          plateform: this.user.proprietaire,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          console.log(response.data);
          this.infosFacture = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosFactureLength() {
      axios
        .post(`${process.env.VUE_APP_API}/facture/getbyowner/count`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.infosFactureLength = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // openModalDetailNew(idFacture) {
    //   axios
    //     .post(`${process.env.VUE_APP_API}/facture/getAllBdlsById`, {
    //       id: idFacture,
    //     })
    //     .then((response) => {
    //       this.detailFacture = response.data;
    //       this.showDetailFacture = true;
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching facture details:", error);
    //     });
    // },
    async openModalDetail(idFacture, article) {
      console.log('idFacture : ', idFacture);
      console.log('article : ', article);
      
      let data;
      if (
        (article.Bdls && article.Bdls.length > 0) ||
        (article.pdfData && article.pdfData.length > 0)
      ) {
        data = article;
      } else {
        await axios
          .post(`${process.env.VUE_APP_API}/facture/getAllBdlsById`, {
            id: idFacture,
          })
          .then((response) => {
            console.log('response : ', response.data);
            data = response.data;
          })
          .catch((error) => {
            console.error("Error fetching facture details:", error);
          });
      }
      this.detailFacture = data;

      if (data.Bdls && data.Bdls.length > 0) {
        this.detailPdf = data.Bdls;
      } else {
        this.detailPdf = data.pdfData;
      }

      this.tmpTotalPrice = data.TotalPrice;
      this.tmpTotalPriceTTC = data.TotalPrice + data.TotalPrice * 0.2;

      this.showDetailFacture = true;
    },
    getIndexRd(regTab) {
      var ind = 0;
      regTab.forEach((element, index) => {
        if (element.placeholder == "Reste dû") {
          ind = index;
        }
      });
      return ind;
    },
    getIndexR(regTab) {
      var ind = 0;
      regTab.forEach((element, index) => {
        if (element.placeholder == "Rendu") {
          ind = index;
        }
      });
      return ind;
    },
    deleteLine(index) {
      this.tabModifFacture.reglement.splice(index, 1);
      console.log(this.tabModifFacture.reglement);
    },
    // changeModeReg(index) {
    //   this.indexMode = index;
    //   console.log(this.Reglements[index]);
    //   console.log(this.tabModifFacture.reglement[0]);
    //   this.tabModifFacture.reglement[0].img = this.Reglements[index].src;
    //   this.tabModifFacture.reglement[0].placeholder =
    //     this.Reglements[index].placeholder;
    // },
    setReglement(method) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;
      switch (method) {
        case "es":
          this.tabModifFacture.reglement.push({
            img: require("../../assets/icons_payments/ESP.png"),
            icon: "payments",
            placeholder: "Espèce",
            model: parseFloat(this.setRestant).toFixed(2),
            date: today,
          });
          break;
        case "cb":
          this.tabModifFacture.reglement.push({
            img: require("../../assets/icons_payments/CB.png"),
            icon: "credit_card",
            placeholder: "Carte Bancaire",
            model: parseFloat(this.setRestant).toFixed(2),
            date: today,
          });
          break;
        case "cc":
          this.tabModifFacture.reglement.push({
            img: require("../../assets/icons_payments/CHQ.png"),
            icon: "money",
            placeholder: "Chèque Comptant",
            model: parseFloat(this.setRestant).toFixed(2),
            date: today,
          });
          break;
        case "vi":
          this.tabModifFacture.reglement.push({
            img: require("../../assets/icons_payments/Virement.png"),
            icon: "account_balance",
            placeholder: "Virement",
            model: parseFloat(this.setRestant).toFixed(2),
            date: today,
          });
          break;
        case "lc":
          this.tabModifFacture.reglement.push({
            img: require("../../assets/icons_payments/LCR.png"),
            icon: "account_balance",
            placeholder: "LCR",
            model: parseFloat(this.setRestant).toFixed(2),
            date: today,
          });
          break;
      }
    },
    setEmails(objet, index) {
      if (
        objet.clientInfo[0] &&
        objet.clientInfo[0].Emails &&
        objet.clientInfo[0].Emails.length > 0
      ) {
        objet.clientInfo[0].Emails.forEach((element, index) => {
          if (element.facture == false) {
            objet.clientInfo[0].Emails.splice(index, 1);
          } else {
            this.checkForm(this.plateform.Email, element.address);
          }
        });
        this.showEmail[index] = !this.showEmail[index];
      } else {
        this.checkForm(this.plateform.Email, objet.clientInfo[0].email);
        this.showEmail[index] = !this.showEmail[index];
      }
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkForm(emailFrom, emailTo) {
      console.log(emailTo);
      this.errors = [];
      if (!emailFrom || !emailTo) {
        this.errors = [];
        this.errors.push("E-mail obligatoire.");
      } else if (!this.validEmail(emailFrom) || !this.validEmail(emailTo)) {
        this.errors = [];
        this.errors.push("E-mail incorrect.");
      } else {
        this.errors = [];
      }
    },
    // sendEmail(objet, index, emailFrom, emailTo) {
    //   if (this.errors.length > 0) {
    //     this.toast.info("Vérifiez l'adresse mail !", {
    //       position: "bottom-right",
    //       timeout: 1000,
    //       closeOnClick: true,
    //       pauseOnFocusLoss: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       draggablePercent: 0.6,
    //       showCloseButtonOnHover: false,
    //       hideProgressBar: false,
    //       closeButton: "button",
    //       icon: true,
    //       rtl: false,
    //     });
    //   } else {
    //   if (objet.Bdls.length>0) {
    //     sendemailfacturepro(objet, emailFrom, emailTo);
    //   } else {
    //     sendemailfacture(objet, emailFrom, emailTo);
    //   }
    //     this.toast.success("Facture envoyée !", {
    //       position: "bottom-right",
    //       timeout: 1000,
    //       closeOnClick: true,
    //       pauseOnFocusLoss: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       draggablePercent: 0.6,
    //       showCloseButtonOnHover: false,
    //       hideProgressBar: false,
    //       closeButton: "button",
    //       icon: true,
    //       rtl: false,
    //     });
    //     this.showEmail[index] = false;
    //   }
    // },
    async createPDF(objet) {
      this.loadingPDF = true;
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (objet.regroupe) {
        await this.createPDFfacture(objet._id, dateedition, datepdf);
      } else {
        if (dateedition == datepdf) {
          if (objet.TotalPrice >= 0) {
            createfacture(objet, false, false);
          } else {
            createfacture(objet, false, true);
          }
        } else if (objet.TotalPrice >= 0) {
          createfacture(objet, true, false);
        } else {
          createfacture(objet, true, true);
        }
      }
      this.loadingPDF = false;
    },
    async createPDFfacture(id, dateedition, datepdf) {
      await axios
        .post(`${process.env.VUE_APP_API}/facture/getAllBdlsById`, {
          id: id,
        })
        .then((objet) => {
          if (dateedition == datepdf) {
            if (objet.data.TotalPrice >= 0) {
              createfacturepro(objet.data, false, false);
            } else {
              createfacturepro(objet.data, false, true);
            }
          } else if (objet.data.TotalPrice >= 0) {
            createfacturepro(objet.data, true, false);
          } else {
            createfacturepro(objet.data, true, true);
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadingPDF = false;
        });
    },
    showModalModifFacture(Facture) {
      this.showModifFacture = true;
      this.tabModifFacture = Facture;
    },
    async updateFacture() {
      this.tabModifFacture.reglement[
        this.getIndexR(this.tabModifFacture.reglement)
      ].model = parseFloat(this.setRendu).toFixed(2);
      if (this.setRestant == 0) {
        axios
          .put(
            `${process.env.VUE_APP_API}/facture/${this.tabModifFacture._id}`,
            this.tabModifFacture
          )
          .then(() => {
            this.showModifFacture = false;
          });
      } else {
        alert("Le reste n'est pas égal à 0.");
      }
    },
    sortByDate() {
      this.infosFacture.sort((a, b) => {
        if (a.Date < b.Date) {
          return -1;
        }
        if (a.Date > b.Date) {
          return 1;
        }
        return 0;
      });
    },
    reverseByDate() {
      this.sortDate = this.infosFacture.sort((a, b) => {
        if (a.Date < b.Date) {
          return -1;
        }
        if (a.Date > b.Date) {
          return 1;
        }
        return 0;
      });
      this.sortDate.reverse((a, b) => {
        if (a.Date < b.Date) {
          return -1;
        }
        if (a.Date > b.Date) {
          return 1;
        }
        return 0;
      });
    },
    sortByNumero() {
      this.infosFacture.sort((a, b) => {
        if (a.Numero < b.Numero) {
          return -1;
        }
        if (a.Numero > b.Numero) {
          return 1;
        }
        return 0;
      });
    },
    reverseByNumero() {
      this.sortNumero = this.infosFacture.sort((a, b) => {
        if (a.Numero < b.Numero) {
          return -1;
        }
        if (a.Numero > b.Numero) {
          return 1;
        }
        return 0;
      });
      this.sortNumero.reverse((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
    },
    sortByAlpha() {
      this.infosFacture.sort((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
    },
    reverseByAlpha() {
      this.sortAlpha = this.infosFacture.sort((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
      this.sortAlpha.reverse((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
    },
    sortByUsername() {
      this.infosFacture.sort((a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      });
    },
    reverseByUsername() {
      this.sortUsername = this.infosFacture.sort((a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      });
      this.sortUsername.reverse((a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      });
    },
    sortByTotalPrice() {
      this.infosFacture.sort((a, b) => {
        if (a.TotalPrice < b.TotalPrice) {
          return -1;
        }
        if (a.TotalPrice > b.TotalPrice) {
          return 1;
        }
        return 0;
      });
    },
    reverseByTotalPrice() {
      this.sortTotalPrice = this.infosFacture.sort((a, b) => {
        if (a.TotalPrice < b.TotalPrice) {
          return -1;
        }
        if (a.TotalPrice > b.TotalPrice) {
          return 1;
        }
        return 0;
      });
      this.sortTotalPrice.reverse((a, b) => {
        if (a.TotalPrice < b.TotalPrice) {
          return -1;
        }
        if (a.TotalPrice > b.TotalPrice) {
          return 1;
        }
        return 0;
      });
    },
    filterByValue(value) {
      var filterTab = [];
      if (value != "") {
        this.infosFacture = this.infosFactureTmp;
        this.infosFacture.filter((obj) => {
          //loop through each object
          for (const key in obj) {
            if (
              obj[key].toString().toLowerCase().includes(value.toLowerCase())
            ) {
              filterTab.push(obj);
              break;
            }
          }
        });
        this.infosFacture = filterTab;
      }
    },
    saveStatus(id, data) {
      axios.put(`${process.env.VUE_APP_API}/facture/${id}`, data).then(() => {
        this.toast.success("Statut enregistré !", {
          position: "bottom-right",
          timeout: 1000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      });
    },
    async changeStatusSelection() {
      this.loading = true;
      for (const [index, line] of this.selectedFacture.entries()) {
        await axios.put(`${process.env.VUE_APP_API}/facture/${line._id}`, {
          status: this.allStatus,
        });
      }
      this.loading = false;
      this.toast.success("Statuts enregistrés !", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      this.selectedFacture = [];
      this.selectAll = [];
      this.getInfosFacture(this.currentPage, 10);
    },
    selectAllFact() {
      for (const [index, line] of this.infosFacture.entries()) {
        if (this.selectAll == true) {
          this.selectedFacture[index] = line;
        } else {
          this.selectedFacture = [];
        }
      }
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getInfosFacture(skipValue, limitValue);
        this.getInfosFactureLength();
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(`${process.env.VUE_APP_API}/facture/searchInPlateform`, {
            plateform: this.user.proprietaire,
            searchTerm: searchTerm,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          })
          .then((response) => {
            this.searchInProgress = false;
            this.infosFacture = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
    async getFactures() {
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      await axios
        .post(`${process.env.VUE_APP_API}/facture/getbyowner`, {
          uid: plateform_mere,
        })
        .then((response) => {
          this.Factures = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async exportExcel() {
      this.loadingExport = true;
      await this.getFactures();
      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(this.Factures);
      XLSX.utils.book_append_sheet(wb, ws, "Factures");

      XLSX.writeFile(wb, "Liste_Factures" + this.user.proprietaire + ".xlsx");
      this.loadingExport = false;

      // this.success("Le fichier a été téléchargé");
    },
  },
  mounted() {
    this.getInfosFacture(1, 10);
    this.getInfosFactureLength();
    this.pageSwitch();
  },
};
</script>

<style>
.modal {
  transition: opacity 0.25s ease;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
