<template>
  <!-- modal pour annuler ou reprendre -->
  <div
    v-if="openCancelCmd || openDesarchivedCmd"
    :class="`modal ${
      !openCancelCmd && !openDesarchivedCmd && 'opacity-0 pointer-events-none'
    } z-50 fixed   w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="(openCancelCmd = false), (openDesarchivedCmd = false)"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-height: 50rem; max-width: 38rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <div class="px-6 py-4 modal-content">
        <div class="flex justify-between pb-3">
          <div v-if="dataToChangeStatus">
            <p class="text-xl" v-if="openCancelCmd == true">
              Confirmation d'annulation de commande
            </p>
            <p class="text-xl" v-if="openDesarchivedCmd == true">
              Confirmation pour reprendre la commande
            </p>
          </div>
          <!-- <div
              class="z-50 cursor-pointer modal-close"
              @click="openCancelCmd = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div> -->
        </div>

        <div v-if="dataToChangeStatus">
          <div v-if="openCancelCmd == true">
            <h1>
              Êtes-vous sûr(e) de vouloir annuler définitivement la commande
              n°{{ dataToChangeStatus.BCNumber }}
              ?
            </h1>
            <p class="mt-2">Veuillez indiquer la raison de l'annulation :</p>
            <h2>
              <textarea
                required
                rows="2"
                cols="40"
                class="w-full border border-2 px-1 py-0.5 focus:outline-2 outline-sky-300"
                placeholder="Ex. : Rupture de stock"
                v-model="dataToChangeStatus.cancellation.reason"
              ></textarea>
            </h2>
            <p class="text-xs" style="font-style: italic; color: #888">
              * Ce message sera visible par le client.
            </p>
          </div>
          <div v-if="openDesarchivedCmd == true">
            Êtes-vous sûr(e) de vouloir reprendre la commande n°{{
              dataToChangeStatus.BCNumber
            }}
            ?
          </div>
        </div>

        <div class="flex justify-end pt-2">
          <button
            @click="(openCancelCmd = false), (openDesarchivedCmd = false)"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
          <div v-if="openCancelCmd == true && openDesarchivedCmd == false">
            <button
              @click="validateCancel()"
              v-if="!loadingArchiver"
              class="p-3 px-6 py-3 mr-2 text-red-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-red-400 focus:outline-none"
            >
              Valider pour annuler
            </button>
            <button
              v-else-if="loadingArchiver"
              disabled
              class="p-3 px-6 py-3 mr-2 text-gray-500 bg-transparent rounded-lg focus:outline-none animate-pulse"
            >
              Valider pour annuler
            </button>
          </div>

          <button
            v-if="openCancelCmd == false && openDesarchivedCmd == true"
            @click="validateResume()"
            class="p-3 px-6 py-3 mr-2 text-red-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-red-400 focus:outline-none"
          >
            Valider pour reprendre
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- fin modal pour annuler ou reprendre -->

  <!-- modal détail cmd -->
  <div
    v-if="infoInCmd"
    :class="`modal ${
      !infoInCmd && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-screen top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="infoInCmd = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      style="max-width: 70rem"
      class="z-50 w-11/12 mx-auto max-h-[40rem] bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Récapitulatif de la commande</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="infoInCmd = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div class="flex justify-between">
          <div class="w-1/3 ml-2">
            Référence client:
            {{ detailCommande.Note }}
          </div>

          <div class="flex space-x-2">
            <div
              v-if="
                detailCommande.BCNumber &&
                !detailCommande.BCNumber.startsWith('R')
              "
              class="flex flex-col my-auto items-center ml-auto mx-10"
            >
              <span
                class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
                style="color: blue"
                @click="openBC(detailCommande)"
              >
                download
              </span>
              <div>BC n° {{ detailCommande.BCNumber }}</div>
            </div>
            <div
              v-if="detailCommande.BLNumber"
              class="flex flex-col my-auto items-center ml-auto mx-10"
            >
              <span
                class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
                style="color: blue"
                @click="openBL(detailCommande)"
              >
                download
              </span>
              <div>BL n° {{ detailCommande.BLNumber }}</div>
            </div>
            <div
              v-if="detailCommande.BLNumber"
              class="flex flex-col my-auto items-center ml-auto mx-10"
            >
              <span
                class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
                style="color: blue"
                @click="openBT(detailCommande)"
              >
                download
              </span>
              <div>BT n° {{ detailCommande.BLNumber }}</div>
            </div>
          </div>
        </div>

        <table class="min-w-full mt-4">
          <div class="overflow-y-auto max-h-[25rem]">
            <thead class="tableHEADTR">
              <tr>
                <th
                  class="w-44 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Réf.
                </th>
                <th
                  class="w-80 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Description
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Qté
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  P.V.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Remise
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Px final
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Total
                </th>
              </tr>
            </thead>
            <tbody class="bg-white w-fit">
              <tr
                v-for="(cmd, index) in detailCommande.Articles"
                :key="index"
                class="tableHEADTR"
              >
                <td
                  class="w-44 px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="flex flex-row items-center">
                    <span class="ml-4 text-sm leading-5 text-gray-900">
                      {{ cmd.Ref_fournisseur }}
                    </span>
                  </div>
                </td>

                <td
                  class="w-80 px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  style="overflow: none"
                >
                  <div class="text-sm leading-5 text-gray-500 overflow-y-auto">
                    {{ cmd.Description }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="text-sm leading-5">
                    {{ cmd.quantity }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  {{ parseFloat(cmd.Prix_euro).toFixed(2) }}
                  €
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div v-if="cmd.PrixFournisseurType != 'prixnet'">
                    {{ cmd.remise }} %
                  </div>
                  <div v-else>prix net</div>
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <span v-if="cmd.PrixFournisseurType == 'prixnet'">
                    {{ parseFloat(cmd.Prix_vente).toFixed(2) }}
                    €
                  </span>
                  <span v-else>
                    {{
                      parseFloat(
                        cmd.Prix_euro - (cmd.Prix_euro * cmd.remise) / 100
                      ).toFixed(2)
                    }}
                    €
                  </span>
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <span v-if="cmd.PrixFournisseurType == 'prixnet'">
                    {{ parseFloat(cmd.Prix_vente * cmd.quantity).toFixed(2) }}
                    €
                  </span>
                  <span v-else>
                    {{
                      parseFloat(
                        (cmd.Prix_euro - (cmd.Prix_euro * cmd.remise) / 100) *
                          cmd.quantity
                      ).toFixed(2)
                    }}
                    €
                  </span>
                </td>
              </tr>
            </tbody>
          </div>
          <thead class="tableHEADTR mt-12 border-t">
            <tr class="tableHEADTR">
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="w-64 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                <div class="text-sm leading-5 text-gray-900">
                  Total HT :
                  {{ parseFloat(detailCommande.TotalPrice).toFixed(2) }} €
                </div>
                <div
                  v-if="detailCommande.TotalPriceTTC"
                  class="text-sm leading-5 text-gray-500"
                >
                  Total TTC :
                  {{ parseFloat(detailCommande.TotalPriceTTC).toFixed(2) }} €
                </div>
                <div v-else class="text-sm leading-5 text-gray-500">
                  Total TTC :
                  {{
                    parseFloat(
                      detailCommande.TotalPrice +
                        detailCommande.TotalPrice * 0.2
                    ).toFixed(2)
                  }}
                  €
                </div>
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
  <!-- fin modal détail cmd -->

  <!-- BODY -->
  <div
    class="flex flex-row justify-between bg-slate-100 rounded-md shadow items-center"
  >
    <div class="flex space-x-2">
      <!-- actif -->
      <button
        class="hover:shadow-lg flex mr-4 justify-between items-center w-32 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'actif'"
        @click="getInfosCommandes(1, 10)"
      >
        <span class="m-auto"> Actif </span>
        <span class="material-icons-outlined" style="font-size: 16px">
          notifications
        </span>
      </button>
      <button
        class="hover:shadow-lg flex mr-4 justify-between items-center w-32 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="getInfosCommandes(1, 10)"
      >
        <span class="m-auto"> Actif </span>
        <span class="material-icons-outlined" style="font-size: 16px">
          notifications
        </span>
      </button>

      <!-- preparation -->
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'preparation'"
        @click="getByStatus(1, 10, 'preparation', 'Commande validée')"
      >
        <span class="m-auto"> En préparation </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          inventory
        </span>
      </button>
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="getByStatus(1, 10, 'preparation', 'Commande validée')"
      >
        <span class="m-auto"> En préparation </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          inventory
        </span>
      </button>

      <!-- commandée -->
      <button
        class="hover:shadow-lg flex mr-4 justify-between items-center w-32 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'commandée'"
        @click="getByStatus(1, 10, 'commandée', 'Commandé chez le fournisseur')"
      >
        <span class="m-auto"> Commandée </span>
        <span class="material-icons-outlined" style="font-size: 16px">
          local_shipping
        </span>
      </button>
      <button
        class="hover:shadow-lg flex mr-4 justify-between items-center w-32 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="getByStatus(1, 10, 'commandée', 'Commandé chez le fournisseur')"
      >
        <span class="m-auto"> Commandée </span>
        <span class="material-icons-outlined" style="font-size: 16px">
          local_shipping
        </span>
      </button>

      <!-- prete -->
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'prete'"
        @click="getByStatus(1, 10, 'prete', 'Commande prête')"
      >
        <span class="m-auto"> Prête </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          done_all
        </span>
      </button>
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="getByStatus(1, 10, 'prete', 'Commande prête')"
      >
        <span class="m-auto"> Prête </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          done_all
        </span>
      </button>

      <!-- valide -->
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'valide'"
        @click="getByStatus(1, 10, 'valide', 'Commande et BL disponibles')"
      >
        <span class="m-auto"> Validée </span>
        <span class="material-icons-round" style="font-size: 14px">
          inbox
        </span>
      </button>
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="getByStatus(1, 10, 'valide', 'Commande et BL disponibles')"
      >
        <span class="m-auto"> Validée </span>
        <span class="material-icons-round" style="font-size: 14px">
          inbox
        </span>
      </button>

      <!-- attente -->
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'attente'"
        @click="getByStatus(1, 10, 'attente', 'Commande en attente')"
      >
        <span class="m-auto"> En attente </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          hourglass_empty
        </span>
      </button>
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="getByStatus(1, 10, 'attente', 'Commande en attente')"
      >
        <span class="m-auto"> En attente </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          hourglass_empty
        </span>
      </button>

      <!-- annule -->
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-if="tabState == 'annule'"
        @click="getByStatus(1, 10, 'annule', 'Commande annulée')"
      >
        <span class="m-auto"> Annulée </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          cancel
        </span>
      </button>
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="getByStatus(1, 10, 'annule', 'Commande annulée')"
      >
        <span class="m-auto"> Annulée </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          cancel
        </span>
      </button>

      <!-- archived -->
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'archived'"
        @click="getArchived(1, 10)"
      >
        <span class="m-auto"> Archivée </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          inventory_2
        </span>
      </button>
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="getArchived(1, 10)"
      >
        <span class="m-auto"> Archivée </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          inventory_2
        </span>
      </button>
    </div>

    <!-- more filter -->
    <div class="flex flex-col">
      <div
        v-show="showMoreFilter"
        @click="showMoreFilter = false"
        class="absolute w-screen h-screen z-10 top-0 left-0"
      ></div>
      <button
        class="hover:shadow-lg flex justify-between items-center w-22 h-fit bg-gray-600 p-1 text-white text-sm rounded border-2 focus:border-blue-600"
        @click="showMoreFilter = true"
      >
        <span class="m-auto"> Plus de filtre </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          filter_alt
        </span>
      </button>
      <div
        class="fixed z-20 bg-gray-100 shadow-lg mt-8 p-1 px-2 rounded-md w-36 border border-blue-500 flex border border-black"
        v-if="showMoreFilter"
      >
        <div v-if="plateform.structure_depot == true">
          <div v-if="cdtnDsv == false" class="flex space-x-2">
            <input
              type="checkbox"
              class="border"
              v-model="dsvcheck"
              @change="filterCmdDsv()"
            />
            <label>DA SILVA</label>
          </div>
          <div v-else class="flex space-x-2">
            <input
              type="checkbox"
              checked
              class="border"
              v-model="dsvcheck"
              @change="(cdtnDsv = false), (infosCommandes = tempInfosCommandes)"
            />
            <label>DA SILVA</label>
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex flex-col text-xs mr-4 text-orange-500 cursor-pointer"
      title="Actualiser"
      @click="reloadPage()"
    >
      <span
        class="material-icons-outlined m-auto border border-white rounded-full hover:bg-orange-400 hover:bg-opacity-25 shadow-sm"
        >refresh</span
      >
    </div>
  </div>

  <div class="flex justify-between mt-2">
    <searchInput
      v-if="tabState == 'actif'"
      placeholder="Rechercher dans commandes"
      @searchWord="search(1, 10, false, $event)"
      @catchFalse="searchCatchError = $event"
      :searchStarted="searchInProgress"
      :catchError="searchCatchError"
    />
    <searchInput
      v-if="tabState == 'archived'"
      placeholder="Rechercher dans commandes archivées"
      @searchWord="search(1, 10, true, $event)"
      @catchFalse="searchCatchError = $event"
      :searchStarted="searchInProgress"
      :catchError="searchCatchError"
    />
    <searchInput
      v-if="tabState != 'actif' && tabState != 'archived'"
      placeholder="Rechercher dans commandes"
      @searchWord="searchByStatus(1, 10, tabState, currentStatus, $event)"
      @catchFalse="searchCatchError = $event"
      :searchStarted="searchInProgress"
      :catchError="searchCatchError"
    />

    <!-- <router-link
      to="/regrouperCommandePro"
      class="bg-bluevk text-white hover:bg-blue-900 border border-bluevk shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
    >
      Clôturer commandes
    </router-link> -->
  </div>

  <div class="flex flex-col mt-2">
    <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                N° Commande
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                Date
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Statut
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                Commandé par
              </th>
              <th
                class="md:px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Progression
              </th>
              <th
                class="py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                HT
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                TTC
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                BL
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                BT
              </th>
            </tr>
          </thead>

          <div
            v-show="loadingFile"
            class="flex justify-center w-full bg-white items-center space-x-2 mt-4"
          >
            <span>En cours de chargement</span>
            <PulseLoader color="#2d74da" />
          </div>

          <tbody class="bg-white" v-show="!loadingFile">
            <tr
              v-for="(u, index) in infosCommandes"
              :key="index"
              style="cursor: pointer"
              :class="
                u.cmdPro ? 'hover:bg-gray-100' : 'bg-blue-100 hover:bg-blue-200'
              "
            >
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                <div class="flex justify-between items-center">
                  <div class="flex flex-col">
                    <div class="text-sm font-medium leading-5 text-gray-900">
                      {{ u.BCNumber }}
                    </div>
                    <div
                      class="text-sm font-medium leading-5 text-gray-900"
                      v-if="
                        plateform.structure_depot == true &&
                        ((u.textFile && u.textFile.consignataire != '') ||
                          u.Fournisseur == user.proprietaire)
                      "
                    >
                      {{ u.Fournisseur }}
                    </div>
                  </div>
                  <div
                    v-if="u.status == 'Commande annulée'"
                    title="Reprendre la commande"
                  >
                    <button
                      class="material-icons-round text-red-600 items-center bg-gray-100 rounded-full p-1 shadow hover:bg-gray-200"
                      @click="openModalDesarchived(u)"
                    >
                      <span class="material-icons-rounded mt-1 m-auto">
                        unarchive
                      </span>
                    </button>
                  </div>
                  <div v-else>
                    <button
                      class="material-icons-round text-red-600 items-center bg-gray-100 rounded-full p-1 shadow hover:bg-gray-200"
                      @click="openModalCancel(u)"
                    >
                      <span class="material-icons-rounded mt-1 m-auto">
                        highlight_off
                      </span>
                    </button>
                  </div>
                  <!-- <div
                    v-else
                    class="py-4 border-b border-gray-200 whitespace-nowrap"
                  ></div> -->
                </div>
              </td>

              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
                @click="setTempCommande(u)"
              >
                <div class="text-sm leading-5 text-gray-900">
                  {{ getFRDate(u.Date) }}
                </div>
              </td>
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                <span
                  @click="getDetailCmd(u)"
                  title="Plus d'informations"
                  class="material-icons-outlined text-md text-cyan-600 hover:text-cyan-700 cursor-pointer"
                >
                  info
                </span>
              </td>
              <td
                class="px-3 md:px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                @click="setTempCommande(u)"
              >
                <div class="flex flex-col">
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 rounded-full w-fit"
                  >
                    {{ u.status }}
                  </span>
                  <div
                    v-if="u.status == 'Commande annulée' && u.cancellation"
                    class="text-xs rounded-md bg-orange-400 text-white w-fit px-1 text-sm overflow-x-auto"
                    :title="u.cancellation.reason"
                  >
                    <h4>
                      Raison d'annulation:
                      {{
                        u.cancellation.reason.length > 11
                          ? u.cancellation.reason.substr(0, 11) + "..."
                          : u.cancellation.reason
                      }}
                    </h4>
                    <h4>Par: {{ u.cancellation.user }}</h4>
                  </div>
                  <span
                    v-if="u.status == 'Commandé chez le fournisseur'"
                    class="rounded-md bg-orange-400 text-white w-fit px-1 text-xs md:text-sm overflow-x-auto"
                    >{{ u.commandeFournisseur.user }}:
                    {{ u.commandeFournisseur.message }}</span
                  >
                </div>
                <div class="text-xs leading-5 text-gray-900 md:hidden">
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                    >{{ u.Client }}</span
                  >
                </div>
              </td>

              <td
                class="pl-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
                @click="setTempCommande(u)"
              >
                <span
                  class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                  >{{ u.Name }}</span
                >
                <div class="text-sm leading-5 text-gray-900">
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                    >{{ u.Client }}</span
                  >
                </div>
              </td>

              <td
                class="md:pr-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                @click="setTempCommande(u)"
              >
                <progressBar
                  v-if="u.status == 'Commande annulée'"
                  :pourcentageStatus="parseInt(u.PourcentageStatus)"
                  :isRed="true"
                  class="hidden md:block"
                />
                <progressBar
                  v-else
                  :pourcentageStatus="parseInt(u.PourcentageStatus)"
                  :isRed="false"
                  class="hidden md:block"
                />
                <span class="md:hidden">{{ u.PourcentageStatus }} %</span>
              </td>

              <td
                class="py-4 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                <div
                  v-if="
                    u.status == 'Commande et BL disponibles' &&
                    tabState != 'archived'
                  "
                >
                  <button
                    class="text-orange-600 bg-gray-100 hover:bg-gray-200 items-center rounded-full shadow px-1"
                    @click="archiverTouteCommande(u)"
                    title="Archiver"
                  >
                    <span class="material-icons-outlined mt-1 m-auto"
                      >archive</span
                    >
                  </button>
                </div>

                <div v-else @click="setTempCommande(u)"></div>
              </td>

              <td
                class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
                @click="setTempCommande(u)"
              >
                {{ parseFloat(u.TotalPrice).toFixed(2) }} €
              </td>

              <td
                v-if="u.TotalPriceTTC"
                @click="setTempCommande(u)"
                class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                {{ parseFloat(u.TotalPriceTTC).toFixed(2) }} €
              </td>
              <td
                v-else
                @click="setTempCommande(u)"
                class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                {{ parseFloat(u.TotalPrice + u.TotalPrice * 0.2).toFixed(2) }} €
              </td>

              <td
                class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                <div class="flex flex-col w-fit">
                  <span
                    class="w-fit z-20 material-icons-round cursor-pointer rounded-full shadow-lg p-1"
                    v-if="u.BLNumber"
                    style="color: blue"
                    @click="openBL(u)"
                  >
                    download
                  </span>

                  <span class="m-auto">{{ u.BLNumber }}</span>
                </div>
              </td>
              <!--  -->
              <td
                class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                <div class="flex flex-col w-fit">
                  <span
                    class="w-fit z-20 material-icons-round cursor-pointer rounded-full shadow-lg p-1"
                    v-if="u.BLNumber"
                    style="color: blue"
                    @click="openBT(u)"
                  >
                    download
                  </span>
                  <span class="m-auto">{{ u.BLNumber }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <pageNumber
          v-if="tabState == 'actif' && !searchActive"
          :pageStart="pageStart"
          :pageNext="pageNext"
          :tabList="infosCommandesLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(infosCommandesLength / 10)"
          :clickFunction="getInfosCommandes"
          :currentPage="currentPage"
          :typeList="'commandes'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />

        <pageNumber
          v-if="tabState != 'actif' && tabState != 'archived' && !searchActive"
          :pageStart="pageStart"
          :pageNext="pageNext"
          :tabList="byStatusLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(byStatusLength / 10)"
          :clickFunction="
            (arg1, arg2) => getByStatus(arg1, arg2, tabState, currentStatus)
          "
          :currentPage="currentPage"
          :typeList="'commandes'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />

        <pageNumber
          v-if="tabState == 'archived' && !searchActive"
          :pageStart="pageStart"
          :pageNext="pageNext"
          :tabList="archivedLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(archivedLength / 10)"
          :clickFunction="getArchived"
          :currentPage="currentPage"
          :typeList="'commandes'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />

        <!-- searchActive -->
        <pageNumber
          v-if="tabState == 'actif' && searchActive"
          :pageStart="pageStart"
          :pageNext="pageNext"
          :tabList="searchLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(searchLength / 10)"
          :clickFunction="(arg1, arg2) => search(arg1, arg2, false, searchTerm)"
          :currentPage="currentPage"
          :typeList="'commandes'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />
        <pageNumber
          v-if="tabState != 'actif' && tabState != 'archived' && searchActive"
          :pageStart="pageStart"
          :pageNext="pageNext"
          :tabList="searchLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(searchLength / 10)"
          :clickFunction="
            (arg1, arg2) =>
              searchByStatus(arg1, arg2, tabState, currentStatus, searchTerm)
          "
          :currentPage="currentPage"
          :typeList="'commandes'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />
        <pageNumber
          v-if="tabState == 'archived' && searchActive"
          :pageStart="pageStart"
          :pageNext="pageNext"
          :tabList="searchLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(searchLength / 10)"
          :clickFunction="(arg1, arg2) => search(arg1, arg2, true, searchTerm)"
          :currentPage="currentPage"
          :typeList="'commandes'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import ProgressBar from "@/components/Commandes/progressBar.vue";
import { createcommande } from "./../hooks/pdf/commande.js";
import { createlivraison } from "./../hooks/pdf/livraison.js";
import { createtransport } from "./../hooks/pdf/transport.js";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import { useToast } from "vue-toastification";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";
import { unreserveStock, reserveStock } from "@/hooks/stock/stock.ts";
import {
  cancelCommand,
  resumeCommand,
} from "@/hooks/transport/panierTransport.ts";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data: () => ({
    infosCommandes: [],
    tempInfosCommandes: [],
    tabState: "",
    currentStatus: "",
    scanEAN: "",
    valideCommandestate: true,
    prepareCommandestate: false,
    endCommandestate: false,
    envoiCommandestate: false,
    tabCommandestate: [true, false, false, false, true],
    tempCommande: {
      cmdstate: { 0: true, 1: false, 2: false, 3: false, 4: true },
    },
    tempNBArticles: 0,
    validateTempArt: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    validateTempEAN: [],
    showErrorPrepaArticle: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    articlePrepState: [],
    quantitePrep: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0,
    ],
    numDoc: 0,
    numDocConsigne: 0,
    index: 0,
    tabDasilva: [],
    dsvcheck: false,
    cdtnDsv: false,
    pdfinfo: [],
    showMoreFilter: false,
    loadingFile: false,
    showRefSimilar: [],
    similarRef: [],
    loadingRef: false,
    autoCompleteTab: [],
    showAutoComplete: false,
    openModalSearch: false,
    openModalSearchVanHeck: false,
    foundArticle: [],
    searchInput: "",
    showEdit: false,
    openCancelCmd: false,
    openDesarchivedCmd: false,
    dataToChangeStatus: null,

    pageTab: [],
    pageNext: 11,
    pageStart: 1,
    currentPage: 1,
    infosCommandesLength: 0,
    byStatusLength: 0,
    archivedLength: 0,

    searchActive: false,
    searchTerm: "",
    searchLength: 0,
    searchInProgress: false,
    searchCatchError: false,

    infoInCmd: false,
    detailCommande: [],

    loadingArchiver: false,
  }),
  components: {
    PulseLoader,
    ProgressBar,
    pageNumber,
    searchInput,
  },
  computed: {
    ...mapGetters(["user", "plateform", "cmdPreparation"]),
    setTodayDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;
      return today;
    },
  },
  methods: {
    ...mapActions([
      "suppStockArticle",
      "addStockArticle",
      "setMvtStock",
      "searchDirect",
      "searchTranslatedDirect",
      "getCmdPreparation",
    ]),
    getFRDate(date) {
      return getFrDate(date);
    },
    getInfosCommandes(skipValue, limitValue) {
      this.loadingFile = true;
      this.tabState = "actif";
      this.currentPage = skipValue;
      axios
        .post(`${process.env.VUE_APP_API}/commandesClient/getbyowner/getpage`, {
          plateform: this.user.proprietaire,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          console.log(response.data);
          this.infosCommandes = response.data;
          this.tempInfosCommandes = response.data;

          this.tabDasilva = [];
          this.cdtnDsv = false;
          this.loadingFile = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosCommandesLength() {
      axios
        .post(`${process.env.VUE_APP_API}/commandesClient/getbyowner/count`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.infosCommandesLength = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // By status
    getByStatus(skipValue, limitValue, tabState, status) {
      console.log(skipValue, limitValue, tabState, status);
      this.loadingFile = true;
      this.tabState = tabState;
      this.currentPage = skipValue;
      this.currentStatus = status;
      axios
        .post(
          `${process.env.VUE_APP_API}/commandesClient/getbystatus/getPage`,
          {
            plateform: this.user.proprietaire,
            status: status,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          }
        )
        .then((response) => {
          this.getByStatusLength(status);
          this.infosCommandes = response.data;
          this.tempInfosCommandes = response.data;
          this.tabDasilva = [];
          this.cdtnDsv = false;
          this.loadingFile = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getByStatusLength(status) {
      console.log(status);
      axios
        .post(`${process.env.VUE_APP_API}/commandesClient/getbystatus/count`, {
          plateform: this.user.proprietaire,
          status: status,
        })
        .then((response) => {
          this.byStatusLength = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // archive
    getArchived(skipValue, limitValue) {
      this.loadingFile = true;
      this.tabState = "archived";
      this.currentPage = skipValue;
      axios
        .post(
          `${process.env.VUE_APP_API}/commandesClient/getallarchived/getPage`,
          {
            plateform: this.user.proprietaire,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          }
        )
        .then((response) => {
          this.getArchivedLength("archived");
          this.infosCommandes = response.data;
          this.tempInfosCommandes = response.data;
          this.tabDasilva = [];
          this.cdtnDsv = false;
          this.loadingFile = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArchivedLength(status) {
      axios
        .post(
          `${process.env.VUE_APP_API}/commandesClient/getallarchived/count`,
          {
            plateform: this.user.proprietaire,
            status: status,
          }
        )
        .then((response) => {
          this.archivedLength = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openModalCancel(u) {
      this.openCancelCmd = true;
      this.openDesarchivedCmd = false;
      this.dataToChangeStatus = u;
      console.log(this.dataToChangeStatus);
    },
    openModalDesarchived(u) {
      this.openDesarchivedCmd = true;
      this.openCancelCmd = false;
      this.dataToChangeStatus = u;
      console.log(this.dataToChangeStatus);
    },
    async validateCancel() {
      if (!this.dataToChangeStatus.cancellation.reason) {
        this.toast.warning("Veuillez indiquer la raison de l'annulation.", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } else {
        this.loadingArchiver = true;
        await this.changeStatusCommande(
          this.dataToChangeStatus,
          true,
          "Commande annulée",
          this.dataToChangeStatus.PourcentageStatus,
          "Commande annulée !",
          this.dataToChangeStatus.cancellation.reason
        );
        this.loadingArchiver = false;

        await cancelCommand(
          this.user.proprietaire,
          this.dataToChangeStatus.clientInfo[0]._id,
          this.dataToChangeStatus.BCNumber.toString()
        );
      }
    },
    async validateResume() {
      await this.changeStatusCommande(
        this.dataToChangeStatus,
        false,
        this.dataToChangeStatus.status,
        this.dataToChangeStatus.PourcentageStatus,
        "Commande activée !",
        ""
      );

      let BCnum;
      if (this.dataToChangeStatus.BLNumber) {
        BCnum = this.dataToChangeStatus.BLNumber.toString();
      }

      await resumeCommand(
        this.user.proprietaire,
        this.dataToChangeStatus.clientInfo[0]._id,
        this.user.username,
        {
          type: this.dataToChangeStatus.BLNumber
            ? "Bon de livraison"
            : "Bon de commande",
          created_here: "préparation de commande",
          BCnumber: this.dataToChangeStatus.BCNumber.toString(),
          BCdate: this.dataToChangeStatus.Date,
          BCuser: this.dataToChangeStatus.Name,
          BCtotalPrice: this.dataToChangeStatus.TotalPrice,
          BLnumber: BCnum,
          BLdate: this.dataToChangeStatus.BLNumber
            ? this.dataToChangeStatus.Date
            : "",
          BLuser: this.dataToChangeStatus.BLNumber ? this.user.username : "",
          BLtotalPrice: this.dataToChangeStatus.BLNumber
            ? this.dataToChangeStatus.TotalPrice
            : 0,
        }
      );
    },
    async changeStatusCommande(
      u,
      archived,
      status,
      percentage,
      info,
      reasonCancellation
    ) {
      var statusFinal;
      if (archived == false) {
        if (this.dataToChangeStatus.PourcentageStatus == 10) {
          statusFinal = "Commande Passée";
        } else if (this.dataToChangeStatus.PourcentageStatus == 25) {
          statusFinal = "Commande validée";
        } else if (this.dataToChangeStatus.PourcentageStatus == 75) {
          statusFinal = "Commande prête";
        } else if (this.dataToChangeStatus.PourcentageStatus == 100) {
          statusFinal = "Commande et BL disponibles";
        }
      } else {
        statusFinal = status;
      }

      await axios.put(`${process.env.VUE_APP_API}/commandesClient/${u._id}`, {
        archived: archived,
        status: statusFinal,
        PourcentageStatus: percentage,
        cancellation: {
          value: archived,
          reason: reasonCancellation,
          user: this.user.username,
        },
      });

      await this.increDecreStock(u, archived);
      this.openCancelCmd = false;
      this.openDesarchivedCmd = false;
      this.getInfosCommandes(this.currentPage, 10);
      this.toast.success(info, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async increDecreStock(data, incre) {
      if (incre) {
        for (const [index, element] of data.Articles.entries()) {
          if (element.Prefixe_tarif) {
            await unreserveStock(
              this.user._id,
              this.user.proprietaire,
              element._id,
              element.Ref_fournisseur,
              element.Code_marque,
              element.Code_EAN,
              0,
              0,
              10,
              "",
              this.user.username,
              "BC n°" + data.BCNumber + " annulée par " + this.user.username,
              element.Prefixe_tarif,
              data.Client,
              data.BCNumber,
              "BL",
              "préparation de commande",
              element.quantity
            );
            // await incrementStockDecrementReserved(
            //   element.Code_EAN,
            //   element.quantity,
            //   element.quantity,
            //   this.user.proprietaire,
            //   element.Ref_fournisseur,
            //   element.Prefixe_tarif,
            //   element.Code_marque,
            //   this.user.username,
            //   "Commande n°" +
            //     data.BCNumber +
            //     " : Annulée par : " +
            //     this.user.username +
            //     ". +" +
            //     element.quantity +
            //     " en stock, -" +
            //     element.quantity +
            //     " en réservé.",
            //   data.Client,
            //   data.BCNumber
            // );
          }
        }
      } else {
        for (const [index, element] of data.Articles.entries()) {
          if (element.Prefixe_tarif) {
            await reserveStock(
              this.user._id,
              this.user.proprietaire,
              element._id,
              element.Ref_fournisseur,
              element.Code_marque,
              element.Code_EAN,
              0,
              0,
              10,
              "",
              this.user.username,
              "BC n°" + data.BCNumber + " reprise par " + this.user.username,
              element.Prefixe_tarif,
              this.user.proprietaire,
              data.BCNumber,
              "BC",
              "préparation de commande",
              element.quantity
            );

            // await decrementStockIncrementReserved(
            //   element.Code_EAN,
            //   element.quantity,
            //   element.quantity,
            //   this.user.proprietaire,
            //   element.Ref_fournisseur,
            //   element.Prefixe_tarif,
            //   element.Code_marque,
            //   this.user.username,
            //   "Commande n°" +
            //     data.BCNumber +
            //     " : Reprise par : " +
            //     this.user.username +
            //     ". -" +
            //     element.quantity +
            //     " en stock, +" +
            //     element.quantity +
            //     " en réservé.",
            //   data.Client,
            //   data.BCNumber
            // );
          }
        }
      }
    },
    getDetailCmd(object) {
      this.detailCommande = object;
      this.infoInCmd = true;
    },
    reloadPage() {
      window.location.reload();
    },
    setTempCommande(u) {
      this.getCmdPreparation(u);
      this.$router.push("/preparation");
      this.tempNBArticles = parseInt(this.cmdPreparation.Articles.length);
    },
    // archiver infosCommandes
    archiverTouteCommande(tab) {
      axios
        .put(`${process.env.VUE_APP_API}/commandesClient/${tab._id}`, {
          archived: true,
        })
        .then(() => {
          if (this.tabState == "actif") {
            this.getInfosCommandes(this.currentPage, 10);
          }
          if (this.tabState == "valide") {
            this.getValide();
          }
          this.toast.success("Commande archivée !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    filterCmdDsv() {
      this.tabDasilva = [];
      this.infosCommandes.forEach((element) => {
        if (element.Fournisseur && element.Fournisseur == "DA SILVA") {
          this.tabDasilva.push(element);
        }
      });
      this.infosCommandes = this.tabDasilva;
      this.cdtnDsv = true;
    },
    openBC(tab) {
      console.log(tab);
      console.log(tab.BCNumber);
      axios
        .post(`${process.env.VUE_APP_API}/commandes/getone`, {
          uid: {
            BCNumber: tab.BCNumber,
            plateform: this.user.proprietaire,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.createPDFcommande(response.data[0]);
        });
    },
    openBL(tab) {
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getone`, {
          uid: {
            BLNumber: tab.BLNumber,
            plateform: this.user.proprietaire,
          },
        })
        .then((response) => {
          this.createPDF(response.data[0]);
        });
    },
    openBT(tab) {
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getone`, {
          uid: {
            BLNumber: tab.BLNumber,
            plateform: this.user.proprietaire,
          },
        })
        .then((response) => {
          this.createPDFtransport(response.data[0]);
        });
    },
    createPDFcommande(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createcommande(objet, false);
      } else {
        createcommande(objet, true);
      }
    },
    createPDF(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createlivraison(objet, false, false);
      } else {
        createlivraison(objet, true, false);
      }
    },
    createPDFtransport(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createtransport(objet, false);
      } else {
        createtransport(objet, true);
      }
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    search(skipValue, limitValue, archive, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getInfosCommandes(skipValue, limitValue);
        this.getInfosCommandesLength();
        this.pageSwitch();
      } else {
        if (archive == false) {
          this.tabState = "actif";
        } else {
          this.tabState = "archived";
        }
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(
            `${process.env.VUE_APP_API}/commandesClient/searchInPlateform`,
            {
              plateform: this.user.proprietaire,
              archive: archive,
              searchTerm: searchTerm,
              skip: (skipValue - 1) * 10,
              limit: limitValue,
            }
          )
          .then((response) => {
            this.searchInProgress = false;
            this.infosCommandes = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
    searchByStatus(skipValue, limitValue, tabState, currentStatus, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getByStatus(skipValue, limitValue, tabState, currentStatus);
        this.pageSwitch();
      } else {
        // if (archive == false) {
        //   this.tabState = "actif";
        // } else {
        //   this.tabState = "archived";
        // }
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(
            `${process.env.VUE_APP_API}/commandesClient/searchInPlateformByStatus`,
            {
              plateform: this.user.proprietaire,
              status: currentStatus,
              searchTerm: searchTerm,
              skip: (skipValue - 1) * 10,
              limit: limitValue,
            }
          )
          .then((response) => {
            this.searchInProgress = false;
            this.infosCommandes = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
  },
  mounted() {
    this.getInfosCommandes(1, 10);
    this.getInfosCommandesLength();
    this.pageSwitch();
  },
};
</script>
