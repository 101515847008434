<template>
  <router-link to="/articlehub">
    <div id="retour" class="flex justify-start w-fit">
      <backButton />
    </div>
  </router-link>
  <!-- Start of the modal import impression excel  -->
  <div
    v-if="showFilePrint"
    :class="`modal ${
      !showFilePrint && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="closeImport()"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 mx-auto bg-white rounded shadow-lg modal-container max-h-128 max-w-8xl"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <div class="flex justify-between space-x-2 items-center">
            <div>
              <p class="text-2xl font-bold">Tableau de référence à imprimer</p>
            </div>
            <div class="flex flex-row items-center space-x-2">
              <select
                v-model="fournisseurSelected"
                placeholder="Fournisseur"
                style="font-size: 20px"
                @change="loadingCorrection = false"
                class="border-2 h-9 rounded-md focus:outline-sky-300 outline-2 font-bold"
              >
                <option
                  v-for="(f, indexF) in fournisseur"
                  :key="indexF"
                  :value="f.Prefixe_tarif"
                >
                  {{ f.CompanyName }}
                </option>
              </select>
              <span v-show="fournisseurNotSelected" class="text-red-500"
                >Veuillez choisir un fournisseur!</span
              >
            </div>
            <div v-show="loadingCorrection">Correction en cours ...</div>
          </div>
          <div class="z-50 cursor-pointer modal-close" @click="closeImport()">
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <div class="flex" v-show="loadingFilesPrint">
          <loading-import />
        </div>
        <div v-show="!loadingFilesPrint">
          <div
            class="py-2 my-2 overflow-y-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2 max-h-40"
          >
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
            >
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                    >
                      <select
                        class="form-select block w-full mt-1 text-white text-base bg-vk"
                        v-model="tabHeader[0]"
                      >
                        <option class="bg-white text-gray-800" value="Code_EAN">
                          Code_EAN
                        </option>
                        <option
                          class="bg-white text-gray-800"
                          value="Ref_fournisseur"
                        >
                          Ref_fournisseur
                        </option>
                      </select>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                    >
                      <select
                        class="form-select block w-full mt-1 text-white text-base bg-vk"
                        v-model="tabHeader[1]"
                      >
                        <option class="bg-white text-gray-800" value="Code_EAN">
                          Code_EAN
                        </option>
                        <option
                          class="bg-white text-gray-800"
                          value="Ref_fournisseur"
                        >
                          Ref_fournisseur
                        </option>
                      </select>
                    </th>
                  </tr>
                </thead>

                <tbody class="bg-white">
                  <!-- <tr v-for="(mp, index) in matricePrix" :key="index"> -->
                  <tr v-for="(readCondi, index) in readFilePrint" :key="index">
                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[0] }}
                    </td>

                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[1] }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="flex justify-end space-x-2">
            <button
              class="px-4 py-2 text-white bg-orange-500 rounded hover:bg-blue-600"
              @click="readFileFormatingPrint()"
              v-if="tabHeader.length > 1 && !loadingCorrection"
            >
              Corriger le tableau
            </button>
            <button
              class="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
              @click="filePrint()"
              v-if="tabHeader.length > 1 && !loadingCorrection"
            >
              Générer le pdf
            </button>
          </div>
        </div>

        <!-- End Body -->
      </div>
    </div>
  </div>
  <!-- End of modal import impression excel  -->

  <!-- Start of the modal import excel  -->
  <div
    v-if="showFile"
    :class="`modal ${
      !showFile && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="closeImport()"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 mx-auto bg-white rounded shadow-lg modal-container max-h-128 max-w-8xl"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <div class="flex flex-row space-x-2 items-center">
            <div>
              <p class="text-2xl font-bold">Tableau de stock à importer</p>
            </div>
            <div class="flex flex-row items-center space-x-2">
              <select v-model="fournisseurSelected">
                <option
                  v-for="(f, indexF) in fournisseur"
                  :key="indexF"
                  :value="f.Prefixe_tarif"
                >
                  {{ f.CompanyName }}
                </option>
              </select>
              <span v-show="fournisseurNotSelected" class="text-red-500"
                >Veuillez choisir un fournisseur!</span
              >
            </div>
            <div v-show="loadingCorrection">Correction en cours ...</div>
          </div>
          <div class="z-50 cursor-pointer modal-close" @click="closeImport()">
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <div class="flex" v-show="loadingFiles">
          <loading-import />
        </div>
        <div v-show="!loadingFiles">
          <div
            class="py-2 my-2 overflow-y-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2 max-h-40"
          >
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
            >
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                    >
                      <select
                        class="form-select block w-full mt-1 text-white text-base bg-vk"
                        v-model="tabHeader[0]"
                      >
                        <option
                          class="bg-white text-gray-800"
                          value="Ref_fournisseur"
                        >
                          Ref_fournisseur
                        </option>
                        <option class="bg-white text-gray-800" value="Code_EAN">
                          Code_EAN
                        </option>
                        <option class="bg-white text-gray-800" value="Adresse">
                          Adresse
                        </option>
                        <option class="bg-white text-gray-800" value="min">
                          min
                        </option>
                        <option class="bg-white text-gray-800" value="max">
                          max
                        </option>
                        <option class="bg-white text-gray-800" value="Stock">
                          Stock
                        </option>
                        <option
                          class="bg-white text-gray-800"
                          value="Prefixe_tarif"
                        >
                          Prefixe_tarif
                        </option>
                      </select>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                    >
                      <select
                        class="form-select block w-full mt-1 text-white text-base bg-vk"
                        v-model="tabHeader[1]"
                      >
                        <option
                          class="bg-white text-gray-800"
                          value="Ref_fournisseur"
                        >
                          Ref_fournisseur
                        </option>
                        <option class="bg-white text-gray-800" value="Code_EAN">
                          Code_EAN
                        </option>
                        <option class="bg-white text-gray-800" value="Adresse">
                          Adresse
                        </option>
                        <option class="bg-white text-gray-800" value="min">
                          min
                        </option>
                        <option class="bg-white text-gray-800" value="max">
                          max
                        </option>
                        <option class="bg-white text-gray-800" value="Stock">
                          Stock
                        </option>
                        <option
                          class="bg-white text-gray-800"
                          value="Prefixe_tarif"
                        >
                          Prefixe_tarif
                        </option>
                      </select>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                    >
                      <select
                        class="form-select block w-full mt-1 text-white text-base bg-vk"
                        v-model="tabHeader[2]"
                      >
                        <option
                          class="bg-white text-gray-800"
                          value="Ref_fournisseur"
                        >
                          Ref_fournisseur
                        </option>
                        <option class="bg-white text-gray-800" value="Code_EAN">
                          Code_EAN
                        </option>
                        <option class="bg-white text-gray-800" value="Adresse">
                          Adresse
                        </option>
                        <option class="bg-white text-gray-800" value="min">
                          min
                        </option>
                        <option class="bg-white text-gray-800" value="max">
                          max
                        </option>
                        <option class="bg-white text-gray-800" value="Stock">
                          Stock
                        </option>
                        <option
                          class="bg-white text-gray-800"
                          value="Prefixe_tarif"
                        >
                          Prefixe_tarif
                        </option>
                      </select>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                    >
                      <select
                        class="form-select block w-full mt-1 text-white text-base bg-vk"
                        v-model="tabHeader[3]"
                      >
                        <option
                          class="bg-white text-gray-800"
                          value="Ref_fournisseur"
                        >
                          Ref_fournisseur
                        </option>
                        <option class="bg-white text-gray-800" value="Code_EAN">
                          Code_EAN
                        </option>
                        <option class="bg-white text-gray-800" value="Adresse">
                          Adresse
                        </option>
                        <option class="bg-white text-gray-800" value="min">
                          min
                        </option>
                        <option class="bg-white text-gray-800" value="max">
                          max
                        </option>
                        <option class="bg-white text-gray-800" value="Stock">
                          Stock
                        </option>
                        <option
                          class="bg-white text-gray-800"
                          value="Prefixe_tarif"
                        >
                          Prefixe_tarif
                        </option>
                      </select>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                    >
                      <select
                        class="form-select block w-full mt-1 text-white text-base bg-vk"
                        v-model="tabHeader[4]"
                      >
                        <option
                          class="bg-white text-gray-800"
                          value="Ref_fournisseur"
                        >
                          Ref_fournisseur
                        </option>
                        <option class="bg-white text-gray-800" value="Code_EAN">
                          Code_EAN
                        </option>
                        <option class="bg-white text-gray-800" value="Adresse">
                          Adresse
                        </option>
                        <option class="bg-white text-gray-800" value="min">
                          min
                        </option>
                        <option class="bg-white text-gray-800" value="max">
                          max
                        </option>
                        <option class="bg-white text-gray-800" value="Stock">
                          Stock
                        </option>
                        <option
                          class="bg-white text-gray-800"
                          value="Prefixe_tarif"
                        >
                          Prefixe_tarif
                        </option>
                      </select>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                    >
                      <select
                        class="form-select block w-full mt-1 text-white text-base bg-vk"
                        v-model="tabHeader[5]"
                      >
                        <option
                          class="bg-white text-gray-800"
                          value="Ref_fournisseur"
                        >
                          Ref_fournisseur
                        </option>
                        <option class="bg-white text-gray-800" value="Code_EAN">
                          Code_EAN
                        </option>
                        <option class="bg-white text-gray-800" value="Adresse">
                          Adresse
                        </option>
                        <option class="bg-white text-gray-800" value="min">
                          min
                        </option>
                        <option class="bg-white text-gray-800" value="max">
                          max
                        </option>
                        <option class="bg-white text-gray-800" value="Stock">
                          Stock
                        </option>
                        <option
                          class="bg-white text-gray-800"
                          value="Prefixe_tarif"
                        >
                          Prefixe_tarif
                        </option>
                      </select>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                    >
                      <select
                        class="form-select block w-full mt-1 text-white text-base bg-vk"
                        v-model="tabHeader[6]"
                      >
                        <option
                          class="bg-white text-gray-800"
                          value="Ref_fournisseur"
                        >
                          Ref_fournisseur
                        </option>
                        <option class="bg-white text-gray-800" value="Code_EAN">
                          Code_EAN
                        </option>
                        <option class="bg-white text-gray-800" value="Adresse">
                          Adresse
                        </option>
                        <option class="bg-white text-gray-800" value="min">
                          min
                        </option>
                        <option class="bg-white text-gray-800" value="max">
                          max
                        </option>
                        <option class="bg-white text-gray-800" value="Stock">
                          Stock
                        </option>
                        <option
                          class="bg-white text-gray-800"
                          value="Prefixe_tarif"
                        >
                          Prefixe_tarif
                        </option>
                      </select>
                    </th>
                  </tr>
                </thead>

                <tbody class="bg-white">
                  <!-- <tr v-for="(mp, index) in matricePrix" :key="index"> -->
                  <tr v-for="(readCondi, index) in readFile" :key="index">
                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[0] }}
                    </td>

                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[1] }}
                    </td>
                    <td
                      class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[2] }}
                    </td>

                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[3] }}
                    </td>
                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[4] }}
                    </td>
                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[5] }}
                    </td>
                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[6] }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="flex justify-end space-x-2">
            <button
              class="px-4 py-2 text-white bg-orange-500 rounded hover:bg-blue-600"
              @click="readFileFormating()"
              v-if="tabHeader.length > 5 && !loadingCorrection"
            >
              Corriger le tableau
            </button>
            <button
              class="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
              @click="importFile()"
              v-if="tabHeader.length > 5 && !loadingCorrection"
            >
              Importer
            </button>
          </div>
        </div>

        <!-- End Body -->
      </div>
    </div>
  </div>
  <!-- End of modal import excel  -->
  <div class="flex flex-col">
    <div class="flex flex-row items-center justify-between">
      <div class="">
        <h5 class="text-gray-700" style="font-size: 30px">Etat des stocks</h5>
      </div>
      <div class="flex flex-row space-x-2 items-center">
        <label for="dropzone-file-print">
          <span
            class="bg-orange-500 p-2 text-white rounded shadow cursor-pointer"
          >
            Impression étiquettes
          </span>
          <input
            id="dropzone-file-print"
            type="file"
            class="hidden"
            accept=".xlsx, .xls, .csv"
            @change="readExcelPrint()"
          />
        </label>
        <label>
          <button
            v-if="loadingExport"
            class="flex bg-blue-500 p-2 text-white rounded shadow"
          >
            <PulseLoader color="#ffffff" />
            <h4>Exportation</h4>
          </button>
          <button
            v-else
            class="flex bg-blue-500 p-2 text-white rounded shadow"
            @click="exportExcel()"
          >
            <h4>Exporter en Excel</h4>
          </button></label
        >
        <label for="dropzone-file">
          <span
            class="p-2 bg-green-500 text-white rounded shadow cursor-pointer"
          >
            Importer excel
          </span>
          <input
            id="dropzone-file"
            type="file"
            class="hidden"
            accept=".xlsx, .xls, .csv"
            @change="readExcel()"
          />
        </label>
      </div>
    </div>
    <div class="flex flex-row space-x-2">
      <div id="Recherche" class="w-96 flex flex-row">
        <label
          for="default-search"
          class="mb-2 text-sm font-medium text-gray-900 sr-only"
          >Search</label
        >
        <!-- 1 -->
        <div class="relative">
          <div
            class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5 text-gray-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            type="text"
            id="default-search"
            placeholder="Rechercher par article"
            v-model="inputSearchArticle"
            @keyup.enter="searchArticle()"
            class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline-sky-300 outline-2"
            required
          />
        </div>
        <!-- 2 -->
        <div class="relative">
          <div
            class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5 text-gray-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            type="text"
            id="default-search"
            placeholder="Rechercher par adresse"
            v-model="inputSearchAdresse"
            @keyup.enter="searchAdresse()"
            class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline-sky-300 outline-2"
            required
          />
        </div>
      </div>
      <div>
        <button
          @click="searchAdresse()"
          class="text-white h-full bg-sky-700 hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
        >
          Rechercher
        </button>
      </div>
      <div
        class="w-full space-y-4 overflow-y-auto h-80"
        v-if="showErrorStock == true"
      >
        Veuillez remplir les champs de recherche pour trouver des articles
      </div>
    </div>
  </div>
  <div>
    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Article
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Code_EAN
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Dépot / Adresse
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Min/Max
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Stock
                </th>

                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="(u, index) in Stock" :key="index">
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ Stock[index].Ref_fournisseur }}
                  </div>
                  <div class="text-sm leading-5 text-gray-900">
                    {{ Stock[index].Prefixe_tarif }}
                  </div>
                  <div v-if="!u.Ref_fournisseur" class="">
                    <span
                      class="material-icons-outlined cursor-pointer text-orange-500"
                      @click="getRefByEAN(u.Code_EAN, index)"
                    >
                      manage_search
                    </span>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.Code_EAN }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-col">
                    <div>{{ u.plateform }}</div>
                    <div class="text-blue-500">
                      {{ u.adresse_article }}
                    </div>
                  </div>
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  Min : {{ u.min }} Max : {{ u.max }}
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-row items-center space-x-2">
                    <div>
                      <!-- <span v-if="u.stock > 0" class="text-green-600">{{
                        u.stock
                      }}</span>
                      <span v-else-if="u.stock == 0" class="text-orange-600">{{
                        u.stock
                      }}</span> -->
                      <!-- <span v-if="u.stock < 0" class="text-red-600">{{
                        u.stock
                      }}</span> -->
                      {{ u.stock }}
                    </div>
                    <div>
                      <span
                        class="material-icons-outlined text-blue-500 cursor-pointer"
                        @click="
                          getEtiquetaRangement(u.Code_EAN, u.Ref_fournisseur)
                        "
                      >
                        qr_code_scanner
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="flex justify-center">
                    Nbr article en stock : {{ stockList }}
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="flex justify-center">
                    Nbr de réf. : {{ stockList }}
                  </div>
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>
          </table>

          <pageNumber
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="stockList"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(stockList / 10)"
            :clickFunction="getPage"
            :currentPage="currentPage"
            :typeList="'articles'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import readXlsxFile from "read-excel-file";
// import React, { useState, useEffect } from 'react';
import * as XLSX from "xlsx";
import { useToast } from "vue-toastification";
import loadingImport from "../components/general/importFiles/loadingImport.vue";
import pageNumber from "../components/pageNumber.vue";
import {
  etiquetteRangement,
  etiquetteExcelRangement,
} from "@/hooks/pdf/etiquetteRangement.js";
import { searchArticles } from "@/hooks/searchEngine/articles.ts";
import { searchAdresses } from "@/hooks/searchEngine/articles.ts";
import { getStock } from "@/hooks/stock/stock.ts";
import backButton from "@/components/Button/back.vue";

export default {
  data() {
    return {
      allStock: [],
      loadingExport: false,

      Stock: [],
      readFile: null,
      file: null,
      showFile: false,
      loadingFiles: false,
      tabHeader: [],
      fournisseur: [],
      fournisseurNotSelected: false,
      fournisseurSelected: "",
      loadingCorrection: false,
      readFilePrint: null,
      showFilePrint: false,
      loadingFilesPrint: false,

      stockList: 0,
      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      inputSearchArticle: "",
      loadingArticle: false,
      showErrorStock: false,
      inputSearchAdresse: "",

      currentPage: 1,
      // pageNumber: 0,
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    loadingImport,
    pageNumber,
    backButton,
    PulseLoader,
  },
  computed: {
    ...mapGetters(["user", "comptoirSessionActive"]),
  },
  methods: {
    async getAll() {
      await axios
        .post(`${process.env.VUE_APP_API}/stock/getAll`, {
          plateform: this.user.proprietaire,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.length > 0) {
            this.allStock = res.data;
            console.log(this.allStock);
          }
        });
    },
    getPage(skipValue, limitValue) {
      this.currentPage = skipValue;
      axios
        .post(
          `${process.env.VUE_APP_API}/stock/articles/${this.user.proprietaire}`,
          {
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          }
        )
        .then((res) => {
          if (res.data.length > 0) {
            this.Stock = res.data;
          } else {
            this.showErrorStock = true;
          }
        });
      // axios.get(`${process.env.VUE_APP_API}/stock/pageNumber`).then((res) => {
      //   this.pageNumber = res.data;
      // });
    },
    getTotal() {
      axios
        .get(`${process.env.VUE_APP_API}/stock/count/${this.user.proprietaire}`)
        .then((res) => {
          this.stockList = res.data;
        });
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    // pageUp() {
    //   if (this.pageNext <= this.pageTotal) {
    //     this.pageStart = this.pageNext;
    //     this.pageTab.forEach((element, index) => {
    //       this.pageTab[index] = { pageNumber: this.pageNext };
    //       this.pageNext = this.pageNext + 1;
    //     });
    //   }
    // },
    // pageDown() {
    //   if (this.pageNext != 11) {
    //     this.pageNext = this.pageNext - 20;
    //     this.pageStart = this.pageNext;
    //     this.pageTab.forEach((element, index) => {
    //       this.pageTab[index] = { pageNumber: this.pageNext };
    //       this.pageNext = this.pageNext + 1;
    //     });
    //   }
    // },
    getRefByEAN(EAN, index) {
      axios
        .post(`${process.env.VUE_APP_API}/stock/getbyEAN`, {
          Code_EAN: EAN,
          plateform: this.user.proprietaire,
        })
        .then((res) => {
          if (res.data) {
            this.Stock[index].Ref_fournisseur =
              res.data.article.Ref_fournisseur;
            axios.put(`${process.env.VUE_APP_API}/stock/${EAN}`, {
              plateform: this.user.proprietaire,
              Ref_fournisseur: res.data.article.Ref_fournisseur,
            });
          }
        });
    },
    async readExcel() {
      var file = event.target.files ? event.target.files[0] : null;
      console.log(file);
      if (file.size < 1000000) {
        console.log("showfile");
        this.loadingFiles = true;
        this.showFile = true;
        this.readFile = await readXlsxFile(file);
        this.readFile.splice(0, 1);
        this.loadingFiles = false;
      } else {
        this.error("Le fichier est trop volumineux");
      }
      console.log(this.tabHeader);
    },
    async readExcelPrint() {
      var file = event.target.files ? event.target.files[0] : null;
      if (file.size < 1000000) {
        console.log("showfileprint");
        this.loadingFilesPrint = true;
        this.showFilePrint = true;
        this.readFilePrint = await readXlsxFile(file);
        this.readFilePrint.splice(0, 1);
        this.loadingFilesPrint = false;
      } else {
        this.error("Le fichier est trop volumineux");
      }
      console.log(this.tabHeader);
    },
    importFile() {
      if (this.controlHeaderTab(this.tabHeader)) {
        this.loadingFiles = true;
        if (this.readFile.length > 0) {
          axios
            .post(`${process.env.VUE_APP_API}/stock/importStock`, {
              Data: this.readFile,
              plateform: this.user.proprietaire,
              headerTab: this.tabHeader,
            })
            .then((res) => {
              this.loadingFiles = false;
              this.showFile = false;
              this.getStock();
            });
        } else {
          this.error("Le fichier est vide");
          this.loadingFiles = false;
        }
      } else {
        this.error("Les colonnes ne doivent pas être identiques");
      }
    },
    async filePrint() {
      if (this.controlHeaderTab(this.tabHeader)) {
        this.loadingFilesPrint = true;
        console.log("readFilePrint", this.readFilePrint);
        if (this.readFilePrint.length > 0) {
          await axios
            .post(`${process.env.VUE_APP_API}/stock/importPrint`, {
              Data: this.readFilePrint,
              plateform: this.user.proprietaire,
              headerTab: this.tabHeader,
            })
            .then((res) => {
              console.log("res", res);
              this.getLabelExcel(res.data);
              this.loadingFilesPrint = false;
              this.showFilePrint = false;
            });
        } else {
          this.error("Le fichier est vide");
          this.loadingFilesPrint = false;
        }
      } else {
        this.error("Les colonnes ne doivent pas être identiques");
      }
    },
    async readFileFormating() {
      if (this.fournisseurSelected == "") {
        this.fournisseurNotSelected = true;
      } else {
        this.fournisseurNotSelected = false;
        this.loadingCorrection = true;
        axios
          .post(`${process.env.VUE_APP_API}/stock/excelFormatting`, {
            tabHeader: this.tabHeader,
            plateform: this.user.proprietaire,
            data: this.readFile,
            fournisseur: this.fournisseurSelected,
          })
          .then((res) => {
            console.log(res.data);
            this.readFile = res.data;
            this.loadingCorrection = false;
          });
      }
    },
    // article
    async searchArticle() {
      this.loadingArticle = true;
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      const foundArticle = await searchArticles(
        this.inputSearchArticle,
        plateform_mere,
        this.comptoirSessionActive[0].Client[0].Name,
        this.comptoirSessionActive[0].Client[0].Group.Name
      );
      // const foundArticleStock = await getStock(
      //   this.user.proprietaire,
      //   foundArticle[0].article.Ref_fournisseur,
      //   foundArticle[0].article.Code_EAN,
      //   foundArticle[0].article.Code_marque
      // )
      // console.log(foundArticleStock);
      console.log(foundArticle);
      console.log(this.Stock);
      // stock
      if (foundArticle.length > 0) {
        this.loadingArticle = false;
        console.log(this.loadingArticle);
        this.Stock = [];
        for (const tab of foundArticle) {
          this.Stock.push(tab.stockData[0]);
        }
        // foundArticle
        // this.Stock = foundArticleStock;
        // console.log(this.Stock);
        // stock
      } else {
        this.showErrorStock = true;
      }
    },
    // adresse
    async searchAdresse() {
      this.loadingArticle = true;
      const foundAdresse = await searchAdresses(
        this.inputSearchAdresse,
        this.user.proprietaire
      );
      if (foundAdresse.length > 0) {
        this.loadingArticle = false;
        console.log(this.loadingArticle);
        this.Stock = [];
        for (const tab of foundAdresse) {
          this.Stock.push(tab.stockData[0]);
        }
      } else {
        this.showErrorStock = true;
      }
    },
    async readFileFormatingPrint() {
      if (this.fournisseurSelected == "") {
        this.fournisseurNotSelected = true;
      } else {
        this.fournisseurNotSelected = false;
        this.loadingCorrection = true;
        axios
          .post(`${process.env.VUE_APP_API}/stock/excelFormattingPrint`, {
            tabHeader: this.tabHeader,
            plateform: this.user.proprietaire,
            data: this.readFilePrint,
            fournisseur: this.fournisseurSelected,
          })
          .then((res) => {
            console.log(res.data);
            this.readFilePrint = res.data;
            this.loadingCorrection = false;
          });
      }
    },
    controlHeaderTab(arr) {
      for (let i = 0; i < arr.length - 1; i++) {
        for (let j = i + 1; j < arr.length; j++) {
          if (arr[i] === arr[j]) {
            return false;
          }
        }
      }
      return true;
    },
    closeImport() {
      this.showFile = false;
      this.file = null;
      this.readFile = null;
      this.showFilePrint = false;
      this.readFilePrint = null;
    },
    async exportExcel() {
      this.loadingExport = true;
      await this.getAll();
      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(this.allStock);
      XLSX.utils.book_append_sheet(wb, ws, "Stock");

      XLSX.writeFile(wb, "Stock_" + this.user.proprietaire + ".xlsx");
      this.loadingExport = false;

      this.success("Le fichier a été téléchargé");
    },
    getEtiquetaRangement(ean, Ref_fournisseur) {
      etiquetteRangement(ean, Ref_fournisseur);
    },
    getLabelExcel(objet) {
      console.log(objet);
      etiquetteExcelRangement(objet);
    },
    getFournisseur() {
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/getall`, {
          plateform: this.user.proprietaire,
        })
        .then((res) => {
          this.fournisseur = res.data;
        });
    },
    success(message) {
      this.toast.success(message, {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(message) {
      this.toast.warning(message, {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  mounted() {
    this.getPage(1, 10);
    this.getTotal();
    this.pageSwitch();
    this.getFournisseur();
  },
};
</script>
<style scoped>
.bg-vk {
  background-color: #2262b3;
}
</style>
