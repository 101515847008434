<template>
  <div class="flex flex-col space-y-4">
    <div class="flex">
      <button class="flex flex-row space-x-2 items-center">
        <backButton />
      </button>
    </div>
    <div>
      <select v-model="plateformSelected" @change="checkClient()" class="p-2">
        <option v-for="item in plateform" :key="item._id" :value="item">
          {{ item.Name }}
        </option>
      </select>
      <span v-show="errorClient" class="text-red-500"
        >Vous n'êtes pas client de cette plateform!</span
      >
      {{ stateCLient }}
    </div>
    <div v-show="showFournisseur" class="flex flex-col">
      <input type="text" v-model="searchFournisseur" />
      <div class="flex flex-col bg-white max-h-60 overflow-y-auto p-2">
        <div
          v-for="(f, indexF) in filteredFrounisseur"
          :key="indexF"
          class="p-2 hover:bg-blue-200 cursor-pointer"
          @click="selectFournisseur(f)"
        >
          {{ f.CompanyName }}
        </div>
      </div>
    </div>
    <div v-show="showCondition" class="flex flex-col space-y-2">
      <div v-if="fournisseurSelected != null">
        {{ fournisseurSelected.CompanyName }} -
        {{ fournisseurSelected.Prefixe_tarif }} -
        {{ fournisseurSelected.typeCondition }}
      </div>
      <div class="flex flex-row items-center space-x-4">
        <div class="flex flex-col space-y-2">
          <span>Marge sur T1 (en %) :</span
          ><input type="Number" v-model="marge.T1" />
        </div>
        <div class="flex flex-col space-y-2">
          <span>Marge sur T2 (en %) :</span
          ><input type="Number" v-model="marge.T2" />
        </div>
        <div class="flex flex-col space-y-2">
          <span>Marge sur T3 (en %) :</span
          ><input type="Number" v-model="marge.T3" />
        </div>
        <div class="flex flex-col space-y-2">
          <span>Marge sur Part 1 (en %) :</span
          ><input type="Number" v-model="marge.Part1" />
        </div>
        <div class="flex flex-col space-y-2">
          <span>Marge sur Part 2 (en %) :</span
          ><input type="Number" v-model="marge.Part2" />
        </div>
        <div class="flex flex-col space-y-2">
          <span>Marge sur Part 3 (en %) :</span
          ><input type="Number" v-model="marge.Part3" />
        </div>
      </div>
      <div class="flex">
        <button
          class="p-2 bg-blue-500 text-white rounded shadow"
          @click="importConditionF()"
        >
          Valider
        </button>
      </div>
    </div>
    <div v-show="loadingImport">
      <span>Import en cours...</span>
    </div>
  </div>
</template>
<script>
import {
  getPlateform,
  verifyClient,
  getFournisseur,
  importCondition,
} from "@/hooks/fournisseur/importConditionFournisseur";
import { mapGetters } from "vuex";
import backButton from "@/components/Button/back.vue";
import axios from "axios";
export default {
  data() {
    return {
      plateform: [],
      plateformSelected: null,
      errorClient: false,
      showFournisseur: false,
      fournisseurList: [],
      searchFournisseur: "",
      fournisseurSelected: null,
      showCondition: false,
      marge: {
        T1: 0,
        T2: 0,
        T3: 0,
        Part1: 0,
        Part2: 0,
        Part3: 0,
      },
      stateImport: null,
      loadingImport: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    filteredFrounisseur() {
      if (this.searchFournisseur) {
        return this.fournisseurList.filter((item) => {
          return item.CompanyName.toUpperCase().startsWith(
            this.searchFournisseur.toUpperCase()
          );
        });
      } else {
        return this.fournisseurList;
      }
    },
  },
  methods: {
    async checkClient() {
      this.errorClient = !(await verifyClient(
        this.plateformSelected.Name,
        this.user.proprietaire
      ));

      if (!this.errorClient) {
        this.showFournisseur = true;
        this.fournisseurList = await getFournisseur(
          this.plateformSelected.Name
        );
      }
    },
    selectFournisseur(fournisseur) {
      this.fournisseurSelected = fournisseur;
      this.showFournisseur = false;
      this.showCondition = true;
    },
    async importConditionF() {
      let condTab = [];
      const condition = await axios.get(
        `${process.env.VUE_APP_API}/configurationCondition/${this.user.proprietaire}`
      );
      for (const conf of condition.data) {
        condTab.push(0);
      }
      this.showCondition = false;
      this.loadingImport = true;
      this.stateImport = await importCondition(
        this.plateformSelected.Name,
        this.user.proprietaire,
        this.marge,
        this.fournisseurSelected.CompanyName,
        condTab
      );
      this.loadingImport = false;
      this.$router.push("/fournisseurs");
    },
  },
  async mounted() {
    this.plateform = await getPlateform();
  },
  components: {
    backButton,
  },
};
</script>
<style></style>
