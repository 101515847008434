<template>
  <div
    class="flex flex-col space-y-2 flex-justify-between p-2 w-[20rem] m-auto bg-gray-100 shadow rounded-lg"
  >
    <div>
      <input
        placeholder="Nom de l'inventaire"
        v-model="Name"
        type="
      text"
        class="w-full focus:outline-2 h-[2rem] outline-sky-300 p-2 border rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
      />
    </div>
    <div>
      <ul class="grid w-full gap-2 grid-cols-3">
        <li>
          <input
            type="radio"
            id="standard"
            name="type"
            value="standard"
            class="hidden peer"
            v-model="type"
            required
          />
          <label
            for="standard"
            class="inline-flex items-center justify-between w-full p-2 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-sm font-semibold">Standard</div>
            </div>
          </label>
        </li>
        <li>
          <input
            type="radio"
            id="journalier"
            name="type"
            value="journalier"
            class="hidden peer"
            v-model="type"
          />
          <label
            for="journalier"
            class="inline-flex items-center justify-between w-full p-2 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-sm font-semibold">Journalier</div>
            </div>
          </label>
        </li>
        <li>
          <input
            type="radio"
            id="tournant"
            name="type"
            value="tournant"
            class="hidden peer"
            v-model="type"
          />
          <label
            for="tournant"
            class="inline-flex items-center justify-between w-full p-2 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-sm font-semibold">Tournant</div>
            </div>
          </label>
        </li>
      </ul>
    </div>
    <div>
      <ul class="flex flex-col w-full">
        <li>
          <input
            type="radio"
            id="noInit"
            name="initZero"
            value="noInit"
            class="hidden peer"
            v-model="initZero"
            required
          />
          <label
            for="noInit"
            class="inline-flex items-center justify-between w-full p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-sm font-semibold">Ne pas initialiser</div>
              <div class="w-full text-xs">
                Ne modifie pas la quantité des articles non-scannés
              </div>
            </div>
          </label>
        </li>
        <li>
          <input
            type="radio"
            id="init"
            name="initZero"
            value="init"
            class="hidden peer"
            v-model="initZero"
          />
          <label
            for="init"
            class="inline-flex items-center justify-between w-full p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-sm font-semibold">Initialiser</div>
              <div class="w-full text-xs">
                Initialise la valeur des articles non-scannés à 0
              </div>
            </div>
          </label>
        </li>
        <li>
          <input
            type="radio"
            id="theo"
            name="initZero"
            value="theo"
            class="hidden peer"
            v-model="initZero"
          />
          <label
            for="theo"
            class="inline-flex items-center justify-between w-full p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-sm font-semibold">Théorique</div>
              <div class="w-full text-xs">
                Quantité scanné = quantité théorique
              </div>
            </div>
          </label>
        </li>
      </ul>
    </div>
    <span class="animate-pulse" v-show="loadingSearch">
      Recherche en cours ...
    </span>
    <div
      class="w-[18rem] h-[2rem] m-auto flex flex-row space-x-2 justify-center"
    >
      <div>
        <input
          v-model="supplier"
          @keyup:enter="checkSupplier()"
          placeholder="Fournisseur"
          type="text"
          class="focus:outline-2 h-[2rem] outline-sky-300 p-2 border rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
        />
      </div>

      <div>
        <span
          class="material-icons-outlined cursor-pointer text-blue-500"
          @click="checkSupplier()"
        >
          search
        </span>
      </div>
    </div>
    <div class="grid grid-cols-3 gap-1" v-if="supplierSelected">
      <span
        class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 cursor-pointer"
        @click="supplierSelected = ''"
        >{{ supplierSelected }}</span
      >
    </div>
    <div
      class="w-[18rem] h-[2rem] m-auto flex flex-row space-x-2 justify-center"
    >
      <div>
        <input
          v-model="marque"
          placeholder="Marque"
          type="text"
          class="focus:outline-2 h-[2rem] outline-sky-300 p-2 border rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
        />
      </div>

      <div>
        <span
          class="material-icons-outlined cursor-pointer text-blue-500"
          @click="checkBrand()"
        >
          search
        </span>
      </div>
    </div>
    <div class="grid grid-cols-3 gap-1">
      <span
        v-for="(m, indexM) in marqueTab"
        :key="indexM"
        class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
        >{{ m }}</span
      >
    </div>
    <div
      class="w-[18rem] h-[2rem] m-auto flex flex-row space-x-2 justify-center"
    >
      <input
        v-model="adresse"
        placeholder="Adresse"
        type="
      text"
        class="focus:outline-2 h-[2rem] outline-sky-300 p-2 border rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
      />
      <div>ok</div>
    </div>

    <div
      class="w-[10rem] h-[5rem] m-auto rounded-lg flex flex-row justify-center pt-2 space-x-2"
    >
      <div>
        <router-link to="/hubInventaireStock">
          <button class="h-[4rem] w-[6rem] rounded-lg bg-red-500">
            Fermer
          </button>
        </router-link>
      </div>
      <button
        class="h-[4rem] w-[6rem] rounded-lg bg-green-500"
        v-if="marqueTab.length > 0 && supplierSelected && Name"
        @click="startInventaire()"
      >
        Valider
      </button>
    </div>
    <div class="m-auto">
      <p v-show="isInputEmpty" style="color: red">
        Le champ "Marque" ne peut pas être vide !
      </p>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { createInventory } from "@/hooks/stock/inventaire.ts";
export default {
  el: "#app",
  data() {
    return {
      adresse: "",
      marque: "",
      marqueTab: [],
      supplier: "",
      supplierSelected: "",
      Name: "",
      type: "standard",
      initZero: "init",
      isInputEmpty: false,
      showFourni: false,
      fournisseurList: [],
      brandState: false,
      loadingSearch: false,
      loadingFamille: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["setinventoryprocessing"]),
    async startInventaire() {
      if (this.marqueTab.length > 0 && this.supplierSelected && this.Name) {
        const initInventory = await createInventory(
          this.Name,
          this.type,
          this.initZero,
          this.adresse,
          this.supplierSelected,
          this.marqueTab,
          "0",
          "Aucune",
          this.user.proprietaire,
          this.user._id
        );
        this.setinventoryprocessing(initInventory.data);
        this.$router.push("/inventaireStock");
      } else {
        this.isInputEmpty = true;
      }
    },
    async checkBrand() {
      this.loadingSearch = true;
      this.marque = this.marque.toUpperCase();
      const chkBrand = await axios.post(
        `${process.env.VUE_APP_API}/inventaire/checkBrand`,
        {
          plateform: this.user.proprietaire,
          brand: this.marque,
        }
      );
      this.loadingSearch = false;
      if (chkBrand.data) {
        this.brandState = true;
        this.marqueTab.push(this.marque);
      } else {
        this.brandState = false;
      }
    },
    async checkSupplier() {
      if (this.supplier) {
        //axios call
        const chkSupplier = await axios.post(
          `${process.env.VUE_APP_API}/fournisseur/searchInPlateform`,
          {
            plateform: this.user.proprietaire,
            searchTerm: this.supplier,
            skip: 0,
            limit: 50,
          }
        );
        if (chkSupplier.data.results.length === 1) {
          this.supplierSelected = chkSupplier.data.results[0].Prefixe_tarif;
        }
      }
    },
  },
};
</script>
