<template>
  <div class="flex flex-col space-y-8">
    <backButton />
    <div class="flex justify-center"><menuConfiguration /></div>
    <div v-show="errorMaxCondition" class="flex justify-center text-red-500">
      Vous avez atteint le nombre maximum de conditions!
    </div>
    <div class="grid grid-cols-6 gap-3">
      <div
        class="flex flex-col space-y-2 bg-white rounded shadow p-2"
        v-for="(cond, indexCond) in conditionList"
        :key="indexCond"
      >
        <div class="relative">
          <input
            type="text"
            :id="cond._id + Name"
            v-model="cond.Name"
            @change="updateCondition(cond._id, cond)"
            class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-blue-400 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          />
          <label
            :for="cond._id + Name"
            class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-beigevk px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >{{ cond.Position + 1 }}</label
          >
        </div>
        <div class="relative">
          <input
            type="text"
            :id="cond._id + Description"
            v-model="cond.Description"
            @change="updateCondition(cond._id, cond)"
            class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-blue-400 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          />
          <label
            :for="cond._id + Description"
            class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-beigevk px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >Description</label
          >
        </div>
        <div class="flex justify-end">
          <button
            class="text-white bg-red-500 rounded shadow px-1"
            @click="deleteCondition(cond._id, cond.Position)"
          >
            supprimer
          </button>
        </div>
      </div>
      <div class="flex flex-col space-y-2 bg-white rounded shadow p-2">
        <div class="relative">
          <input
            type="text"
            id="floating_nom"
            v-model="addName"
            class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-blue-400 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          />
          <label
            for="floating_nom"
            class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-beigevk px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >{{ conditionList.length + 1 }}</label
          >
        </div>
        <div class="relative">
          <input
            type="text"
            id="floating_desc"
            v-model="addDescription"
            class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-blue-400 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          />
          <label
            for="floating_desc"
            class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-beigevk px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >Description</label
          >
        </div>
        <div class="flex justify-end">
          <button
            class="text-white bg-bluevk rounded shadow px-1"
            @click="addCondition(conditionList.length)"
          >
            ajouter
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import backButton from "@/components/Button/back.vue";
import menuConfiguration from "@/components/Menu/menuConfiguration.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      addName: "",
      addDescription: "",
      conditionList: [],
      errorMaxCondition: false,
    };
  },
  components: {
    backButton,
    menuConfiguration,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async addCondition(position) {
      if (this.conditionList.length <= 10) {
        const addCond = await axios.post(
          `${process.env.VUE_APP_API}/configurationCondition`,
          {
            plateform: this.user.proprietaire,
            Name: this.addName,
            Description: this.addDescription,
            Position: position,
          }
        );
        this.getCondtion();
        this.addName = "";
        this.addDescription = "";
        this.errorMaxCondition = false;
      } else {
        this.errorMaxCondition = true;
      }
    },
    async getCondtion() {
      const condition = await axios.get(
        `${process.env.VUE_APP_API}/configurationCondition/${this.user.proprietaire}`
      );
      this.conditionList = condition.data;
    },
    async deleteCondition(id, Position) {
      const deleteCond = await axios.post(
        `${process.env.VUE_APP_API}/configurationCondition/deleteCondition/${id}`,
        { Position: Position, plateform: this.user.proprietaire }
      );
      this.getCondtion();
    },
    async updateCondition(id, condition) {
      const updateCond = await axios.post(
        `${process.env.VUE_APP_API}/configurationCondition/updateCondition/${id}`,
        {
          Name: condition.Name,
          Description: condition.Description,
        }
      );
      this.getCondtion();
    },
  },
  mounted() {
    this.getCondtion();
  },
};
</script>
<style></style>
