<template>
  <div v-if="tabTransporteur[0]">
    <div class="flex justify-between">
      <button
        class="bg-bluevk hover:bg-bluevkdark text-white font-bold px-4 rounded"
        title="Cliquer pour revenir en arrière"
        @click="$router.back()"
      >
        <p class="my-auto">&lt; Retour</p>
      </button>

      <div class="text-2xl font-medium ml-2">
        <h2 class="space-x-2">
          <span>Tarif</span>
          <span class="text-green-600">
            {{ tabTransporteur[0].transporteur }}
          </span>
        </h2>
      </div>

      <div class="flex justify-end mt-2 space-x-2">
        <label
          id="dropzone-file"
          @click="exportTemplate()"
          title="Télécharger un template Excel"
          class="cursor-pointer bg-orange-600 hover:bg-orange-500 text-white shadow-md px-2 py-1 flex justify-between rounded-md"
        >
          <span class="m-auto">Template</span>
          <span class="material-icons-outlined m-auto">download</span>
        </label>
        <label
          id="dropzone-file"
          title="Importer Excel"
          class="cursor-pointer bg-emerald-700 hover:bg-emerald-600 text-white shadow-md px-2 py-1 flex justify-between rounded-md"
        >
          <span class="m-auto">Importer Excel</span>
          <span class="material-icons-outlined m-auto">file_upload</span>
          <input
            id="dropzone-file"
            type="file"
            class="hidden"
            accept=".xlsx"
            @change="readExcel()"
          />
        </label>
        <label
          id="dropzone-file"
          @click="exportToExcel()"
          title="Exporter Excel"
          class="cursor-pointer bg-sky-700 hover:bg-sky-600 text-white shadow-md px-2 py-1 flex justify-between rounded-md"
        >
          <span class="m-auto">Exporter Excel</span>
          <span class="material-icons-outlined m-auto">file_download</span>
        </label>
      </div>
    </div>

    <div>
      <div class="flex flex-col mt-2">
        <div
          class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        >
          <div
            class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg h-full"
          >
            <table class="min-w-full" style="height: 70vh" ref="table">
              <thead class="tableHEADTR">
                <tr>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Référence
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Département
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Service
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Nbr Colis ou Palette
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Poids / Tarif
                  </th>
                  <th
                    class="w-12 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    <button
                      class="inline-flex px-2 h-fit text-xs font-semibold leading-5 text-red-500 rounded-full"
                      @click="showInsertTarif = !showInsertTarif"
                      title="Insérer un tarif"
                    >
                      <span
                        class="material-icons-round bg-blue-500 hover:bg-opacity-25 rounded-sm text-white cursor-pointer"
                      >
                        add
                      </span>
                    </button>
                  </th>
                </tr>
              </thead>

              <tbody class="bg-white tableBODY" style="height: 70vh">
                <tr
                  v-if="showInsertTarif"
                  class="tableHEADTR text-blue-700 font-medium"
                >
                  <td
                    class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <input
                      type="text"
                      class="focus:outline-2 outline-sky-300 p-1 border border-gray-500 rounded-md font-medium"
                      :value="
                        departement + '/' + service + '/' + colis + '/' + poids
                      "
                      disabled
                    />
                  </td>

                  <td
                    class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <input
                      type="text"
                      class="focus:outline-2 outline-sky-300 p-1 w-2/5 border border-gray-500 rounded-md font-medium"
                      v-model="departement"
                      ref="departement"
                      @keydown.enter="this.$refs.service.focus()"
                    />
                  </td>

                  <td
                    class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="relative mt-2 rounded-md shadow-sm">
                      <select
                        v-model="service"
                        class="focus:outline-2 outline-sky-300 p-1 border border-gray-500 rounded-md font-medium"
                        ref="service"
                        @keydown.enter="this.$refs.colis.focus()"
                      >
                        <option
                          v-for="(select, indexSelect) in services"
                          :key="indexSelect"
                          :value="select.value"
                        >
                          {{ select.name }}
                        </option>
                      </select>
                    </div>
                  </td>

                  <td
                    class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <input
                      type="number"
                      class="focus:outline-2 outline-sky-300 p-1 w-2/5 border border-gray-500 rounded-md font-medium"
                      v-model="colis"
                      ref="colis"
                      @keydown.enter="this.$refs.poids.focus()"
                    />
                  </td>

                  <td
                    class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <input
                      type="number"
                      class="focus:outline-2 outline-sky-300 p-1 w-2/6 border border-gray-500 rounded-md font-medium"
                      v-model="poids"
                      ref="poids"
                      @keydown.enter="this.$refs.tarif.focus()"
                    />
                    <input
                      type="text"
                      class="w-12 focus:outline-2 outline-sky-300 p-1 border border-gray-500 rounded-md font-medium"
                      v-model="unitePoids"
                      disabled
                      ref="unitePoids"
                      @keydown.enter="this.$refs.tarif.focus()"
                    />
                    <input
                      type="number"
                      class="focus:outline-2 outline-sky-300 p-1 w-2/6 border border-gray-500 rounded-md font-medium"
                      v-model="tarif"
                      ref="tarif"
                      @keydown.enter="insertTarif()"
                    />
                    €
                  </td>

                  <td
                    class="w-12 px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div
                      class="cursor-pointer rounded-md bg-green-600 shadow-sm text-white hover:bg-green-300 p-0.5 w-7 h-7"
                      @click="insertTarif()"
                    >
                      <span class="material-icons-outlined">done</span>
                    </div>
                    <!-- <div class="flex flex-row items-center space-x-1">
                      <button
                        class="inline-flex px-2 h-fit text-xs font-semibold leading-5 text-red-500 rounded-full"
                        @click="showInsertTarif = false"
                      >
                        <span
                          class="material-icons-round bg-red-500 hover:bg-opacity-25 rounded-sm text-white cursor-pointer"
                          style="font-size: small"
                        >
                          clear
                        </span>
                      </button>
                    </div> -->
                  </td>
                </tr>

                <tr
                  v-for="(line, _id) in tarifs"
                  :key="_id"
                  class="tableHEADTR"
                >
                  <td
                    class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <input
                      type="text"
                      class="focus:outline-2 outline-sky-300 p-1 border border-gray-500 rounded-md font-medium"
                      v-model="line.reference"
                      @change="updateTarif(line)"
                    />
                  </td>
                  <td
                    class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <input
                      type="text"
                      class="focus:outline-2 outline-sky-300 p-1 w-2/6 border border-gray-500 rounded-md font-medium"
                      v-model="line.departement"
                      @change="updateTarif(line)"
                    />
                  </td>

                  <td
                    class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="relative mt-2 rounded-md shadow-sm">
                      <select
                        v-model="line.service"
                        class="focus:outline-2 outline-sky-300 p-1 border border-gray-500 rounded-md font-medium"
                        @change="updateTarif(line)"
                      >
                        <option
                          v-for="(select, indexSelect) in services"
                          :key="indexSelect"
                          :value="select.value"
                        >
                          {{ select.name }}
                        </option>
                      </select>
                    </div>
                  </td>

                  <td
                    class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <input
                      type="number"
                      class="focus:outline-2 outline-sky-300 p-1 w-2/6 border border-gray-500 rounded-md font-medium"
                      v-model="line.colis"
                      @change="updateTarif(line)"
                    />
                  </td>

                  <td
                    class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <input
                      type="number"
                      class="focus:outline-2 outline-sky-300 p-1 w-2/6 border border-gray-500 rounded-md font-medium"
                      v-model="line.poids"
                      @change="updateTarif(line)"
                    />
                    <input
                      type="text"
                      class="focus:outline-2 outline-sky-300 p-1 w-12 border border-gray-500 rounded-md font-medium"
                      v-model="line.unitePoids"
                      disabled
                      @change="updateTarif(line)"
                    />
                    /
                    <input
                      type="number"
                      class="focus:outline-2 outline-sky-300 p-1 w-2/6 border border-gray-500 rounded-md font-medium"
                      v-model="line.tarif"
                      @change="updateTarif(line)"
                    />
                    €
                  </td>

                  <td
                    class="w-12 px-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex flex-row items-center space-x-1">
                      <button
                        class="inline-flex px-2 h-fit text-xs font-semibold leading-5 text-red-500 rounded-full"
                        @click="removeTarif(line)"
                      >
                        <span
                          class="material-icons-round bg-red-500 hover:bg-opacity-25 rounded-sm text-white cursor-pointer"
                          style="font-size: small"
                        >
                          clear
                        </span>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <div class="flex justify-end mt-2">
            <button
              class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
              @click="insertTarif()"
            >
              Enregistrer
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import readXlsxFile from "read-excel-file";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import {
  getTransporteurById,
  createTarifTransporteur,
  getAllTarifs,
  updateTarifTransporteur,
  removeTarifTransporteur,
} from "@/hooks/transport/transporteur";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      tabTransporteur: [],
      tarifs: [],
      showInsertTarif: false,

      loadingFiles: false,
      readFile: null,
      showFile: false,

      departement: "",
      service: "",
      services: [
        {
          value: "J",
          name: "Livraison de jour (J)",
        },
        {
          value: "N",
          name: "Livraison de nuit (J+1 ou N)",
        },
        {
          value: "P",
          name: "Palette (P)",
        },
        {
          value: "PE",
          name: "Palette (PE)",
        },
        {
          value: "M",
          name: "Messagerie (M)",
        },
      ],
      colis: "",
      poids: "",
      unitePoids: "G",
      tarif: "",
    };
  },
  computed: {
    ...mapGetters(["user", "transporteurId"]),
  },
  methods: {
    async getTransporteur() {
      this.tabTransporteur = await getTransporteurById(
        this.user.proprietaire,
        this.transporteurId
      );
      console.log(this.tabTransporteur);
    },
    async getTarifs() {
      this.tarifs = await getAllTarifs(
        this.user.proprietaire,
        this.transporteurId
      );
      console.log(this.tarifs);
    },
    async insertTarif() {
      await createTarifTransporteur(
        this.user.proprietaire,
        this.transporteurId,
        {
          plateform: this.user.proprietaire,
          transporteur: this.tabTransporteur[0].transporteur,
          transporteurId: this.transporteurId,
          reference:
            this.departement +
            "/" +
            this.service +
            "/" +
            this.colis +
            "/" +
            this.poids,
          departement: this.departement,
          service: this.service,
          colis: this.colis,
          poids: this.poids,
          unitePoids: this.unitePoids,
          tarif: this.tarif,
        }
      );
      this.initializeVariables();
      this.$refs.departement.focus();
      this.toast.success("Enregistré !", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      this.getTarifs();
    },
    async removeTarif(data) {
      await removeTarifTransporteur(
        this.user.proprietaire,
        this.transporteurId,
        data._id
      );
      this.toast.success("Supprimé !", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      this.getTarifs();
    },
    async updateTarif(data) {
      data.reference =
        data.departement +
        "/" +
        data.service +
        "/" +
        data.colis +
        "/" +
        data.poids;
      await updateTarifTransporteur(
        this.user.proprietaire,
        this.transporteurId,
        data._id,
        data
      );
      this.toast.success("Modifié !", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      this.getTarifs();
    },
    initializeVariables() {
      this.departement = "";
      this.service = "";
      this.colis = "";
      this.poids = "";
      this.unitePoids = "G";
      this.tarif = "";
    },

    // EXCEL
    exportTemplate() {
      const dataToExport = [
        {
          Reference: "",
          Departement: "",
          Service: "",
          Colis: "",
          Poids: "",
          Unite: "",
          Tarif: "",
        },
      ];

      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(dataToExport);
      XLSX.utils.book_append_sheet(wb, ws, "grilleTransporteur");

      XLSX.writeFile(
        wb,
        "Tarif" + this.tabTransporteur[0].transporteur + "Template" + ".xlsx"
      );
    },
    exportToExcel() {
      const dataToExport = this.tarifs.map((row) => {
        const { [Object.keys(row).pop()]: last, ...rest } = row;
        return rest;
      });

      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(dataToExport);
      XLSX.utils.book_append_sheet(wb, ws, "grilleTransporteur");

      XLSX.writeFile(
        wb,
        "Tarif" + this.tabTransporteur[0].transporteur + ".xlsx"
      );
    },
    async readExcel() {
      var file = event.target.files ? event.target.files[0] : null;
      if (file.size < 1000000) {
        this.loadingFiles = true;
        this.showFile = true;
        this.readFile = await readXlsxFile(file);
        this.initHeader(this.readFile[0]);
        this.readFile.splice(0, 1);
        this.loadingFiles = false;
      } else {
        this.error("Le fichier est trop volumineux");
      }
    },
  },
  mounted() {
    this.getTransporteur();
    this.getTarifs();
  },
};
</script>
