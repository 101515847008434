<template>
  <div
    v-if="openEnvoi"
    :class="`modal ${
      !openEnvoi && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openEnvoi = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-height: 48rem; max-width: fit-content"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">
            Voulez-vous envoyer le récapitulatif des ventes au fournisseur ?
          </p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openEnvoi = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <div></div>

        <!--Footer-->
        <div class="flex justify-center pt-2">
          <button
            @click="openEnvoi = false"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
          <button
            v-if="tabTxt.length == 0"
            @click="createBdlVide()"
            :disabled="loadingEnvoi"
            class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
          >
            Envoyer vide
          </button>
          <button
            v-else
            @click="createBdl()"
            :disabled="loadingEnvoi"
            class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
          >
            Envoyer
          </button>
        </div>
      </div>
    </div>
  </div>

  <div id="body" class="flex flex-col">
    <router-link to="/detailFournisseur" class="w-fit">
      <div
        id="retour"
        class="flex justify-start bg-slate-100 rounded-lg px-1 w-fit hover:bg-white border border-blue-400"
      >
        <img src="../assets/RetourIcon.png" class="w-6" />
        <span style="color: #5976e8">Retour</span>
      </div>
    </router-link>

    <h5 class="text-gray-700" style="font-size: 30px">
      Bons de livraison du jour
    </h5>

    <hr
      style="
        height: 1px;
        color: #a9a9a9;
        background-color: #a9a9a9;
        border: none;
      "
    />

    <div class="mt-4 flex justify-end space-x-2">
      <button
        class="w-24 bg-cyan-600 p-1 text-white text-sm rounded shadow"
        v-if="tabState == 'actif'"
        @click="getBLdsv()"
      >
        Actif
      </button>
      <button
        class="w-24 bg-blue-300 border border-cyan-600 p-1 text-white text-sm rounded shadow"
        v-else
        @click="getBLdsv()"
      >
        Actif
      </button>
      <button
        class="w-24 bg-cyan-600 p-1 text-white text-sm rounded shadow"
        v-if="tabState == 'archived'"
        @click="getBLdsvArchived()"
      >
        Archivé
      </button>
      <button
        class="w-24 bg-blue-300 border border-cyan-600 p-1 text-white text-sm rounded shadow"
        v-else
        @click="getBLdsvArchived()"
      >
        Archivé
      </button>
    </div>
    <div
      class="mt-6 py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
    >
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full border shadow">
          <thead class="bg-gray-100 text-gray-900">
            <tr>
              <th
                class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
              >
                <span>Date</span>
              </th>
              <th
                class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
              >
                <span>Client</span>
              </th>
              <th
                class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
              >
                <span>Numéro BL</span>
              </th>
              <th
                class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
              >
                <span>Montant total</span>
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <tr
              v-for="(bdl, index) in tabBdl"
              :key="index"
              class="tab-list cursor-pointer odd:bg-white even:bg-slate-50"
            >
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ getFRDate(bdl.Date) }}
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ bdl.Client }}
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ bdl.Numero }}
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ bdl.TotalPrice }} €
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
      v-if="tabState == 'actif'"
    >
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full border shadow">
          <thead class="bg-gray-100 text-gray-900">
            <tr>
              <th
                class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
              >
                <span>Infos txt</span>
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <tr
              v-for="(bdl, index) in setTabTxt"
              :key="index"
              class="tab-list cursor-pointer odd:bg-white even:bg-slate-50"
            >
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ bdl }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="flex justify-center space-x-12" v-if="tabState == 'actif'">
      <button
        class="mt-12 px-6 py-3 font-medium tracking-wide text-cyan-600 bg-transparent rounded-md hover:bg-white focus:outline-none"
        @click="archiveSelection(tabBdl, 'tabBdl')"
      >
        Archiver
      </button>
      <button
        class="mt-12 px-6 py-3 font-medium tracking-wide text-white bg-cyan-600 rounded-md hover:bg-cyan-500 focus:outline-none"
        @click="openEnvoi = true"
      >
        Envoyer
      </button>
    </div>

    <div
      class="mt-6 py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
    >
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full border shadow">
          <thead class="bg-gray-100 text-gray-900">
            <tr>
              <th
                class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
              >
                <span>Date</span>
              </th>
              <th
                class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
              >
                <span>Client</span>
              </th>
              <th
                class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
              >
                <span>Numéro BL</span>
              </th>
              <th
                class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
              >
                <span>Montant total</span>
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <tr
              v-for="(bdl, index) in tabOrderByDsv"
              :key="index"
              class="tab-list cursor-pointer odd:bg-white even:bg-slate-50"
            >
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ getFRDate(bdl.Date) }}
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ bdl.Client }}
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ bdl.Numero }}
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ bdl.TotalPrice }} €
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
      v-if="tabState == 'actif'"
    >
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full border shadow">
          <thead class="bg-gray-100 text-gray-900">
            <tr>
              <th
                class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
              >
                <span>Infos txt</span>
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <tr
              v-for="(bdl, index) in setTabTxtOrderByDsv"
              :key="index"
              class="tab-list cursor-pointer odd:bg-white even:bg-slate-50"
            >
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ bdl }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="flex justify-center space-x-12" v-if="tabState == 'actif'">
    <button
      class="mt-12 px-6 py-3 font-medium tracking-wide text-cyan-600 bg-transparent rounded-md hover:bg-white focus:outline-none"
      @click="archiveSelection(tabOrderByDsv, 'tabOrderByDsv')"
    >
      Archiver
    </button>
    <button
      class="mt-12 px-6 py-3 font-medium tracking-wide text-white bg-cyan-600 rounded-md hover:bg-cyan-500 focus:outline-none"
      @click="openEnvoi = true"
    >
      Envoyer
    </button>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { createlivraisonDsv, createVideDsv } from "@/hooks/pdf/envoiDsv";
import { useToast } from "vue-toastification";
import { getFrDate } from "@/hooks/tools/date.ts";
import { getFournisseurByID } from "@/hooks/fournisseur/fournisseur.ts";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      openEnvoi: false,
      tabBdl: [],
      tabTxt: [],
      formatBL: [],
      tabState: "",
      tabOrderByDsv: [],
      tabTxtOrderByDsv: [],
      loadingEnvoi: false,
      fournisseurTab: {},
    };
  },
  computed: {
    ...mapGetters(["user", "fournisseurID"]),
    setTodayDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      today = dd + mm + +yyyy;
      return today;
    },
    formatDateBL() {
      this.tabBdl.forEach((element) => {
        let dt = getFrDate(element.Date);
        this.formatBL.push(dt.substr(0, 10).replace(/([^\w ]|_)/g, ""));
      });
      return this.formatBL;
    },
    setTabTxt() {
      this.tabBdl.forEach((el, index) => {
        el.Articles.forEach((element) => {
          if (!element.Ref_fournisseur.startsWith("C : ")) {
            let m = " "; /* espace ajouté à chaque fois */
            let n = 0; /* 0 ajouté à chaque fois */
            // consignataire
            let consignataire = [el.textFile.consignataire];
            while (consignataire.length <= 9) {
              consignataire = consignataire + m;
            }

            // numéro BL
            let numBL = el.Numero.slice(0, -1);
            while (numBL.length <= 5) {
              numBL = numBL + m;
            }

            // type BL
            let typeBL = [el.textFile.typeBL];
            // while (typeBL.length <= 5) {
            //   typeBL = typeBL + m;
            // }

            // date BL
            let dateBL = this.formatDateBL[index];
            while (dateBL.length <= 7) {
              dateBL = dateBL + m;
            }

            // code client
            let codeClient = [el.textFile.codeClient];
            while (codeClient.length <= 7) {
              codeClient = codeClient + m;
            }

            // ref article
            let refArticle = element.Ref_fournisseur;
            while (refArticle.length <= 12) {
              refArticle = refArticle + m;
            }

            // designation
            let designation = element.Description;
            if (designation.length <= 17) {
              while (designation.length <= 17) {
                designation = designation + m;
              }
            } else {
              designation = element.Description.substr(0, 18);
            }

            // qte commandee
            let qty = element.quantity.toString();
            if (qty.indexOf("-") !== -1) {
              qty = qty.replace(/-/g, "");
            }
            let qteCommandee = qty;
            while (qteCommandee.length <= 5) {
              qteCommandee = n + qteCommandee;
            }

            // qte livree
            let qteLivree = qty;
            while (qteLivree.length <= 5) {
              qteLivree = n + qteLivree;
            }

            // libelle port
            let libellePort = "";
            while (libellePort.length <= 19) {
              libellePort = libellePort + m;
            }

            // montant port
            let montantPort = "";
            while (montantPort.length <= 7) {
              montantPort = montantPort + m;
            }

            // num BC
            let numBC;
            if (el.textFile.numeroCmd != "") {
              numBC = el.textFile.numeroCmd.toString().substr(0, 10);
              while (numBC.length <= 17) {
                numBC = numBC + m;
              }
            } else {
              numBC = "".toString();
              while (numBC.length <= 17) {
                numBC = numBC + m;
              }
            }

            // commentaire
            let commentaire = " ";
            commentaire = el.textFile.numeroCmd.toString().substr(11);
            while (commentaire.length <= 31) {
              commentaire = commentaire + m;
            }

            this.tabTxt.push(
              consignataire +
                numBL +
                typeBL +
                dateBL +
                codeClient +
                refArticle +
                designation +
                qteCommandee +
                qteLivree +
                libellePort +
                montantPort +
                numBC +
                commentaire +
                "0"
            );
          }
        });
      });
      return this.tabTxt;
    },
    formatDateBLOrderByDsv() {
      var formatBL = [];

      this.tabOrderByDsv.forEach((element) => {
        let dt = getFrDate(element.Date);
        formatBL.push(dt.substr(0, 10).replace(/([^\w ]|_)/g, ""));
      });
      return formatBL;
    },
    setTabTxtOrderByDsv() {
      this.tabOrderByDsv.forEach((el, index) => {
        el.Articles.forEach((element) => {
          if (!element.Ref_fournisseur.startsWith("C : ")) {
            let m = " "; /* espace ajouté à chaque fois */
            let n = 0; /* 0 ajouté à chaque fois */
            // consignataire
            let consignataire = [el.textFile.consignataire];
            while (consignataire.length <= 9) {
              consignataire = consignataire + m;
            }

            // numéro BL
            let numBL = el.Numero.slice(0, -1);
            while (numBL.length <= 5) {
              numBL = numBL + m;
            }

            // type BL
            let typeBL = [el.textFile.typeBL];
            // while (typeBL.length <= 5) {
            //   typeBL = typeBL + m;
            // }

            // date BL
            let dateBL = this.formatDateBLOrderByDsv[index];
            while (dateBL.length <= 7) {
              dateBL = dateBL + m;
            }

            // code client
            let codeClient = [el.textFile.codeClient];
            while (codeClient.length <= 7) {
              codeClient = codeClient + m;
            }

            // ref article
            let refArticle = element.Ref_fournisseur;
            while (refArticle.length <= 12) {
              refArticle = refArticle + m;
            }

            // designation
            let designation = element.Description;
            if (designation.length <= 17) {
              while (designation.length <= 17) {
                designation = designation + m;
              }
            } else {
              designation = element.Description.substr(0, 18);
            }

            // qte commandee
            let qty = element.quantity.toString();
            if (qty.indexOf("-") !== -1) {
              qty = qty.replace(/-/g, "");
            }
            let qteCommandee = qty;
            while (qteCommandee.length <= 5) {
              qteCommandee = n + qteCommandee;
            }

            // qte livree
            let qteLivree = qty;
            while (qteLivree.length <= 5) {
              qteLivree = n + qteLivree;
            }

            // libelle port
            let libellePort = "";
            while (libellePort.length <= 19) {
              libellePort = libellePort + m;
            }

            // montant port
            let montantPort = "";
            while (montantPort.length <= 7) {
              montantPort = montantPort + m;
            }

            // num BC
            let numBC;
            if (el.textFile.numeroCmd != "") {
              numBC = el.textFile.numeroCmd.toString().substr(0, 10);
              while (numBC.length <= 17) {
                numBC = numBC + m;
              }
            } else {
              numBC = "".toString();
              while (numBC.length <= 17) {
                numBC = numBC + m;
              }
            }

            // commentaire
            let commentaire = " ";
            commentaire = el.textFile.numeroCmd.toString().substr(11);
            while (commentaire.length <= 31) {
              commentaire = commentaire + m;
            }

            this.tabTxtOrderByDsv.push(
              consignataire +
                numBL +
                typeBL +
                dateBL +
                codeClient +
                refArticle +
                designation +
                qteCommandee +
                qteLivree +
                libellePort +
                montantPort +
                numBC +
                commentaire +
                "0"
            );
          }
        });
      });
      return this.tabTxtOrderByDsv;
    },
  },
  methods: {
    async getFournisseur() {
      this.fournisseurTab = await getFournisseurByID(
        this.fournisseurID,
        this.user.proprietaire
      );
    },
    getFRDate(date) {
      return getFrDate(date);
    },
    getBLdsv() {
      this.tabState = "actif";
      this.tabBdl = [];
      this.tabTxt = [];
      axios
        .post(
          `${process.env.VUE_APP_API}/bdl/getbyfournisseur/${this.fournisseurTab.CompanyName}`,
          {
            plateform: this.user.proprietaire,
          }
        )
        .then((response) => {
          // this.tabBdl = response.data;

          response.data.forEach((element) => {
            // if (
            //   element.Date.substr(0, 10).replace(/([^\w ]|_)/g, "") ==
            //   this.setTodayDate
            // ) {
            this.tabBdl.push(element);
            // }
          });
        });
    },
    getBLdsvArchived() {
      this.tabState = "archived";
      this.tabBdl = [];
      this.tabTxt = [];
      axios
        .post(
          `${process.env.VUE_APP_API}/bdl/getbyfournisseurarchived/${this.fournisseurTab.CompanyName}`,
          {
            plateform: this.user.proprietaire,
          }
        )
        .then((response) => {
          // this.tabBdl = response.data;
          response.data.forEach((element) => {
            // if (
            //   element.Date.substr(0, 10).replace(/([^\w ]|_)/g, "") ==
            //   this.setTodayDate
            // ) {
            this.tabBdl.push(element);
            // }
          });
        });
    },
    archiveSelection(tab, name) {
      axios
        .post(`${process.env.VUE_APP_API}/bdl/archiveManyBDL`, {
          data: tab,
        })
        .then(() => {
          if (name == "tabBdl") {
            this.getBLdsv();
            this.tabBdl = [];
          } else if (name == "tabOrderByDsv") {
            this.getBdlNameDsv();
            this.tabOrderByDsv = [];
          }
          this.toast.success("Archivé !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    async createBdlVide() {
      this.loadingEnvoi = true;
      await createVideDsv(this.user, this.tabTxt);
      this.loadingEnvoi = false;
      this.toast.success("Envoyé !", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      // this.archiveSelection();
      this.openEnvoi = false;
    },
    async createBdl() {
      this.loadingEnvoi = true;
      if (this.tabOrderByDsv.length > 0) {
        await createlivraisonDsv(
          this.tabOrderByDsv,
          this.tabTxtOrderByDsv,
          true,
          this.user
        );
        this.toast.success("Envoyé !", {
          position: "bottom-right",
          timeout: 1000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.archiveSelection(this.tabOrderByDsv, "tabOrderByDsv");
      }
      if (this.tabBdl.length > 0) {
        await createlivraisonDsv(this.tabBdl, this.tabTxt, false, this.user);
        this.toast.success("Envoyé !", {
          position: "bottom-right",
          timeout: 1000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.archiveSelection(this.tabBdl, "tabBdl");
      }
      this.loadingEnvoi = false;
      this.openEnvoi = false;
    },
    getBdlNameDsv() {
      this.tabState = "actif";
      this.tabOrderByDsv = [];
      this.tabTxtOrderByDsv = [];
      axios
        .post(
          `${process.env.VUE_APP_API}/bdl/getbdlbydsv/${this.fournisseurTab.CompanyName}`,
          {
            plateform: this.user.proprietaire,
          }
        )
        .then((response) => {
          this.tabOrderByDsv = response.data;
        });
    },
  },
  async mounted() {
    await this.getFournisseur();
    this.getBLdsv();
    this.getBdlNameDsv();
  },
};
</script>

<style></style>
