<template>
  <div
    v-if="openModalPDF"
    class="fixed inset-0 z-50 flex items-center justify-center"
  >
    <!-- Overlay -->
    <div
      class="absolute inset-0 bg-gray-700 opacity-75"
      @click="openModalPDF = false"
    ></div>
    <div
      @click="openModalPDF = false"
      class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
    >
      <svg
        class="text-white fill-current"
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 18 18"
      >
        <path
          d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
        />
      </svg>
    </div>

    <!-- Modal Content -->
    <div
      class="relative mx-64 rounded-lg shadow-lg w-full h-full overflow-hidden flex flex-col"
    >
      <!-- Body -->
      <div class="flex-1 overflow-auto">
        <iframe
          :src="factureLink"
          class="w-full h-full"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  </div>

  <PrintDialog
    v-if="printDialogVisible"
    :visible="printDialogVisible"
    :facturationSelected="facturationSelected"
    @cancel="handleCancelPrint"
  />
  <div class="bg-white w-full lg:p-4 xl:p-4 md:p-4 sm:p-4 rounded-sm shadow">
    <div class="flex justify-between">
      <backButton />
      <div class="relative flex justify-center">
        <div
          v-show="showDmdEnvoi"
          @click="showDmdEnvoi = false"
          class="absolute w-screen h-screen z-10 top-0 left-0"
        ></div>
        <!-- <button
          v-if="!loadingEnvoi"
          class="bg-bluevk text-white hover:bg-blue-900 border border-bluevk shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
          @click="showDmdEnvoi = true"
        >
          <span class="material-icons-outlined text-xl mr-2"> mail </span>
          Envoyer une par une
        </button> -->
        <!-- <button
          v-else
          class="bg-bluevk text-white hover:bg-blue-900 border border-bluevk shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
        >
          <PulseLoader color="#ffff" class="mr-2" />
          <span>Envoi en cours </span>
        </button> -->
        <div
          v-if="showDmdEnvoi"
          class="absolute mt-8 -ml-8 bg-gray-200 p-2 rounded shadow-lg z-10 overflow-hidden"
        >
          <div class="flex flex-col justify-center space-x-4">
            <span
              >Êtes-vous sûr de vouloir envoyer toutes les factures aux clients
              ?</span
            >
            <div class="flex space-x-4 justify-center">
              <button
                class="bg-bluevk text-white hover:bg-blue-900 border border-bluevk shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
                @click="sendPDF(tabPDF)"
              >
                Oui
              </button>
              <button
                class="text-bluevk hover:bg-blue-900 border border-bluevk shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
                @click="showDmdEnvoi = false"
              >
                Non
              </button>
            </div>
          </div>
        </div>
        <button
          @click="printPDF(facturationSelected)"
          class="ml-4 bg-orange-600 hover:bg-orange-700 text-white py-2 px-4 rounded"
        >
          Imprimer
        </button>
      </div>
    </div>

    <div class="grid xl:grid-cols-2 gap-4">
      <div v-for="(tabF, indexF) in tabPDF" :key="indexF" class="mt-2">
        <div class="flex flex-col border rounded-md shadow-lg p-2">
          <div class="flex justify-end space-x-4">
            <button
              @click="openOneFacture(tabF.link)"
              class="px-6 py-3 bg-green-500 text-white hover:bg-green-600 shadow-md font-semibold hover:text-white rounded"
            >
              Télécharger
            </button>
            <button
              class="px-6 py-3 bg-blue-500 text-white hover:bg-blue-600 shadow-md font-semibold hover:text-white rounded"
            >
              Envoyer la facture
            </button>
          </div>
          <!-- Détail -->
          <div class="p-8 w-full">
            <div class="w-full">
              <div
                class="flex flex-col items-center w-full md:flex-row sm:flex-row lg:pb-10 xl:pb-10 md:pb-10 sm:pb-5"
              >
                <div class="w-1/2 sm:w-1/4">
                  <img
                    :src="plateform.lien_logo"
                    alt="Logo de l'entreprise"
                    class="w-1/2 h-auto mx-auto"
                  />
                </div>
              </div>

              <div class="mt-4 md:mt-0 sm:mt-0 text-center">
                <div
                  class="text-gray-700 uppercase font-medium text-xl sm:text-s max-w-3/4 mx-auto"
                >
                  Facture
                  <span class="text-bluevk">n° {{ tabF.Numero }}</span>
                </div>
              </div>
            </div>
            <div
              class="flex justify-end text-gray-700 text-xs font-medium pt-8"
            >
              Le {{ getFRDate(tabF.Date) }}
            </div>

            <div class="sm:flex sm:justify-between sm:pt-4 sm:pb-8">
              <div class="sm:w-2/4 md:mr-3 bg-white rounded-sm shadow-md">
                <div
                  class="bg-bluevk text-white text-xs font-medium py-1 px-2 rounded-t-sm"
                >
                  Expéditeur
                </div>
                <div class="md:flex justify-between">
                  <div class="flex-1 md:w-1/2 m-3">
                    <div class="text-bluevk text-xs uppercase font-bold">
                      {{ plateform.Titre2 }}
                    </div>
                    <div class="text-bluevk text-xs font-m mt-1">
                      {{ plateform.Adresse }} <br />
                      {{ plateform.Code_postale }}
                      <span class="uppercase">{{ plateform.Ville }}</span
                      ><br />
                      {{ plateform.Pays }}
                    </div>
                  </div>

                  <div class="my-1 border-l border-gray-300"></div>
                  <div class="flex-1 m-3 pb-3">
                    <div class="text-xs font-bold text-gray-700">
                      {{ plateform.Titre1 }}
                    </div>
                    <div class="text-xs text-gray-700 font-m mt-1">
                      {{ plateform.Email }}<br />
                      {{ plateform.Telephone }}
                    </div>
                    <div class="text-xs text-gray-700 mt-1">
                      {{ plateform.Siret }} - {{ plateform.Tva }} -
                      {{ plateform.Naf }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="sm:w-2/4 md:ml-3 bg-white rounded-sm shadow-md">
                <div
                  class="bg-bluevk text-white text-xs font-medium py-1 px-2 rounded-t-sm"
                >
                  Destinataire
                </div>
                <div class="md:flex justify-between" v-if="tabF.clientInfo[0]">
                  <div class="flex-1 m-3">
                    <div class="text-bluevk text-xs uppercase font-bold">
                      {{ tabF.Client }}
                    </div>
                    <div class="text-bluevk text-xs font-m mt-1">
                      {{ tabF.clientInfo[0].Adresse }}<br />
                      {{ tabF.clientInfo[0].PostalCode }}
                      <span class="uppercase">{{
                        tabF.clientInfo[0].City
                      }}</span
                      ><br />
                      {{ tabF.clientInfo[0].Country }}
                    </div>
                  </div>
                  <div class="my-1 border-l border-gray-300"></div>
                  <div class="flex-1 m-3 pb-3">
                    <div class="text-xs text-gray-700 font-bold">
                      {{ tabF.Name }}
                    </div>
                    <div class="text-xs text-gray-700 font-m mt-1">
                      {{ tabF.clientInfo[0].email }}<br />
                      {{ tabF.clientInfo[0].telephone }}
                    </div>
                    <div class="text-xs text-gray-700 mt-1">
                      {{ tabF.clientInfo[0].siret }} -
                      {{ tabF.clientInfo[0].numerotva }} -
                      {{ tabF.clientInfo[0].nafape }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="tabF.Bdls.length > 0"
              class="overflow-y-auto border rounded-md shadow-lg p-1"
              style="height: 40rem"
            >
              <div v-for="(bdl, indexBdl) in tabF.Bdls" :key="indexBdl">
                <div class="">
                  <div class="text-xs font-bold text-gray-700">
                    Bdl n° : {{ bdl.Numero }}
                  </div>
                  <div class="text-xs font-bold text-gray-700">
                    Servi par : {{ bdl.Name }}
                  </div>
                  <div class="text-xs italic font-medium text-bluevk ml-auto">
                    Commentaire : {{ bdl.Note }}
                  </div>
                </div>
                <div class="overflow-x-auto">
                  <table
                    class="mt-2 mb-4 w-full text-gray-700 text-xs rounded-sm border-collapse"
                  >
                    <thead>
                      <tr
                        class="bg-bluevk text-white text-left px-3 rounded-t-sm"
                      >
                        <th class="px-2 py-1 text-xs font-medium">Qté</th>
                        <th class="px-2 py-1 text-xs font-medium">Réf.</th>
                        <th class="px-2 py-1 text-xs font-medium">
                          Appellation
                        </th>
                        <th
                          class="px-2 py-1 text-xs font-medium"
                          v-if="user.accountType == 'Administrateur'"
                        >
                          PA €
                        </th>
                        <th
                          class="px-2 py-1 text-xs font-medium"
                          v-if="user.accountType == 'Administrateur'"
                        >
                          Marge sur PV €
                        </th>
                        <th
                          class="px-2 py-1 text-xs font-medium"
                          v-if="user.accountType == 'Administrateur'"
                        >
                          Marge sur PV %
                        </th>
                        <th class="px-2 py-1 text-xs font-medium">
                          Prix brut HT
                        </th>
                        <th class="px-2 py-1 text-xs font-medium">Remise</th>
                        <th class="px-2 py-1 text-xs font-medium">
                          Prix net HT
                        </th>
                        <th class="px-2 py-1 text-xs font-medium">
                          Montant HT
                        </th>
                      </tr>
                    </thead>

                    <tbody class="text-xs font-medium">
                      <tr v-for="(article, index) in bdl.Articles" :key="index">
                        <td class="border border-gray-300 p-2">
                          {{ article.quantity }}
                        </td>

                        <td class="border border-gray-300 p-2">
                          {{ article.Ref_fournisseur }}
                        </td>

                        <td class="border border-gray-300 p-2">
                          {{ article.Description }}
                        </td>

                        <td
                          class="border border-gray-300 p-2 font-bold text-orange-600"
                          v-if="user.accountType == 'Administrateur'"
                        >
                          {{ parseFloat(article.PrixAchat).toFixed(2) }}
                          €
                        </td>

                        <td
                          class="border border-gray-300 p-2 font-bold text-green-600"
                          v-if="user.accountType == 'Administrateur'"
                        >
                          {{
                            parseFloat(
                              parseFloat(article.Prix_vente).toFixed(2) -
                                parseFloat(article.PrixAchat).toFixed(2)
                            ).toFixed(2)
                          }}
                          €
                        </td>

                        <td
                          class="border border-gray-300 p-2 font-bold text-green-600"
                          v-if="user.accountType == 'Administrateur'"
                        >
                          {{
                            parseFloat(
                              ((parseFloat(article.Prix_vente).toFixed(2) -
                                parseFloat(article.PrixAchat).toFixed(2)) /
                                parseFloat(article.Prix_euro).toFixed(2)) *
                                100
                            ).toFixed(2)
                          }}
                          %
                        </td>

                        <td class="border border-gray-300 p-2">
                          {{ parseFloat(article.Prix_euro).toFixed(2) }}€
                        </td>

                        <td class="border border-gray-300 p-2">
                          {{ article.remise }}%
                        </td>

                        <td class="border border-gray-300 p-2">
                          <div v-if="article.PrixFournisseurType != 'prixnet'">
                            {{
                              parseFloat(
                                article.Prix_euro -
                                  article.Prix_euro * (article.remise / 100)
                              ).toFixed(2)
                            }}
                          </div>
                          <div v-else>
                            {{ parseFloat(article.Prix_vente).toFixed(2) }}
                          </div>
                        </td>

                        <td class="border border-gray-300 p-2">
                          <div v-if="article.PrixFournisseurType != 'prixnet'">
                            {{
                              parseFloat(
                                (article.Prix_euro -
                                  article.Prix_euro * (article.remise / 100)) *
                                  article.quantity
                              ).toFixed(2)
                            }}
                          </div>
                          <div v-else>
                            {{
                              parseFloat(
                                article.Prix_vente * article.quantity
                              ).toFixed(2)
                            }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="md:mt-8">
                <div class="text-xs font-bold text-gray-700">
                  Servi par : {{ tabF.Name }}
                </div>
                <div class="text-xs italic font-medium text-bluevk ml-auto">
                  Commentaire : {{ tabF.Note }}
                </div>
              </div>
              <div class="overflow-x-auto">
                <table
                  class="mt-2 mb-4 w-full text-gray-700 text-xs rounded-sm border-collapse"
                >
                  <thead>
                    <tr
                      class="bg-bluevk text-white text-left px-3 rounded-t-sm"
                    >
                      <th class="px-2 py-1 text-xs font-medium">Qté</th>
                      <th class="px-2 py-1 text-xs font-medium">Réf.</th>
                      <th class="px-2 py-1 text-xs font-medium">Appellation</th>
                      <th
                        class="px-2 py-1 text-xs font-medium"
                        v-if="user.accountType == 'Administrateur'"
                      >
                        PA €
                      </th>
                      <th
                        class="px-2 py-1 text-xs font-medium"
                        v-if="user.accountType == 'Administrateur'"
                      >
                        Marge sur PV €
                      </th>
                      <th
                        class="px-2 py-1 text-xs font-medium"
                        v-if="user.accountType == 'Administrateur'"
                      >
                        Marge sur PV %
                      </th>
                      <th class="px-2 py-1 text-xs font-medium">
                        Prix brut HT
                      </th>
                      <th class="px-2 py-1 text-xs font-medium">Remise</th>
                      <th class="px-2 py-1 text-xs font-medium">Prix net HT</th>
                      <th class="px-2 py-1 text-xs font-medium">Montant HT</th>
                    </tr>
                  </thead>

                  <tbody class="text-xs font-medium">
                    <tr v-for="(article, index) in tabF.pdfData" :key="index">
                      <td class="border border-gray-300 p-2">
                        {{ article.quantite }}
                      </td>

                      <td class="border border-gray-300 p-2">
                        {{ article.ref }}
                      </td>

                      <td class="border border-gray-300 p-2">
                        {{ article.description }}
                      </td>

                      <td
                        class="border border-gray-300 p-2 font-bold text-orange-600"
                        v-if="user.accountType == 'Administrateur'"
                      >
                        {{
                          parseFloat(tabF.Articles[index].PrixAchat).toFixed(2)
                        }}
                        €
                      </td>

                      <td
                        class="border border-gray-300 p-2 font-bold text-green-600"
                        v-if="user.accountType == 'Administrateur'"
                      >
                        {{
                          parseFloat(
                            parseFloat(tabF.Articles[index].Prix_vente).toFixed(
                              2
                            ) -
                              parseFloat(
                                tabF.Articles[index].PrixAchat
                              ).toFixed(2)
                          ).toFixed(2)
                        }}
                        €
                      </td>

                      <td
                        class="border border-gray-300 p-2 font-bold text-green-600"
                        v-if="user.accountType == 'Administrateur'"
                      >
                        {{
                          parseFloat(
                            ((parseFloat(
                              tabF.Articles[index].Prix_vente
                            ).toFixed(2) -
                              parseFloat(
                                tabF.Articles[index].PrixAchat
                              ).toFixed(2)) /
                              parseFloat(
                                tabF.Articles[index].Prix_euro
                              ).toFixed(2)) *
                              100
                          ).toFixed(2)
                        }}
                        %
                      </td>

                      <td class="border border-gray-300 p-2">
                        {{
                          parseFloat(tabF.Articles[index].Prix_euro).toFixed(2)
                        }}€
                      </td>

                      <td class="border border-gray-300 p-2">
                        {{ tabF.Articles.remise }}%
                      </td>

                      <td class="border border-gray-300 p-2">
                        <div
                          v-if="
                            tabF.Articles[index].PrixFournisseurType !=
                            'prixnet'
                          "
                        >
                          {{
                            parseFloat(
                              tabF.Articles[index].Prix_euro -
                                tabF.Articles[index].Prix_euro *
                                  (article.remise / 100)
                            ).toFixed(2)
                          }}
                        </div>
                        <div v-else>
                          {{
                            parseFloat(tabF.Articles[index].Prix_vente).toFixed(
                              2
                            )
                          }}
                        </div>
                      </td>

                      <td class="border border-gray-300 p-2">
                        {{ parseFloat(article.total).toFixed(2) }}€
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="flex justify-end items-center pb-8 text-gray-700">
              <div class="w-1/3 flex justify-end">
                <table
                  class="mt-4 mb-8 w-full rounded border-collapse border border-gray-300"
                >
                  <thead></thead>
                  <tbody class="text-xs font-medium">
                    <tr>
                      <td class="border border-gray-300 font-bold p-2">
                        Transport
                      </td>
                      <td class="border border-gray-300 p-2">
                        {{ parseFloat(tabF.Transport).toFixed(2) }}€
                      </td>
                    </tr>

                    <tr>
                      <td class="border border-gray-300 font-bold p-2">
                        Total HT
                      </td>
                      <td class="border border-gray-300 p-2">
                        {{ parseFloat(tabF.TotalPrice).toFixed(2) }}€
                      </td>
                    </tr>

                    <tr>
                      <td class="border border-gray-300 font-bold p-2">TVA</td>
                      <td class="border border-gray-300 p-2">
                        {{ parseFloat(tabF.TotalPrice * 0.2).toFixed(2) }}€
                      </td>
                    </tr>

                    <tr>
                      <td class="border border-gray-300 font-bold p-2">
                        Total TTC
                      </td>
                      <td class="border border-gray-300 p-2">
                        {{
                          parseFloat(
                            tabF.TotalPrice + tabF.TotalPrice * 0.2
                          ).toFixed(2)
                        }}€
                      </td>
                    </tr>

                    <tr v-if="user.accountType == 'Administrateur'">
                      <td class="border border-gray-300 font-bold p-2">
                        Marge
                      </td>
                      <td
                        class="border border-gray-300 font-bold p-2 text-green-600"
                      >
                        {{ setMargeTotal[indexF] }} €, soit
                        {{ setMargeTotalePourcentage[indexF] }} %
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="w-1/3 flex ml-auto">
              <div
                class="text-xs font-medium flex justify-end items-center text-gray-700 ml-auto"
              >
                <p>
                  <span class="font-bold">Date d'échéance :</span>
                  {{ getFRDate(tabF.paymentDeadline) }}
                </p>
              </div>
            </div>
            <div class="flex justify-end items-center pb-8 text-gray-700">
              <div class="w-1/3 flex justify-end">
                <table
                  class="mt-2 mb-8 w-full rounded border-collapse border border-gray-300"
                  v-if="tabF.reglement.length > 0"
                >
                  <thead>
                    <tr>
                      <td
                        class="text-xs font-medium py-1 px-2 rounded-t-sm border border-gray-300 bg-bluevk text-white font-bold p-2"
                        colspan="2"
                      >
                        Mode de règlement
                      </td>
                    </tr>
                  </thead>
                  <tbody class="text-xs font-medium">
                    <tr>
                      <td class="border border-gray-300 p-2">
                        <div class="flex flex-row space-x-2">
                          <span
                            v-for="(regl, indexRegl) in tabF.reglement"
                            :key="indexRegl"
                          >
                            {{ regl.placeholder }} {{ regl.model }}€
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import backButton from "@/components/Button/back.vue";
import { printfacture, sendfacture } from "@/hooks/pdf/facturation";
// import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useToast } from "vue-toastification";
import { getFrDate } from "@/hooks/tools/date.ts";
import { PDFDocument } from "pdf-lib";
import PrintDialog from "@/components/Documents/printDialog.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      loadingEnvoi: false,
      showDmdEnvoi: false,
      printDialogVisible: false,
      tabPDF: [],
      openModalPDF: false,
      factureLink: "",
    };
  },
  components: {
    backButton,
    // PulseLoader,
    PrintDialog,
  },
  computed: {
    ...mapGetters(["user", "plateform", "facturationSelected"]),
    // dateEcheance() {
    //   let dateEditionParts;
    //   let day;
    //   let month;
    //   let year;

    //   let nextMonth;
    //   let nextYear;

    //   let lastDayOfNextMonth;

    //   let dayFormatted;
    //   let monthFormatted;
    //   let yearFormatted;

    //   let tabDate = [];
    //   for (const [indexF, fact] of this.tabPDF.entries()) {
    //     dateEditionParts = this.getFRDate(fact.Date).split("/");
    //     day = parseInt(dateEditionParts[0], 10);
    //     month = parseInt(dateEditionParts[1], 10);
    //     year = parseInt(dateEditionParts[2], 10);

    //     nextMonth = month === 12 ? 1 : month + 1;
    //     nextYear = month === 12 ? year + 1 : year;

    //     lastDayOfNextMonth = new Date(nextYear, nextMonth, 0);

    //     dayFormatted = String(lastDayOfNextMonth.getDate()).padStart(2, "0");
    //     monthFormatted = String(lastDayOfNextMonth.getMonth() + 1).padStart(
    //       2,
    //       "0"
    //     );
    //     yearFormatted = lastDayOfNextMonth.getFullYear();
    //     tabDate[indexF] = `${dayFormatted}/${monthFormatted}/${yearFormatted}`;
    //   }

    //   return tabDate;
    // },
    setMargeTotal() {
      let tabMarge = [];
      for (const [indexF, fact] of this.tabPDF.entries()) {
        var total = 0.0;
        if (fact.Bdls.length > 0) {
          for (const bdl of fact.Bdls) {
            for (const art of bdl.Articles) {
              if (art.PrixAchat) {
                const marge = parseFloat(
                  parseFloat(art.Prix_vente).toFixed(2) -
                    parseFloat(art.PrixAchat).toFixed(2)
                ).toFixed(2);
                total += parseFloat(marge) * art.quantity;
              }
            }
          }
        } else {
          for (const art of fact.Articles) {
            if (art.PrixAchat) {
              const marge = parseFloat(
                parseFloat(art.Prix_vente).toFixed(2) -
                  parseFloat(art.PrixAchat).toFixed(2)
              ).toFixed(2);
              total += parseFloat(marge) * art.quantity;
            }
          }
        }

        tabMarge[indexF] = total.toFixed(2);
      }

      return tabMarge;
    },
    setMargeTotalePourcentage() {
      let tabMarge = [];
      for (const [indexF, fact] of this.tabPDF.entries()) {
        var chiffreAffairesPPC = 0.0;
        var chiffreAffairesPV = 0.0;
        var coutTotal = 0.0;
        if (fact.Bdls.length > 0) {
          for (const bdl of fact.Bdls) {
            for (const art of bdl.Articles) {
              if (art.PrixAchat) {
                chiffreAffairesPPC +=
                  parseFloat(art.Prix_euro).toFixed(2) * art.quantity;
                chiffreAffairesPV +=
                  parseFloat(art.Prix_vente).toFixed(2) * art.quantity;
                coutTotal +=
                  parseFloat(art.PrixAchat).toFixed(2) * art.quantity;
              }
            }
          }
        } else {
          for (const art of fact.Articles) {
            if (art.PrixAchat) {
              chiffreAffairesPPC +=
                parseFloat(art.Prix_euro).toFixed(2) * art.quantity;
              chiffreAffairesPV +=
                parseFloat(art.Prix_vente).toFixed(2) * art.quantity;
              coutTotal += parseFloat(art.PrixAchat).toFixed(2) * art.quantity;
            }
          }
        }
        const margeTotalePourcentage =
          ((parseFloat(chiffreAffairesPV) - parseFloat(coutTotal)) /
            parseFloat(chiffreAffairesPPC)) *
          100;

        tabMarge[indexF] = margeTotalePourcentage.toFixed(2);
      }

      return tabMarge;
    },
  },
  methods: {
    handleCancelPrint() {
      this.printDialogVisible = false;
    },
    getFRDate(date) {
      return getFrDate(date);
    },
    async getPdfLink() {
      const response = await axios.post(
        `${process.env.VUE_APP_API}/facture/getPdfLinkDetail`,
        {
          plateform: this.user.proprietaire,
          numeroF: this.facturationSelected.numeroFacturation,
        }
      );
      this.tabPDF = response.data;
    },
    async printPDF() {
      // printfacture(objet);
      this.printDialogVisible = true;
      // try {
      //   // Créer un nouveau document PDF
      //   const mergedPdf = await PDFDocument.create();

      //   for (const pdfUrl of this.tabPDF) {
      //     const response = await fetch(pdfUrl);
      //     const arrayBuffer = await response.arrayBuffer();
      //     const sourcePdf = await PDFDocument.load(arrayBuffer);

      //     // Copier toutes les pages du document source
      //     const copiedPages = await mergedPdf.copyPages(
      //       sourcePdf,
      //       sourcePdf.getPageIndices()
      //     );
      //     copiedPages.forEach((page) => {
      //       mergedPdf.addPage(page); // Ajouter les pages au document combiné
      //     });

      //     const totalPages = sourcePdf.getPageCount(); // Obtenir le nombre total de pages

      //     // Ajouter une page vierge si le nombre de pages est impair
      //     if (totalPages % 2 != 0) {
      //       console.log("totalPages ", totalPages);
      //       mergedPdf.addPage(); // Ajouter une page vierge
      //     }
      //   }

      //   const mergedPdfBytes = await mergedPdf.save(); // Enregistrer le document combiné
      //   const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
      //   const url = URL.createObjectURL(blob); // Créer un objet URL

      //   const newWindow = window.open(url, "_blank");

      //   if (newWindow) {
      //     newWindow.addEventListener("load", () => {
      //       newWindow.print();
      //       console.log("print"); // Imprimer le document combiné
      //     });
      //   }
      // } catch (error) {
      //   console.error("Erreur lors de la récupération du PDF:", error);
      // }
    },
    openOneFacture(link) {
      this.openModalPDF = true;
      this.factureLink = link;
    },
    async sendPDF(objet) {
      this.showDmdEnvoi = false;
      this.loadingEnvoi = true;
      for (const facture of objet) {
        await sendfacture(facture);
      }
      this.loadingEnvoi = false;
      this.success("Factures envoyées !");
    },
    success(msg) {
      this.toast.success(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  beforeMount() {
    this.getPdfLink();
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 30px;
  border-radius: 10px;
  z-index: 1000;
}

.modal-actions {
  margin-top: 20px;
}
</style>
