<template>
  <router-link class="xs:hidden sm:block -mt-3 w-fit" to="/articlehub">
    <div id="retour" class="flex justify-start">
      <backButton />
    </div>
  </router-link>

  <div class="xs:-mt-3 sm:mt-0">
    <!-- PARTIE DE RECHERCHE -->
    <div class="flex flex-col sm:mt-2 space-y-2 xs:text-xs sm:text-base">
      <div
        class="flex xs:flex-col xs:space-y-1 lg:space-y-0 lg:flex-row lg:space-x-2 w-full"
      >
        <!-- Recherche par EAN -->
        <div class="w-full relative rounded-md shadow-sm">
          <span
            class="absolute material-icons-round inset-y-0 left-0 flex items-center pl-3 text-gray-600"
            >qr_code_scanner</span
          >
          <input
            type="text"
            ref="scan"
            placeholder="Code-barres"
            v-model="articleSearchEAN"
            v-on:keyup.enter="
              findArticleByEAN(
                articleSearchEAN,
                articleSearchBrand,
                articleSearchPrefix
              )
            "
            :disabled="articleSearchRef != ''"
            class="w-full border focus:outline-2 outline-sky-300 px-12 py-2 rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
          />
          <button
            @click="
              findArticleByEAN(
                articleSearchEAN,
                articleSearchBrand,
                articleSearchPrefix
              )
            "
            class="absolute material-icons-round xs:text-sm sm:text-base p-1 right-0 bg-bluevk hover:bg-opacity-60 xs:mt-0.5 sm:mt-1 mr-2 text-white shadow-sm px-1 rounded-md"
          >
            search
          </button>
        </div>

        <!-- SUR TEL PLUS DE FILTRES -->
        <div
          v-if="!moreFilter"
          @click="showMoreFilter(true)"
          class="block border flex justify-between hover:opacity-60 text-xs bg-white rounded-md shadow-lg lg:hidden"
        >
          <p class="my-auto ml-2 text-bluevk">Plus de filtres</p>
          <p class="material-icons-round">keyboard_arrow_down</p>
        </div>
        <div
          v-else
          @click="showMoreFilter(false)"
          class="block border flex justify-between hover:opacity-60 text-xs bg-white rounded-md shadow-lg lg:hidden"
        >
          <p class="my-auto ml-2 text-bluevk">Plus de filtres</p>
          <p class="material-icons-round">keyboard_arrow_up</p>
        </div>

        <!-- Recherche par Référence ou Référence Complémentaire -->
        <div
          v-if="!isXSscreen() || moreFilter"
          class="w-full relative rounded-md shadow-sm"
        >
          <span
            class="absolute material-icons-round inset-y-0 left-0 flex items-center pl-3 text-gray-600"
            >pin
          </span>
          <input
            type="text"
            ref="ref"
            placeholder="Référence ou référence complémentaire"
            v-model="articleSearchRef"
            v-on:keyup.enter="
              findArticleByRef(
                articleSearchRef,
                articleSearchBrand,
                articleSearchPrefix
              )
            "
            :disabled="articleSearchEAN != ''"
            class="w-full border focus:outline-2 outline-sky-300 px-12 py-2 rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
          />
          <button
            @click="
              findArticleByRef(
                articleSearchRef,
                articleSearchBrand,
                articleSearchPrefix
              )
            "
            class="absolute material-icons-round xs:text-sm sm:text-base p-1 right-0 bg-bluevk hover:bg-opacity-60 xs:mt-0.5 sm:mt-1 mr-2 text-white shadow-sm px-1 rounded-md"
          >
            search
          </button>
        </div>
      </div>

      <!-- Recherche par Préfixe ou Marque -->
      <div
        v-if="!isXSscreen() || moreFilter"
        class="flex xs:flex-col xs:space-y-1 lg:space-y-0 lg:flex-row lg:space-x-2 w-full"
      >
        <!-- Recherche par Préfixe -->
        <div class="w-full relative rounded-md shadow-sm">
          <span
            class="absolute material-icons-round inset-y-0 left-0 flex items-center pl-3 text-gray-600"
            >local_shipping</span
          >
          <input
            type="text"
            ref="scan"
            placeholder="Préfixe tarif fournisseur"
            v-model="articleSearchPrefix"
            v-on:keyup.enter="findByRefOrEAN(articleSearchPrefix, '')"
            class="w-full border focus:outline-2 outline-sky-300 px-12 py-2 rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
          />
          <button
            @click="findByRefOrEAN(articleSearchPrefix, '')"
            class="absolute material-icons-round xs:text-sm sm:text-base p-1 right-0 bg-bluevk hover:bg-opacity-60 xs:mt-0.5 sm:mt-1 mr-2 text-white shadow-sm px-1 rounded-md"
          >
            search
          </button>
        </div>

        <!-- Recherche par Marque -->
        <div class="w-full relative rounded-md shadow-sm">
          <span
            class="absolute material-icons-round inset-y-0 left-0 flex items-center pl-3 text-gray-600"
            >label
          </span>
          <input
            type="text"
            ref="ref"
            placeholder="Marque"
            v-model="articleSearchBrand"
            v-on:keyup.enter="findByRefOrEAN('', articleSearchBrand)"
            class="w-full border focus:outline-2 outline-sky-300 px-12 py-2 rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
          />
          <button
            @click="findByRefOrEAN('', articleSearchBrand)"
            class="absolute material-icons-round xs:text-sm sm:text-base p-1 right-0 bg-bluevk hover:bg-opacity-60 xs:mt-0.5 sm:mt-1 mr-2 text-white shadow-sm px-1 rounded-md"
          >
            search
          </button>
        </div>
      </div>
    </div>
    <!-- Message d'erreur -->
    <p v-show="errorMsg" class="text-orange-400">Article non trouvé !</p>

    <!-- FILTRE -->
    <div class="mt-1 flex space-x-4 xs:text-xs sm:text-base">
      <!-- PREFIXE TARIF CHOISI -->
      <div
        v-if="prefixRegulStock"
        class="w-fit flex shadow-lg border rounded-md xs:p-1 sm:p-2 bg-orangevk text-white"
      >
        <p>{{ prefixRegulStock }}</p>
        <button
          class="material-icons-round xs:text-sm sm:text-xl text-red-600 text-shadow-lg hover:text-red-900 hover:text-shadow-2xl"
          @click="setSearchRegulStock({ prefix: '', brand: brandRegulStock })"
        >
          close
        </button>
      </div>
      <!-- MARQUE CHOISIE -->
      <div
        v-if="brandRegulStock"
        class="w-fit flex shadow-lg border rounded-md xs:p-1 sm:p-2 bg-orangevk text-white"
      >
        <p>{{ brandRegulStock }}</p>
        <button
          class="material-icons-round xs:text-sm sm:text-xl text-red-600 text-shadow-lg hover:text-red-900 hover:text-shadow-2xl"
          @click="setSearchRegulStock({ prefix: prefixRegulStock, brand: '' })"
        >
          close
        </button>
      </div>
    </div>

    <!-- RESPONSE -->
    <div
      v-if="articlesSearch.length > 0"
      class="mt-2 bg-white p-4 inline-block h-fit min-w-full align-middle border-b border-gray-200 shadow sm:rounded-lg"
    >
      <h1 class="text-bluevk">Résultat :</h1>
      <table class="p-2 border shadow-sm mt-1 min-w-full" style="width: 65rem">
        <thead>
          <tr>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
            >
              Réf. / Marque
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
            >
              Description / Préfixe tarif
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
            >
              Stock
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
            >
              Réservé
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
            >
              Commande fourn.
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
            >
              min / max
            </th>
          </tr>
        </thead>
        <tbody class="bg-white max-h-36 overflow-y-auto text-sm">
          <tr
            v-for="(art, index) in articlesSearch"
            :key="index"
            class="cursor-pointer odd:bg-white even:bg-gray-100 hover:bg-bluevk hover:text-white border-black hover:shadow-lg"
            @click="
              (selectedArticle = art),
                (inputStock = art.stock),
                (articlesSearch = [])
            "
          >
            <td class="px-2 border-b border-gray-200 whitespace-nowrap">
              <p>{{ articleSearch.Ref_fournisseur }}</p>
              <p>{{ articleSearch.Code_marque }}</p>
            </td>

            <td class="px-2 border-b border-gray-200 whitespace-nowrap">
              <p v-if="articleSearch.article">
                {{ articleSearch.article.Description }}
              </p>
              <p class="text-md text-orangevk">{{ art.Prefixe_tarif }}</p>
            </td>

            <td class="px-2 border-b border-gray-200 whitespace-nowrap">
              <p>{{ art.stock }}</p>
            </td>

            <td class="px-2 border-b border-gray-200 whitespace-nowrap">
              <p>{{ art.reserved }}</p>
            </td>

            <td class="px-2 border-b border-gray-200 whitespace-nowrap">
              <p>{{ art.backorder }}</p>
            </td>

            <td class="px-2 border-b border-gray-200 whitespace-nowrap">
              <p>{{ articleSearch.min }}</p>
              <p>{{ articleSearch.max }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- TABLEAU -->
    <div v-if="Object.keys(selectedArticle).length > 0" class="flex">
      <div class="w-full bg-white shadow rounded mt-2 p-2">
        <!-- Informations de l'article -->
        <div class="xs:flex xs:space-x-1 sm:block sm:space-x-0">
          <div class="lg:mt-4 lg:flex lg:space-x-4">
            <!-- Référence -->
            <div class="w-full relative xs:text-xs sm:text-sm">
              <input
                type="text"
                id="reference"
                disabled
                v-model="articleSearch.Ref_fournisseur"
                class="block px-2.5 pb-1 pt-2 w-full sm:text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
              />
              <label
                for="reference"
                class="absolute sm:text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >Référence
              </label>
            </div>

            <!-- Marque -->
            <div class="hidden lg:block w-full relative sm:text-sm">
              <input
                type="text"
                id="marque"
                disabled
                v-model="articleSearch.Code_marque"
                class="block px-2.5 pb-1 pt-2 w-full sm:text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
              />
              <label
                for="marque"
                class="absolute sm:text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >Marque
              </label>
            </div>

            <!-- Description -->
            <div
              v-if="articleSearch.article"
              class="hidden lg:block w-full relative sm:text-sm"
            >
              <input
                type="text"
                id="description"
                disabled
                v-model="articleSearch.article.Description"
                class="block px-2.5 pb-1 pt-2 w-full sm:text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
              />
              <label
                for="description"
                class="absolute sm:text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >Description
              </label>
            </div>
          </div>

          <div class="lg:mt-4 lg:flex lg:space-x-4">
            <!-- Préfixe tarif -->
            <div class="hidden lg:block w-fit relative sm:text-sm">
              <input
                type="text"
                id="prefixe"
                disabled
                v-model="selectedArticle.Prefixe_tarif"
                class="block px-2.5 pb-1 pt-2 w-full sm:text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
              />
              <label
                for="prefixe"
                class="absolute sm:text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >Préfixe tarif
              </label>
            </div>

            <!-- Adresse de l'article -->
            <div class="xs:w-full sm:w-fit relative">
              <div class="flex xs:text-xs">
                <input
                  type="text"
                  id="adresse"
                  v-model="selectedArticle.address"
                  @keyup.enter="updateAddressStock(selectedArticle.address)"
                  class="block pl-2.5 pr-7 pb-1 pt-2 w-full sm:text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                />
                <label
                  for="adresse"
                  class="absolute sm:text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >Adresse</label
                >
                <button
                  @click="updateAddressStock(selectedArticle.address)"
                  class="absolute material-icons-round right-0 xs:mt-0.5 sm:mt-1 bg-orangevk hover:bg-opacity-60 mr-2 text-white shadow-sm px-1 rounded-md"
                >
                  done
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Tableau de stock/réservé/commande fournisseur -->
        <div class="hidden lg:flex lg:flex-row lg:mt-6">
          <div class="w-1/3">
            <div class="flex flex-col items-center">
              <input
                type="number"
                ref="stock"
                v-model="selectedArticle.stock"
                class="w-28 text-6xl border rounded-md shadow-sm text-bluevk outline-2 focus:outline-sky-300"
                @click="setInput('stock')"
                @keyup.enter="setNumber(selectedArticle.stock)"
              />
              <span>Stock</span>
            </div>
          </div>
          <div class="w-1/3">
            <div class="flex flex-col items-center">
              <input
                type="number"
                ref="reserved"
                v-model="selectedArticle.reserved"
                class="w-28 text-6xl border rounded-md shadow-sm text-bluevk outline-2 focus:outline-sky-300"
                @click="setInput('reserved')"
                @keyup.enter="setNumber(selectedArticle.reserved)"
              />
              <span>Réservé client</span>
            </div>
          </div>
          <div class="w-1/3">
            <div class="flex flex-col items-center">
              <input
                type="number"
                ref="backorder"
                v-model="selectedArticle.backorder"
                class="w-28 text-6xl border rounded-md shadow-sm text-bluevk outline-2 focus:outline-sky-300"
                @click="setInput('backorder')"
                @keyup.enter="setNumber(selectedArticle.backorder)"
              />
              <span>Commande fournisseur</span>
            </div>
          </div>
        </div>

        <!-- SUR PC -->
        <div class="hidden border shadow-lg rounded-md lg:block">
          <div class="flex flex-row mt-8">
            <div class="w-1/3">
              <div
                class="flex flex-col items-center shadow rounded cursor-pointer"
                @click="setNumber('1')"
              >
                <span class="text-6xl">1</span>
              </div>
            </div>
            <div class="w-1/3">
              <div
                class="flex flex-col items-center shadow rounded cursor-pointer"
                @click="setNumber('2')"
              >
                <span class="text-6xl">2</span>
              </div>
            </div>
            <div class="w-1/3">
              <div
                class="flex flex-col items-center shadow rounded cursor-pointer"
                @click="setNumber('3')"
              >
                <span class="text-6xl">3</span>
              </div>
            </div>
          </div>
          <div class="flex flex-row mt-4">
            <div class="w-1/3">
              <div
                class="flex flex-col items-center shadow rounded cursor-pointer"
                @click="setNumber('4')"
              >
                <span class="text-6xl">4</span>
              </div>
            </div>
            <div class="w-1/3">
              <div
                class="flex flex-col items-center shadow rounded cursor-pointer"
                @click="setNumber('5')"
              >
                <span class="text-6xl">5</span>
              </div>
            </div>
            <div class="w-1/3">
              <div
                class="flex flex-col items-center shadow rounded cursor-pointer"
                @click="setNumber('6')"
              >
                <span class="text-6xl">6</span>
              </div>
            </div>
          </div>
          <div class="flex mt-4">
            <div class="w-1/3">
              <div
                class="flex flex-col items-center shadow rounded cursor-pointer"
                @click="setNumber('7')"
              >
                <span class="text-6xl">7</span>
              </div>
            </div>
            <div class="w-1/3">
              <div
                class="flex flex-col items-center shadow rounded cursor-pointer"
                @click="setNumber('8')"
              >
                <span class="text-6xl">8</span>
              </div>
            </div>
            <div class="w-1/3">
              <div
                class="flex flex-col items-center shadow rounded cursor-pointer"
                @click="setNumber('9')"
              >
                <span class="text-6xl">9</span>
              </div>
            </div>
          </div>
          <div class="flex mt-4 justify-center">
            <div class="w-1/3">
              <div
                class="flex flex-col items-center shadow rounded cursor-pointer"
                @click="setNumber('0')"
              >
                <span class="text-6xl">0</span>
              </div>
            </div>
          </div>
          <!-- Réinitialiser la quantité -->
          <div class="flex justify-center space-x-4 mt-4">
            <div class="w-1/4">
              <div
                class="flex flex-col items-center shadow rounded text-white bg-red-500 cursor-pointer"
                @click="deleteNumber()"
              >
                <span class="text-2xl p-2">Supprimer</span>
              </div>
            </div>
          </div>
        </div>

        <!-- SUR TEL -->
        <div class="lg:hidden">
          <div class="flex mt-2 space-x-2">
            <!-- PARTIE GAUCHE -->
            <div class="flex flex-col">
              <div class="flex flex-col items-center">
                <span class="text-blue-800">+</span>
                <label class="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    v-model="stateNumber"
                    class="sr-only peer"
                  />
                  <div
                    class="h-7 w-4 bg-blue-700 peer-focus:outline-none text-left dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-blue-800 peer-checked:after:translate-y-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-blue-800 peer-checked:bg-orange-500"
                  ></div>
                </label>
                <span class="text-orange-500">=</span>
              </div>
              <div
                class="flex flex-col space-y-1 overflow-y-auto border border-gray-200 p-0.5 pr-1.5 rounded-md shadow-lg"
                style="max-height: 200px"
              >
                <div v-for="(num, index) in numbers" :key="index">
                  <button>
                    <p
                      class="w-8 border rounded-md shadow-lg font-medium text-white bg-gray-500 hover:bg-gray-800 border border-gray-800"
                      @click="setNumberStock(num)"
                    >
                      {{ num }}
                    </p>
                    <p v-if="index < numbers.length - 1" class="text-center">
                      --
                    </p>
                  </button>
                </div>
              </div>
            </div>

            <!-- PARTIE DROITE -->
            <div class="flex">
              <div class="flex flex-col space-y-2 align-center">
                <div
                  class="flex bg-green-500 w-full rounded shadow cursor-pointer"
                  @click="upInputStock()"
                >
                  <span
                    class="material-symbols-rounded text-7xl text-white mx-auto"
                  >
                    keyboard_arrow_up
                  </span>
                </div>
                <div class="w-full relative rounded-md shadow-sm">
                  <input
                    type="text"
                    ref="scan"
                    v-model="selectedArticle.stock"
                    @keyup.enter="updateStock()"
                    class="w-full text-center focus:outline-2 outline-sky-300 py-4 pl-2 pr-12 text-white text-5xl bg-bluevk shadow rounded"
                  />
                  <button
                    @click="updateStock()"
                    class="absolute material-icons-round my-3 py-4 px-1 text-4xl right-0 hover:bg-white hover:bg-opacity-60 mr-0.5 border text-white shadow-sm px-1 rounded-md"
                  >
                    arrow_forward_ios
                  </button>
                </div>
                <div
                  class="flex bg-red-500 w-full rounded shadow cursor-pointer"
                  @click="downInputStock()"
                >
                  <span
                    class="material-symbols-rounded text-7xl text-white mx-auto"
                  >
                    keyboard_arrow_down
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <audio id="son1" controls class="hidden">
      <source src="../assets/sonTest1.ogg" type="audio/ogg" />
    </audio>
    <audio id="son2" controls class="hidden">
      <source src="../assets/sonTest2.ogg" type="audio/ogg" />
    </audio>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import backButton from "@/components/Button/back.vue";
import {
  scanStock,
  findStock,
  updateAddress,
  updateOrCreateStock,
  updateReservation,
  updateBackorder,
} from "@/hooks/stock/stock.ts";
import { success } from "@/hooks/tools/toast.ts";

export default {
  name: "MyInventaire",
  data() {
    return {
      erreurAdresse: false,
      Articles: [],
      // articleSearchEAN: "4040074059890",
      // articleSearchEAN: "8020584058237",
      articleSearchEAN: "",
      articleSearchRef: "",
      articleSearchPrefix: "",
      articleSearchBrand: "",

      errorMsg: false,

      inputStock: "",
      scanStarted: false,
      adresseArt: "",
      showReservedClient: false,
      showBackOrder: false,

      articlesSearch: [],
      articleSearch: {},
      selectedArticle: {},
      inputType: "",
      moreFilter: false,

      numbers: [2, 5, 10, 20, 50, 100],
      stateNumber: false,
    };
  },
  computed: {
    ...mapGetters(["user", "prefixRegulStock", "brandRegulStock"]),
  },
  components: {
    backButton,
  },
  methods: {
    ...mapActions(["setSearchRegulStock"]),

    // NEW
    async findArticleByEAN(ean, marque, prefixe) {
      this.errorMsg = false;
      this.articlesSearch = [];
      this.articleSearch = {};
      this.selectedArticle = {};

      if (marque || prefixe) {
        this.setSearchRegulStock({ prefix: prefixe, brand: marque });
      }

      const scan = await scanStock(
        this.user._id,
        this.user.proprietaire,
        ean,
        this.brandRegulStock,
        this.prefixRegulStock
      );

      if (scan.state == "exist") {
        this.articleSearch = scan.data;
        if (scan.data.division.length > 1) {
          this.articlesSearch = scan.data.division;
        } else {
          this.selectedArticle = scan.data.division[0];
        }

        document.getElementById("son1").play();
      } else if (scan.state == "do not exist") {
        this.errorMsg = true;

        document.getElementById("son2").play();
      }

      this.articleSearchEAN = "";
      this.articleSearchBrand = "";
      this.articleSearchPrefix = "";
    },
    async findArticleByRef(ref, marque, prefixe) {
      this.errorMsg = false;
      this.articlesSearch = [];
      this.articleSearch = {};
      this.selectedArticle = {};

      if (marque || prefixe) {
        this.setSearchRegulStock({ prefix: prefixe, brand: marque });
      }

      const scan = await findStock(
        this.user._id,
        this.user.proprietaire,
        ref,
        this.brandRegulStock,
        this.prefixRegulStock
      );

      if (scan.state == "exist") {
        this.articleSearch = scan.data;
        if (scan.data.division.length > 1) {
          this.articlesSearch = scan.data.division;
        } else {
          this.selectedArticle = scan.data.division[0];
        }

        document.getElementById("son1").play();

        this.articleSearchRef = "";
      } else if (scan.state == "do not exist") {
        this.errorMsg = true;

        document.getElementById("son2").play();
      }
      this.articleSearchBrand = "";
      this.articleSearchPrefix = "";
    },
    async findByRefOrEAN(prefixe, marque) {
      if (this.articleSearchEAN) {
        this.findArticleByEAN(
          this.articleSearchEAN,
          this.articleSearchBrand
            ? this.articleSearchBrand
            : this.brandRegulStock,
          this.articleSearchPrefix
            ? this.articleSearchPrefix
            : this.prefixRegulStock
        );
      } else if (this.articleSearchRef) {
        this.findArticleByRef(
          this.articleSearchRef,
          this.articleSearchBrand
            ? this.articleSearchBrand
            : this.brandRegulStock,
          this.articleSearchPrefix
            ? this.articleSearchPrefix
            : this.prefixRegulStock
        );
      } else if (prefixe) {
        this.setSearchRegulStock({
          prefix: prefixe,
          brand: this.brandRegulStock,
        });
        this.articleSearchPrefix = "";
      } else {
        this.setSearchRegulStock({
          prefix: this.prefixRegulStock,
          brand: marque,
        });
        this.articleSearchBrand = "";
      }
      this.showMoreFilter(false);
    },
    async updateAddressStock(address) {
      await updateAddress(
        this.user._id,
        this.articleSearch._id,
        address,
        this.user.username
      );
      success("Modifié !");
    },

    // Règlage du  nombre stock/réservé/commande fourn.
    setInput(input) {
      this.inputType = input;
    },
    async setNumber(number) {
      if (this.inputType == "stock") {
        this.$refs.stock.focus();

        this.selectedArticle.stock == 0
          ? (this.selectedArticle.stock = number)
          : (this.selectedArticle.stock += number);

        await this.updateStock();
      } else if (this.inputType == "reserved") {
        this.$refs.reserved.focus();

        this.selectedArticle.reserved == 0
          ? (this.selectedArticle.reserved = number)
          : (this.selectedArticle.reserved += number);

        await this.updateReserved();
      } else {
        this.$refs.backorder.focus();

        this.selectedArticle.backorder == 0
          ? (this.selectedArticle.backorder = number)
          : (this.selectedArticle.backorder += number);

        await this.updateBackOrder();
      }
    },
    async deleteNumber() {
      if (this.inputType == "stock") {
        this.selectedArticle.stock = 0;

        await this.updateStock();
      } else if (this.inputType == "reserved") {
        this.selectedArticle.reserved = 0;

        await this.updateReserved();
      } else {
        this.selectedArticle.backorder = 0;

        await this.updateBackOrder();
      }
    },
    async upInputStock() {
      this.selectedArticle.stock = (
        parseInt(this.selectedArticle.stock) + 1
      ).toString();

      await this.updateStock();
      this.$refs.scan.focus();
    },
    async downInputStock() {
      this.selectedArticle.stock = (
        parseInt(this.selectedArticle.stock) - 1
      ).toString();

      await this.updateStock();
      this.$refs.scan.focus();
    },

    // Plus de filtres
    showMoreFilter(data) {
      this.moreFilter = data;
    },
    isXSscreen() {
      return window.innerWidth < 640; // Retourne true si l'écran est de taille XS (téléphone)
    },
    focusScan() {
      this.$refs.scan.focus();
    },
    async setNumberStock(number) {
      if (!this.stateNumber) {
        this.selectedArticle.stock =
          parseInt(this.selectedArticle.stock) + number;
      } else {
        this.selectedArticle.stock = number;
      }

      await this.updateStock();
    },

    async updateStock() {
      await updateOrCreateStock(
        this.user._id,
        this.user.proprietaire,
        this.articleSearch.article._id,
        this.articleSearch.Ref_fournisseur,
        this.articleSearch.Code_marque,
        this.articleSearch.Code_EAN,
        this.selectedArticle.stock,
        this.articleSearch.min,
        this.articleSearch.max,
        this.selectedArticle.address,
        this.user.username,
        "Stock modifié par " + this.user.username,
        this.selectedArticle.Prefixe_tarif,
        this.user.proprietaire,
        "",
        "NA",
        "régul"
      );

      await this.findArticleByEAN(
        this.articleSearch.Code_EAN,
        this.articleSearch.Code_marque,
        this.selectedArticle.Prefixe_tarif
      );

      this.$refs.stock.focus();

      if (this.isXSscreen()) {
        success("Modifié !");
      }
    },
    async updateReserved() {
      await updateReservation(
        this.user._id,
        this.user.proprietaire,
        this.articleSearch.article._id,
        this.articleSearch.Ref_fournisseur,
        this.articleSearch.Code_marque,
        this.articleSearch.Code_EAN,
        this.selectedArticle.stock,
        this.articleSearch.min,
        this.articleSearch.max,
        this.selectedArticle.address,
        this.user.username,
        "Réservé modifié par " + this.user.username,
        this.selectedArticle.Prefixe_tarif,
        this.user.proprietaire,
        "",
        "NA",
        "régul",
        this.selectedArticle.reserved
      );

      await this.findArticleByEAN(
        this.articleSearch.Code_EAN,
        this.articleSearch.Code_marque,
        this.selectedArticle.Prefixe_tarif
      );

      this.$refs.reserved.focus();

      success("Modifié !");
    },
    async updateBackOrder() {
      await updateBackorder(
        this.user._id,
        this.user.proprietaire,
        this.articleSearch.article._id,
        this.articleSearch.Ref_fournisseur,
        this.articleSearch.Code_marque,
        this.articleSearch.Code_EAN,
        this.selectedArticle.stock,
        this.articleSearch.min,
        this.articleSearch.max,
        this.selectedArticle.address,
        this.user.username,
        "Commande fournisseur modifié par " + this.user.username,
        this.selectedArticle.Prefixe_tarif,
        this.user.proprietaire,
        "",
        "NA",
        "régul",
        this.selectedArticle.backorder
      );

      await this.findArticleByEAN(
        this.articleSearch.Code_EAN,
        this.articleSearch.Code_marque,
        this.selectedArticle.Prefixe_tarif
      );

      this.$refs.backorder.focus();

      success("Modifié !");
    },
  },
  mounted() {
    this.isXSscreen();
  },
};
</script>

<style scoped>
tbody {
  display: block;
}

thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* Pour que les colonnes gardent la même largeur */
}
</style>
