<template>
  <div
    v-if="user.username == 'wanis mahjor' || user.username == 'fatih.kaya'"
    class="cursor-pointer mb-8"
    @click="$router.push('/configurationCatalogueHub')"
  >
    Nouvelle page Fatih
  </div>
  <div>
    <div class="flex">
      <router-link to="/configurationhub" class="flex flex-row items-center">
        <backButton />
      </router-link>
    </div>
    <div class="flex flex-row items-center mt-4">
      <div>
        <span
          v-if="plateform.typeCat == 'base'"
          class="p-2 bg-blue-500 text-white rounded-l cursor-pointer shadow"
          >Catalogue de base</span
        >
        <span
          v-else
          class="p-2 bg-white rounded-l cursor-pointer"
          @click="setCatType('base')"
          >Catalogue de base</span
        >
      </div>
      <div>
        <span
          v-if="plateform.typeCat == 'personnalise'"
          class="p-2 bg-orange-500 text-white rounded-r cursor-pointer shadow"
          >Catalogue personnalisé</span
        >
        <span
          v-else
          class="p-2 bg-white rounded-r cursor-pointer"
          @click="setCatType('personnalise')"
          >Catalogue personnalisé</span
        >
      </div>
    </div>
    <div class="mt-4">
      <CatalogueBase v-if="plateform.typeCat == 'base'" />
      <CataloguePersonnalise v-if="plateform.typeCat == 'personnalise'" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import CatalogueBase from "../components/ConfigurationHub/ConfigurationCatalogue/CatalogueBase.vue";
import CataloguePersonnalise from "../components/ConfigurationHub/ConfigurationCatalogue/CataloguePersonnalise.vue";
import backButton from "@/components/Button/back.vue";
export default {
  data() {
    return {};
  },
  components: {
    CatalogueBase,
    CataloguePersonnalise,
    backButton,
  },
  computed: {
    ...mapGetters(["plateform", "user"]),
  },
  methods: {
    ...mapActions(["getplateform"]),
    setCatType(type) {
      axios
        .put(`${process.env.VUE_APP_API}/plateform/${this.plateform._id}`, {
          typeCat: type,
        })
        .then(() => {
          this.getplateform();
        });
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
};
</script>
<style></style>
