<template>
  <div>
    <router-link to="/Fournisseurs">
      <div
        id="retour"
        class="flex justify-start bg-slate-100 rounded-lg px-1 w-fit hover:bg-white border border-blue-400"
      >
        <img src="../assets/RetourIcon.png" class="w-6" />
        <span style="color: #5976e8">Retour</span>
      </div>
    </router-link>
    <!-- Breadcrumb -->
    <div class="flex flex-col mt-3 sm:flex-row">
      <div class="flex">
        <div class="relative">
          <input
            v-on:keyup.enter="getMatricePrix()"
            list="brow2"
            v-model="fournisseurChosed"
            placeholder="Fournisseurs"
            class="block w-full focus:outline-2 outline-sky-300 py-2 pl-8 pr-6 text-sm text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400 rounded-l rounded-r appearance-none focus:bg-gray-300 focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none hover:bg-gray-300"
          />
          <datalist id="brow2">
            <option
              v-for="(c, index) in fournisseurList"
              :key="index"
              :value="c.CompanyName"
            ></option>
          </datalist>

          <div
            class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
          >
            <svg
              class="w-4 h-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="relative ml-4">
        <span
          class="material-icons-round text-gray-500 cursor-pointer"
          style="font-size: 2.14rem"
          v-show="lock"
          @click="lock = !lock"
        >
          lock
        </span>
      </div>
      <div class="relative">
        <span
          class="material-icons-round text-gray-500 cursor-pointer"
          style="font-size: 2.14rem"
          v-show="!lock"
          @click="(lock = !lock), getMatricePrix()"
        >
          lock_open
        </span>
      </div>
    </div>
    <div class="flex mt-4" v-show="showManualAdd">
      <span>Aucune matrice de prix fournisseur trouvé!</span>
      <select v-model="typeMatricePrix" class="focus:outline-2 outline-sky-300">
        <option value="Remise">Remise</option>
        <option value="PrixNet">PrixNet</option>
      </select>
    </div>

    <div class="flex flex-col mt-8" v-if="typeMatricePrix != 'PrixNet'">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Fournisseur
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Code Remise
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Remise
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Pro 1
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Pro 2
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Pro 3
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Part 1
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Part 2
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Part 3
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="(mp, index) in matricePrix" :key="index">
                <td
                  class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                >
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ mp.MakerName }}
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ mp.Code }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                >
                  <span>{{ mp.SupplierPricing.Value }} %</span>
                  <span v-show="!lock"
                    ><input
                      class="w-4/6"
                      type="Number"
                      v-model="appPriceFournisseur[index]"
                      @change="updateMatricePrix(index, mp.MakerName, mp.Code)"
                    />
                    %</span
                  >
                </td>

                <td
                  class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                  style="width: 8%"
                >
                  <span v-show="lock"
                    >{{ mp.CustomerPricing.PriceAValue }} %</span
                  >
                  <span v-show="!lock"
                    ><input
                      class="w-4/6 focus:outline-2 outline-sky-300"
                      type="Number"
                      v-model="appPriceAValue[index]"
                      @change="updateMatricePrix(index, mp.MakerName, mp.Code)"
                    />
                    %</span
                  >
                </td>

                <td
                  class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                  style="width: 8%"
                >
                  <span v-show="lock"
                    >{{ mp.CustomerPricing.PriceBValue }} %</span
                  >
                  <span v-show="!lock"
                    ><input
                      class="w-4/6 focus:outline-2 outline-sky-300"
                      type="Number"
                      v-model="appPriceBValue[index]"
                      @change="updateMatricePrix(index, mp.MakerName, mp.Code)"
                    />
                    %</span
                  >
                </td>
                <td
                  class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                  style="width: 8%"
                >
                  <span v-show="lock"
                    >{{ mp.CustomerPricing.PriceCValue }} %</span
                  >
                  <span v-show="!lock"
                    ><input
                      class="w-4/6 focus:outline-2 outline-sky-300"
                      type="Number"
                      v-model="appPriceCValue[index]"
                      @change="updateMatricePrix(index, mp.MakerName, mp.Code)"
                    />
                    %</span
                  >
                </td>
                <td
                  class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                  style="width: 8%"
                >
                  <span v-show="lock"
                    >{{ mp.CustomerPricing.PriceDValue }} %</span
                  >
                  <span v-show="!lock"
                    ><input
                      class="w-4/6 focus:outline-2 outline-sky-300"
                      type="Number"
                      v-model="appPriceDValue[index]"
                      @change="updateMatricePrix(index, mp.MakerName, mp.Code)"
                    />
                    %</span
                  >
                </td>
                <td
                  class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                  style="width: 8%"
                >
                  <span v-show="lock"
                    >{{ mp.CustomerPricing.PriceEValue }} %</span
                  >
                  <span v-show="!lock"
                    ><input
                      class="w-4/6 focus:outline-2 outline-sky-300"
                      type="Number"
                      v-model="appPriceEValue[index]"
                      @change="updateMatricePrix(index, mp.MakerName, mp.Code)"
                    />
                    %</span
                  >
                </td>
                <td
                  class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                  style="width: 8%"
                >
                  <span v-show="lock"
                    >{{ mp.CustomerPricing.PriceFValue }} %</span
                  >
                  <span v-show="!lock"
                    ><input
                      class="w-4/6 focus:outline-2 outline-sky-300"
                      type="Number"
                      v-model="appPriceFValue[index]"
                      @change="updateMatricePrix(index, mp.MakerName, mp.Code)"
                    />
                    %</span
                  >
                </td>
              </tr>
            </tbody>
            <tbody class="bg-white" v-show="showManualAdd">
              <tr>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ fournisseurChosed }}
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  style="width: 18%"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    <input
                      class="w-4/6 focus:outline-2 outline-sky-300"
                      type="text"
                      v-model="addCode"
                    />
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  style="width: 12%"
                >
                  <input
                    class="w-4/6 focus:outline-2 outline-sky-300"
                    type="Number"
                    v-model="addRemise"
                  />
                  %
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  style="width: 8%"
                >
                  <input
                    class="w-4/6 focus:outline-2 outline-sky-300"
                    type="Number"
                    v-model="addPriceAValue"
                  />
                  %
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  style="width: 8%"
                >
                  <input
                    class="w-4/6 focus:outline-2 outline-sky-300"
                    type="Number"
                    v-model="addPriceBValue"
                  />
                  %
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  style="width: 8%"
                >
                  <input
                    class="w-4/6 focus:outline-2 outline-sky-300"
                    type="Number"
                    v-model="addPriceCValue"
                  />
                  %
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  style="width: 8%"
                >
                  <input
                    class="w-4/6 focus:outline-2 outline-sky-300"
                    type="Number"
                    v-model="addPriceDValue"
                  />
                  %
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  style="width: 8%"
                >
                  <input
                    class="w-4/6 focus:outline-2 outline-sky-300"
                    type="Number"
                    v-model="addPriceEValue"
                  />
                  %
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  style="width: 8%"
                >
                  <input
                    class="w-4/6 focus:outline-2 outline-sky-300"
                    type="Number"
                    v-model="addPriceFValue"
                  />
                  %
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <button
      v-show="showManualAdd"
      class="px-6 py-3 mt-4 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
      @click="createMatricePrix()"
    >
      Enregistrer
    </button>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "MyFournisseurs",
  data() {
    return {
      lock: true,
      showManualAdd: false,
      fournisseurList: [],
      matricePrix: [],
      fournisseurNumber: 0,
      paginateArray: [],
      fournisseurChosed: "",
      appPriceFournisseur: [],
      appPriceAValue: [],
      appPriceBValue: [],
      appPriceCValue: [],
      appPriceDValue: [],
      appPriceEValue: [],
      appPriceFValue: [],
      addCode: "",
      addRemise: 0,
      addPriceAValue: 0,
      addPriceBValue: 0,
      addPriceCValue: 0,
      addPriceDValue: 0,
      addPriceEValue: 0,
      addPriceFValue: 0,
      typeMatricePrix: "",
      prefixeFournisseur: "",
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    getFournisseur() {
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/getall`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.fournisseurList = response.data;
        });
    },
    getMatricePrix() {
      axios
        .post(`${process.env.VUE_APP_API}/matricePrix/getone`, {
          MakerName: this.fournisseurChosed,
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.showManualAdd = false;
            this.matricePrix = response.data;
            response.data.forEach((element, index) => {
              this.appPriceFournisseur[index] = element.SupplierPricing.Value;
              this.appPriceAValue[index] = element.CustomerPricing.PriceAValue;
              this.appPriceBValue[index] = element.CustomerPricing.PriceBValue;
              this.appPriceCValue[index] = element.CustomerPricing.PriceCValue;
              this.appPriceDValue[index] = element.CustomerPricing.PriceDValue;
              this.appPriceEValue[index] = element.CustomerPricing.PriceEValue;
              this.appPriceFValue[index] = element.CustomerPricing.PriceFValue;
            });
          } else {
            this.matricePrix = [];
            this.showManualAdd = true;
          }
        });
    },
    updateMatricePrix(index, maker, code) {
      axios.put(`${process.env.VUE_APP_API}/matricePrix`, {
        MakerName: maker,
        Code: code,
        plateform: this.user.proprietaire,
        SupplierPricing: {
          Value: this.appPriceFournisseur[index],
        },
        CustomerPricing: {
          PriceAValue: this.appPriceAValue[index],
          PriceBValue: this.appPriceBValue[index],
          PriceCValue: this.appPriceCValue[index],
          PriceDValue: this.appPriceDValue[index],
          PriceEValue: this.appPriceEValue[index],
          PriceFValue: this.appPriceFValue[index],
        },
      });
    },
    async createMatricePrix() {
      const getOneFournisseur = await axios.post(
        `${process.env.VUE_APP_API}/fournisseur/getone`,
        {
          CompanyName: this.fournisseurChosed,
          plateform: this.user.proprietaire,
        }
      );
      getOneFournisseur.data.length > 0
        ? (this.prefixeFournisseur = getOneFournisseur.data[0].Prefixe_tarif)
        : (this.prefixeFournisseur = "None");

      axios
        .post(`${process.env.VUE_APP_API}/matricePrix`, {
          MakerName: this.fournisseurChosed,
          plateform: this.user.proprietaire,
          Code: this.addCode,
          SupplierPricing: {
            Value: this.addRemise,
          },
          CustomerPricing: {
            PriceAValue: this.addPriceAValue,
            PriceBValue: this.addPriceBValue,
            PriceCValue: this.addPriceCValue,
            PriceDValue: this.addPriceDValue,
            PriceEValue: this.addPriceEValue,
            PriceFValue: this.addPriceFValue,
          },
        })
        .then(() => {
          if (this.typeMatricePrix != "") {
            axios
              .post(`${process.env.VUE_APP_API}/matriceFourniSet`, {
                MakerName: this.fournisseurChosed,
                MakerId: this.addCode,
                SupplierId: this.addCode,
                Set: true,
                type: this.typeMatricePrix,
                plateform: this.user.proprietaire,
                Prefix: this.prefixeFournisseur,
              })
              .then(() => {
                this.getMatricePrix();
                this.showManualAdd = false;
              });
          } else {
            alert("choisir un type de remise");
          }
        });
    },
  },
  mounted() {
    this.getFournisseur();
  },
};
</script>
