<template>
  <div v-if="historiqueCaisse.length > 0">
    <!-- Contenu modal -->
    <button
      id="retour"
      class="flex justify-start my-auto bg-slate-100 rounded-lg px-1 w-fit hover:bg-white border border-blue-400"
      @click="clearhistoriquecaisse"
    >
      <img src="@/assets/RetourIcon.png" class="w-6" />
      <span style="color: #5976e8">Retour</span>
    </button>
    <div class="bg-white mt-4 rounded-md" v-if="historiqueCaisse[0]">
      <div class="flex flex-col px-12 py-8">
        <h5 class="text-2xl font-medium">
          Caisse du {{ getFRDate(historiqueCaisse[0].date) }}
        </h5>
        <div class="mt-4 rounded-md p-1">
          <div class="flex justify-between">
            <div class="w-32"></div>
            <h4 class="text-2xl font-medium">
              Total caisse :
              {{ parseFloat(historiqueCaisse[0].totalToday).toFixed(2) }}
              €
            </h4>
          </div>
          <div
            class="flex justify-center items-center"
            style="min-width: 40rem; min-height: 20rem"
          >
            <div
              v-for="(mode, index) in historiqueCaisse[0].payment"
              :key="index"
              class="mode-space-x"
            >
              <div class="flex flex-col">
                <button class="flex space-x-4 rounded-md shadow p-2">
                  <img
                    class="w-12 border border-black rounded-md m-auto rounded-md focus:outline-2 outline-sky-300"
                    :src="mode.image"
                  />
                  <div class="flex flex-col">
                    <h4 class="text-sm m-auto">{{ mode.action }} actions</h4>
                    <p class="m-auto text-xl">{{ mode.total }} €</p>
                  </div>
                  <input
                    type="checkbox"
                    class="w-6 h-6 focus:outline-2 outline-sky-300 m-auto"
                    value="true"
                    disabled
                    v-model="mode.approve"
                  />
                </button>
                <h4 class="text-sm m-auto">{{ mode.type }}</h4>
              </div>
            </div>
          </div>

          <div class="flex justify-center mb-6 items-center">
            <div class="ml-32 text-xl">
              <button class="flex space-x-4 bg-white rounded-md shadow p-2">
                <img
                  class="w-12 border border-black rounded-md m-auto rounded-md focus:outline-2 outline-sky-300"
                  src="@/assets/icons_payments/Caisse.png"
                />
                <div class="flex flex-col">
                  <h4 class="text-sm m-auto">Total encaissé :</h4>
                  <p class="m-auto text-green-700">
                    {{ historiqueCaisse[0].totalCashed }} €
                  </p>
                </div>
              </button>
            </div>

            <div class="ml-32 text-xl">
              <button class="flex space-x-4 bg-white rounded-md shadow p-2">
                <img
                  class="w-12 border border-black rounded-md m-auto rounded-md focus:outline-2 outline-sky-300"
                  src="@/assets/icons_payments/FactureEuro.png"
                />
                <div class="flex flex-col">
                  <h4 class="text-sm m-auto">Total réel :</h4>
                  <p class="m-auto text-green-700">
                    {{ historiqueCaisse[0].totalReal }} €
                  </p>
                </div>
              </button>
            </div>
          </div>

          <!-- Total monnaie -->

          <h4 class="flex justify-center mt-4 text-xl font-medium">
            Total monnaie :
            {{ parseFloat(historiqueCaisse[0].totalCash).toFixed(2) }}
            €
          </h4>

          <div v-if="historiqueCaisse" class="mode-grid-cols mt-4">
            <div v-for="(line, index) in historiqueCaisse[0].cash" :key="index">
              <div class="flex space-x-8 bg-gray-50 border rounded-md p-1">
                <img
                  :src="require(`@/assets/icons_payments/${line.imgName}.jpg`)"
                  class="w-10 h-10"
                />
                <input
                  type="number"
                  class="w-24 text-xl p-1 focus:outline-2 outline-sky-300 m-auto border border-black rounded-md"
                  v-model="line.quantity"
                  min="0"
                  disabled
                />
                <h2 class="m-auto">=> {{ line.total }} €</h2>
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex justify-center items-center"
          style="min-width: 40rem; min-height: 20rem"
        >
          <!-- Montant laissé -->
          <div class="flex justify-center text-xl font-medium">
            <label>Montant laissé :</label>
            <input
              type="number"
              class="w-24 text-xl p-1 focus:outline-2 outline-sky-300 my-auto mx-2 border border-black rounded-md"
              v-model="historiqueCaisse[0].amountLeft.amount"
              min="0"
              disabled
            />
            €
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <button
      id="retour"
      class="flex justify-start my-auto bg-slate-100 rounded-lg px-1 w-fit hover:bg-white border border-blue-400"
      @click="$router.back()"
    >
      <img src="@/assets/RetourIcon.png" class="w-6" />
      <span style="color: #5976e8">Retour</span>
    </button>
    <div
      class="mt-2 relative overflow-x-auto shadow-md sm:rounded-lg mb-12 border border-orange-400"
      style="max-height: 60em"
    >
      <table class="w-full text-sm text-left text-gray-500">
        <caption
          class="text-xl bg-gray-100 w-full font-semibold text-orange-400 pl-4 py-1"
        >
          Historique caisse
        </caption>
        <thead class="text-xs bg-bluevk text-white uppercase bg-gray-50">
          <tr>
            <th scope="col" class="px-6 py-3">Date</th>
            <th scope="col" class="px-6 py-3">Numéro</th>
            <th scope="col" class="px-6 py-3">Créée par</th>
            <th scope="col" class="px-6 py-3">Total encaissé</th>
            <th scope="col" class="px-6 py-3">Total laissé en caisse</th>
            <th scope="col" class="px-6 py-3">Pdf</th>
            <th scope="col" class="px-6 py-3">Détail</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="tab-list odd:bg-white even:bg-gray-100 text-black cursor-pointer"
            v-for="(item, index) in tabHist"
            :key="index"
          >
            <td class="px-6 py-4">{{ getFRDate(item.date) }}</td>
            <td class="px-6 py-4">{{ item.number }}</td>
            <td class="px-6 py-4">{{ item.user }}</td>
            <td class="px-6 py-4 text-xl">
              {{ parseFloat(item.totalCash).toFixed(2) }}
              €
            </td>
            <td class="px-6 py-4 text-xl">
              {{ parseFloat(item.amountLeft.amount).toFixed(2) }}
              €
            </td>
            <td class="px-6 py-4 text-xl">
              <button
                @click="openPdf(item)"
                class="material-icons-round cursor-pointer text-blue-600 hover:text-opacity-25"
              >
                download
              </button>
            </td>
            <td class="px-6 py-4 text-xl">
              <button
                class="material-icons-round cursor-pointer text-green-600 hover:text-opacity-25"
                @click="addhistoriquecaisse(item)"
              >
                visibility
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import { createEcheancier } from "@/hooks/caisse/echeancier";
import { getFrDate } from "@/hooks/tools/date.ts";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "historiqueCaisse",
  data() {
    return {
      tabHist: [],
    };
  },
  computed: {
    ...mapGetters(["user", "historiqueCaisse"]),
  },
  methods: {
    ...mapActions(["addhistoriquecaisse", "clearhistoriquecaisse"]),
    getFRDate(date) {
      return getFrDate(date);
    },
    getHistoriqueCaisse() {
      axios
        .post(`${process.env.VUE_APP_API}/caisse/getAll`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          console.log(response.data);
          this.tabHist = response.data;
        });
    },
    openPdf(item) {
      createEcheancier(item.dataBC.concat(item.dataFact), item);
    },
  },
  mounted() {
    this.getHistoriqueCaisse();
    this.clearhistoriquecaisse();
  },
};
</script>

<style>
.tab-list:hover:nth-child(odd) {
  background-color: rgb(235, 235, 235);
  /* opacity: 0.6; */
}

.tab-list:hover:nth-child(even) {
  background-color: rgb(235, 235, 235);
  /* opacity: 0.6; */
}

.mode-space-x {
  margin-left: 2rem;
}
</style>
